import { useMutation } from "@tanstack/react-query";
import classNames from "classnames";
import { ContractType } from "enums/ContractType";
import { useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  FieldMotorIntelValuation,
  FieldValuation,
  ModalConfirm,
  ModalInternalValuation,
  ModalMotorIntelLog,
  ModalOfferAdd,
  ModalOfferLog,
  ModalValuationAdd,
  ModalWholesaleValuations,
} from "components/app";
import { LinkButton } from "components/Button";
import { PricingInsightsModal } from "components/Modal/PricingInsightsModal";
import { Modal, SvgImage } from "components/shared";

import { AuthContext } from "context/AuthContext";

import {
  ACCEPTED,
  ACQUIRED,
  DECLINED,
  DECLINED_VALUATION,
  DISABLED_STATE_COLOR,
  FLOW_TYPE,
  formatNumber,
  STATUS_LIST,
  VALUATION_INVISIBLE_STATUSES,
} from "utils";
import { isValuationContractType } from "utils/inspections";
import { PermissionAction, PermissionCategory } from "utils/userPermissions";

import { IVehicleSummary } from "types";

import { NegotiationService, ValuationService } from "api/client";

import { MoveToLost } from "./MoveToLost";
import { ResearchVehicle } from "./ResearchVehicle";
import { VerificationValuation } from "./VerificationValuation";

interface ValuationsSectionProps {
  data: IVehicleSummary;
  flow?: FLOW_TYPE;
  hideComment?: boolean;
  canRevertVehicle?: boolean;
  onRefresh: () => void;
  isGlobalSearch?: boolean;
}

export const ValuationsSection = ({
  data,
  flow,
  canRevertVehicle = true,
  onRefresh,
  isGlobalSearch,
}: ValuationsSectionProps) => {
  const { hasPermission } = useContext(AuthContext);
  const navigate = useNavigate();

  const [modalStates, setModalStates] = useState({
    offer: false,
    internal: false,
    wholesale: false,
    mpLog: false,
    addValuation: false,
    addOffer: false,
    backToNegotiating: false,
    pricingInsights: false,
  });

  const toggleModal = (modalName: keyof typeof modalStates, state: boolean) => {
    setModalStates((prev) => ({ ...prev, [modalName]: state }));
  };

  const {
    contract_id,
    status,
    valuations,
    valuation_requests,
    request_id,
    type,
    reference,
  } = data;

  const { name } = status || {};

  const isVehicleVerification = data?.type === ContractType.Verification;

  const offerAmount = valuations?.internal?.offer
    ? `$${formatNumber(valuations?.internal?.offer?.amount)}`
    : "";

  const valuationAmount = valuations?.internal?.valuation?.amount
    ? `$${formatNumber(valuations?.internal?.valuation?.amount)}`
    : "";

  const hubOfferAmount = valuations?.hub?.offer
    ? `$${formatNumber(valuations?.hub?.offer?.amount)}`
    : "";

  const {
    mutate: handleRequestMPValuation,
    isLoading: isRequestingMPValuation,
    isSuccess,
  } = useMutation(
    (payload: { type: string }) =>
      ValuationService.requestMPValuation(contract_id, payload),
    {
      onSuccess: () => {
        toast.success("Valuation Requested", {
          autoClose: 2000,
          theme: "colored",
        });
        onRefresh();
      },
      onError: () => {
        toast.error("Failed to Send", {
          autoClose: 2000,
          theme: "colored",
        });
      },
    }
  );

  const { mutate: onRevertToPending, isLoading: isRevertToPendingLoading } =
    useMutation(
      (payload: { reason: string }) =>
        NegotiationService.negotiationRevert(data.contract_id, payload),
      {
        onSuccess: () => {
          toggleModal("backToNegotiating", false);
          onRefresh();
          toast.success("Successfully Moved", {
            autoClose: 2000,
            theme: "colored",
          });
        },
        onError: () => {
          toast.error("Failed to Move", {
            autoClose: 2000,
            theme: "colored",
          });
        },
      }
    );

  const handleRequestDisabled = useMemo(() => {
    if (
      STATUS_LIST.SoldAcquisition.findIndex((item) => item.key === name) !== -1
    ) {
      return true;
    } else {
      return false;
    }
  }, [name]);

  if (
    status?.name !== "tendered" &&
    VALUATION_INVISIBLE_STATUSES.includes(status?.name)
  ) {
    return null;
  }

  const isRetailDealershipFlow = flow === FLOW_TYPE.RETAIL_DEALERSHIP;

  const canViewOfferAmount =
    !isValuationContractType(data) &&
    hasPermission(PermissionCategory.DEALERSHIP_OFFER, PermissionAction.VIEW);

  const canExternalValuationView = hasPermission(
    isValuationContractType(data)
      ? PermissionCategory.WHOLESALE_VALUATIONS
      : PermissionCategory.EXTERNAL_VALUATIONS,
    PermissionAction.VIEW
  );

  const canExternalValuationRequest = hasPermission(
    isValuationContractType(data)
      ? PermissionCategory.WHOLESALE_VALUATIONS
      : PermissionCategory.EXTERNAL_VALUATIONS,
    PermissionAction.REQUEST
  );

  const canMpValuationRequest = hasPermission(
    PermissionCategory.MOTORINTEL_VALUATIONS,
    PermissionAction.REQUEST
  );

  const canMpValuationView = hasPermission(
    PermissionCategory.MOTORINTEL_VALUATIONS,
    PermissionAction.VIEW
  );

  return (
    <div className="section">
      <div className="section__header">
        <span>
          {isVehicleVerification ? "Verification & Valuation" : "Valuations"}
        </span>
      </div>
      {isVehicleVerification ? (
        <VerificationValuation
          data={data}
          canViewPricingInsights={hasPermission(
            PermissionCategory.PRICE_GUIDE,
            PermissionAction.VIEW_ANY
          )}
          onRefresh={onRefresh}
        />
      ) : (
        <>
          <div className="section__body--valuation valuation">
            {(isValuationContractType(data) || canViewOfferAmount) && (
              <FieldValuation
                title="Offer Amount"
                value={offerAmount}
                declined={data?.status?.name === DECLINED_VALUATION.key}
                onView={() => toggleModal("offer", true)}
                canUserEdit={
                  isValuationContractType(data)
                    ? hasPermission(
                        PermissionCategory.WHOLESALE_VALUATIONS,
                        PermissionAction.CREATE
                      )
                    : hasPermission(
                        PermissionCategory.DEALERSHIP_OFFER,
                        PermissionAction.CREATE
                      )
                }
                onEditValuation={
                  name !== ACQUIRED.key
                    ? () => toggleModal("addOffer", true)
                    : undefined
                }
              />
            )}
            {hasPermission(
              PermissionCategory.DEALERSHIP_VALUATIONS,
              PermissionAction.VIEW
            ) && (
              <FieldValuation
                title="Internal Valuation (Real)"
                value={valuationAmount}
                onView={() => toggleModal("internal", true)}
                canUserEdit={hasPermission(
                  PermissionCategory.DEALERSHIP_VALUATIONS,
                  PermissionAction.CREATE
                )}
                onEditValuation={
                  name !== ACQUIRED.key
                    ? () => toggleModal("addValuation", true)
                    : undefined
                }
              />
            )}
            {(canMpValuationRequest || canMpValuationView) && (
              <FieldMotorIntelValuation
                rawAmount={valuations?.hub?.offer?.amount}
                hubOfferAmount={hubOfferAmount}
                requested={valuation_requests?.hub || isSuccess}
                isRequesting={isRequestingMPValuation}
                onOpenMPLogModal={() => toggleModal("mpLog", true)}
                onRequestMPValuation={handleRequestMPValuation}
                contractId={contract_id}
                vehicleName={data?.vehicle?.name}
                declined={data?.status?.name === DECLINED_VALUATION.key}
                isMotorIntelDeclined={!!data.valuation_requests?.hub_declined}
                canUserView={canMpValuationView}
                canRequest={canMpValuationRequest}
              />
            )}
            {(canExternalValuationRequest || canExternalValuationView) && (
              <FieldValuation
                title="Wholesale Partner Valuations"
                tool={
                  <LinkButton
                    disabled={data?.status?.name === DECLINED_VALUATION.key}
                    onClick={() => toggleModal("wholesale", true)}
                  >
                    <span
                      className={classNames("!text-sm", {
                        [`!text-[${DISABLED_STATE_COLOR}]`]:
                          data?.status?.name === DECLINED_VALUATION.key,
                      })}
                    >{`Request/View >`}</span>
                  </LinkButton>
                }
              />
            )}
            <ResearchVehicle reference={reference} />
            {hasPermission(
              PermissionCategory.PRICE_GUIDE,
              PermissionAction.VIEW_ANY
            ) && (
              <FieldValuation
                title="Pricing Insights"
                tool={
                  <LinkButton
                    onClick={() => toggleModal("pricingInsights", true)}
                  >
                    <span className={"!text-sm"}>{`View >`}</span>
                  </LinkButton>
                }
              />
            )}
          </div>
          <MoveToLost
            contractID={contract_id}
            onRefresh={onRefresh}
            status={status?.name || ""}
          />
          {(status?.name === DECLINED.key || status?.name === ACCEPTED.key) &&
            (flow === FLOW_TYPE.RETAIL_DEALERSHIP || isGlobalSearch) &&
            canRevertVehicle && (
              <LinkButton
                disabled={isRevertToPendingLoading}
                onClick={() => toggleModal("backToNegotiating", true)}
                underline
              >
                Move Back To Pending Deal Status
              </LinkButton>
            )}
        </>
      )}
      <PricingInsightsModal
        data={data?.trade}
        isOpen={modalStates.pricingInsights}
        onClose={() => toggleModal("pricingInsights", false)}
        dateCreated={data?.created_at}
      />
      <Modal
        open={modalStates.backToNegotiating}
        size="auto"
        canClose={false}
        closeModal={() => toggleModal("backToNegotiating", false)}
      >
        <ModalConfirm
          onCancel={() => toggleModal("backToNegotiating", false)}
          onConfirm={() => onRevertToPending({ reason: "Customer cancelled" })}
          isLoading={isRevertToPendingLoading}
        />
      </Modal>
      <Modal
        open={modalStates.offer}
        title="Offer Log"
        description="This is the offer amount sent to the person who requested the valuation."
        size="auto"
        closeModal={() => toggleModal("offer", false)}
      >
        <ModalOfferLog
          open={modalStates.offer}
          valuationID={valuations?.internal?.offer?.valuation_id}
          declined={data?.status?.name === DECLINED_VALUATION.key}
        />
      </Modal>
      <Modal
        open={modalStates.internal}
        title="Internal Valuation (Real)"
        description="This is the estimated actual value of the vehicle, which is only visible to the managers of your dealership."
        size="auto"
        closeModal={() => toggleModal("internal", false)}
      >
        <ModalInternalValuation
          open={modalStates.internal}
          valuation_id={valuations?.internal?.valuation?.valuation_id!}
        />
      </Modal>
      <Modal
        open={modalStates.wholesale}
        title="Wholesale Partner Valuations"
        size="auto"
        closeModal={() => toggleModal("wholesale", false)}
      >
        <ModalWholesaleValuations
          contract_id={contract_id}
          open={modalStates.wholesale}
          requestDisabled={
            handleRequestDisabled || !canExternalValuationRequest
          }
          canUserView={canExternalValuationView}
        />
      </Modal>
      <Modal
        open={modalStates.mpLog}
        size="auto"
        closeModal={() => toggleModal("mpLog", false)}
      >
        <ModalMotorIntelLog
          open={modalStates.mpLog}
          valuation_id={valuations?.hub?.offer?.valuation_id!}
        />
      </Modal>
      <Modal
        open={modalStates.addValuation}
        size="auto"
        title="Add Valuation Amount"
        closeModal={() => {
          onRefresh();
          toggleModal("addValuation", false);
        }}
      >
        <ModalValuationAdd
          onCloseModal={() => {
            onRefresh();
            toggleModal("addValuation", false);
          }}
          contract_id={contract_id}
          requester_dealership={data.requester_dealership || ""}
          requestID={request_id}
        />
      </Modal>
      <Modal
        open={modalStates.addOffer}
        size="auto"
        closeModal={() => {
          onRefresh();
          toggleModal("addOffer", false);
        }}
      >
        <ModalOfferAdd
          onCloseModal={(updated: boolean) => {
            if (updated) {
              navigate(-1);
            } else {
              onRefresh();
            }
            toggleModal("addOffer", false);
          }}
          contractID={contract_id}
          requesterDealershipID={data.requester_dealership || ""}
          requestID={request_id}
        />
      </Modal>
    </div>
  );
};
