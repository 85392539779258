import { ReactElement } from "react";

import { LoadVehicle, StatusBarTabs } from "components/app";
import {
  listPageContentTestID,
  listPageTestID,
} from "components/app/dataTestIDs";
import { SubStatusBarTabs } from "components/app/SubStatusBarTabs/SubStatusBarTabs";
import { NoPermissionMessage } from "components/NoPermissionMessage";
import { Breadcrumbs, Pagination } from "components/shared";
import { PaginationProps } from "components/shared/Pagination/Pagination";

import { ERROR_MESSAGE } from "utils";
import { PermissionCategory } from "utils/userPermissions";

export type ListProps = {
  pageTitle: string;
  breadcrumbs: { title: string; route: string }[];
  pagination: PaginationProps;
  selectedFilterStatus: string;
  filterStatusOptions: { key: string; label: string }[];
  isLoading: boolean;
  isError: boolean;
  children: ReactElement;
  dataTestID?: string;
  hasViewingPermission?: boolean;
  extraFilterStatusOptions?: { key: string; label: string }[];
  onFilterStatusChange: (status: string | number) => void;
  viewPermissionCategory?: PermissionCategory;
};

export const List = ({
  pageTitle,
  breadcrumbs,
  pagination,
  selectedFilterStatus,
  filterStatusOptions,
  isLoading,
  isError,
  children,
  hasViewingPermission,
  dataTestID,
  onFilterStatusChange,
  extraFilterStatusOptions,
  viewPermissionCategory,
}: ListProps) => {
  return (
    <div
      className="flex flex-col max-w-1300 w-full"
      data-testid={dataTestID || listPageTestID}
    >
      <div className="flex w-full gap-5">
        <div className="flex flex-col gap-2.5 pt-2 w-430">
          <Breadcrumbs paths={breadcrumbs} />
          <div className="text-2xl text-txt font-semibold">{pageTitle}</div>
          {hasViewingPermission && <Pagination {...pagination} />}
        </div>
        <div className="flex flex-col gap-2 justify-center">
          <StatusBarTabs
            currentStatus={selectedFilterStatus}
            options={filterStatusOptions}
            onSelect={onFilterStatusChange}
          />
          <SubStatusBarTabs
            statusList={extraFilterStatusOptions || []}
            currentStatus={selectedFilterStatus}
            isActive={!!extraFilterStatusOptions}
            handleChangeStatus={onFilterStatusChange}
          />
        </div>
      </div>
      <div
        className="flex w-full py-5 gap-10"
        data-testid={listPageContentTestID}
      >
        {!hasViewingPermission ? (
          <NoPermissionMessage
            viewPermissionCategory={viewPermissionCategory}
          />
        ) : isLoading ? (
          <LoadVehicle />
        ) : isError ? (
          <span className="py-16 text-label text-lg">{ERROR_MESSAGE}</span>
        ) : (
          <div className="w-full flex flex-col gap-7">{children}</div>
        )}
      </div>
      <div className="py-6">
        {hasViewingPermission && <Pagination {...pagination} />}
      </div>
    </div>
  );
};
