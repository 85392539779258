import { modalInspectionCreateTestID } from "components/app/dataTestIDs";
import { InspectionDealershipSelector } from "components/DealershipSelector/InspectionDealershipSelector";
import { SvgImage } from "components/shared";

import { INSPECTION_TYPE } from "utils";

import { useViewportSizing } from "hooks";

import "../styles.scss";

export interface ModalInspectionCreateProps {
  onSelectMode: (mode: string) => void;
  dataTestID?: string;
  inspectionType: INSPECTION_TYPE;
}

interface IInspection {
  icon: string;
  title: string;
  beta?: boolean;
  disabled?: boolean;
  onClick: () => void;
}

const Inspection = ({
  icon,
  title,
  beta = false,
  disabled,
  onClick,
}: IInspection) => {
  const handleClick = () => {
    if (!disabled) {
      onClick();
    }
  };

  return (
    <button
      className={disabled ? "inspection--disabled" : "inspection"}
      onClick={handleClick}
    >
      <div className="inspection__icon">
        <SvgImage name={icon} />
        {beta && (
          <div className="inspection__icon--beta">
            <span>Beta</span>
          </div>
        )}
      </div>
      <span className="inspection--title">{title}</span>
    </button>
  );
};

export const ModalInspectionCreate = ({
  onSelectMode,
  dataTestID,
  inspectionType,
}: ModalInspectionCreateProps) => {
  const { isDesktop } = useViewportSizing();

  const INSPECTION_DATA = [
    {
      id: 1,
      title: "SCAN REGO",
      type: "SCAN",
      icon: isDesktop ? "ScanDesktopIcon" : "ScanMobileIcon",
      beta: true,
      disabled: true,
    },
    { id: 2, title: "INPUT VIN/REGO", icon: "LicenseIcon", type: "REGO" },
    { id: 3, title: "MANUAL SEARCH", icon: "CarIcon", type: "MANUAL" },
    {
      id: 4,
      title: "send link to customer",
      icon: "LinkIcon",
      type: "LINK",
    },
  ];

  return (
    <>
      <div className="flex flex-col w-full items-center gap-4 mb-5">
        <p className="font-semibold capitalize">Add {inspectionType} Vehicle</p>
      </div>
      <InspectionDealershipSelector />
      <div
        className="inspections"
        data-testid={dataTestID || modalInspectionCreateTestID}
      >
        {INSPECTION_DATA.map((inspection) => (
          <Inspection
            key={inspection.id}
            icon={inspection.icon}
            title={inspection.title}
            beta={inspection.beta}
            disabled={inspection.disabled}
            onClick={() => onSelectMode(inspection.type)}
          />
        ))}
      </div>
    </>
  );
};
