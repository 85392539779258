import { FLOW_TYPE } from "utils";
import { showCustomerSellerInfo } from "utils/vehicle";

import { IVehicleSummary } from "types";

import { DealershipInformation } from "./DealershipInformation";
import { SellerInformation } from "./SellerInformation";

type DealershipAndSellerProps = {
  vehicle: IVehicleSummary;
  flow: FLOW_TYPE;
};

export const DealershipAndSeller = ({
  vehicle,
  flow,
}: DealershipAndSellerProps) => {
  const { seller, requester } = vehicle;
  const showCustomerInfo = showCustomerSellerInfo(vehicle);

  const hasRequester =
    (requester?.dealership &&
      (!Array.isArray(requester?.dealership) ||
        requester?.dealership?.length > 0)) ||
    seller;

  const isAcquiredFromSeller = vehicle?.seller?.id === vehicle?.dealership?.id;

  if (!showCustomerInfo && !hasRequester && !isAcquiredFromSeller) {
    return null;
  }

  if (showCustomerInfo) {
    return (
      <SellerInformation
        data={vehicle || ({} as IVehicleSummary)}
        flow={flow}
      />
    );
  }

  return <DealershipInformation data={vehicle || ({} as IVehicleSummary)} />;
};
