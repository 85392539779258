import { ContractType } from "enums/ContractType";
import { useNavigate } from "react-router-dom";

import { FieldState } from "components/app";
import { Button } from "components/shared";

import { FLOW_TYPE, SOLD_COMBINED_STATUSES } from "utils";

import { IVehicleSummary } from "types";

import "./styles.scss";

interface SellerInformationProps {
  data: IVehicleSummary;
  flow: FLOW_TYPE;
}

export const SellerInformation = ({ data, flow }: SellerInformationProps) => {
  const navigate = useNavigate();
  const { contract_id } = data;
  const { id, email, first_name, last_name, phone, company_name, comments } =
    data?.owner || {};

  const path =
    flow === FLOW_TYPE.WHOLESALE_DEALERSHIP
      ? "/wholesale-dealership/clients"
      : "/retail-dealership/customers";

  const handleGoCustomerPage = () => {
    sessionStorage.setItem(
      "SCROLL_POSITION",
      `${document.getElementsByClassName("dashboard")[0].scrollTop}`
    );

    if (id) {
      navigate(`${path}/${id}/information`);
    } else {
      navigate(`${path}/information/${contract_id}`);
    }
  };

  return (
    <div className="section">
      <div className="section__header">
        <span>Seller Information</span>
      </div>
      <div className="disclosure__pannel--wide">
        <FieldState name="First Name" value={first_name} />
        <FieldState name="Mobile Number" value={phone} />
        <FieldState name="Last Name" value={last_name} />
        <FieldState name="Company Name" value={company_name} />
        <FieldState name="Email Address" value={email} />
        <FieldState
          name="Comments"
          style={{ gridRow: 4 }}
          variant="comment"
          value={comments?.comment}
        />
      </div>
      <div className="w-full flex justify-between items-end flex-1">
        <p className="text-sm text-label h-9">
          * Access editing page to add information
        </p>
        <div className="tab__panel__edit">
          <Button type="button" onClick={handleGoCustomerPage}>
            <span className="tab__panel__edit__title">
              {data?.owner?.id ? "View" : "Edit"}
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};
