import imageCompression from "browser-image-compression";
import Slider from "rc-slider";
import { ChangeEvent, FC, useRef, useState } from "react";
import { toast } from "react-toastify";

import { ModalCarouselCar } from "components/app";
import { ButtonLink, FieldText, SvgImage } from "components/shared";

import { IImage } from "types";

import { CustomerInspectionService, VehicleService } from "api/client";

import "rc-slider/assets/index.css";

interface ICosmeticItemProps {
  limit: number;
  cost: number | string | undefined | null;
  label: string;
  contractId: string;
  photos: IImage[];
  fromCustomer?: boolean;
  onChangeCost?: (val: number) => void;
  onChangeImage: (images: IImage[]) => void;
  latitude?: number | null;
  longitude?: number | null;
}

const handleStyle = {
  backgroundColor: "#2979FF",
  borderColor: "#2979FF",
};

const trackStyle = {
  backgroundColor: "#2979FF",
};

export const CosmeticItem: FC<ICosmeticItemProps> = ({
  limit,
  photos,
  label,
  contractId,
  cost,
  fromCustomer,
  onChangeCost,
  onChangeImage,
  latitude,
  longitude,
}) => {
  const [isCarousel, setIsCarousel] = useState<boolean>(false);
  const [activeCarouselItem, setActiveCarouselItem] = useState<number>(0);
  const fileInputField = useRef<HTMLInputElement>(null);
  const handleCost = (value: number | number[]) => {
    if (typeof value === "number") {
      onChangeCost?.(value);
    }
  };

  const handleInputCost = (value: ChangeEvent<HTMLInputElement>) => {
    const val = +value.target.value;
    if (val >= 0) {
      onChangeCost?.(val);
    }
  };

  const handleAddNewImage = () => {
    if (fileInputField.current) {
      fileInputField.current.click();
    }
  };

  const handleNewFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if ((e.target as HTMLInputElement)?.files?.length) {
      const imageFile = (e.target as HTMLInputElement)?.files?.[0];

      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      try {
        if (imageFile) {
          const compressedFile = await imageCompression(imageFile, options);

          const formData = new FormData();
          formData.append("image", compressedFile);
          formData.append("category", "conditions");
          formData.append("label", label);
          let uploadResult;
          if (fromCustomer) {
            if (latitude && longitude) {
              formData.append("latitude", `${latitude}`);
              formData.append("longitude", `${longitude}`);
            }
            uploadResult = await CustomerInspectionService.uploadImages(
              formData,
              contractId
            );
          } else {
            uploadResult = await VehicleService.uploadImage(
              formData,
              contractId
            );
          }
          onChangeImage([...photos, uploadResult.data as IImage]);
        }
      } catch (error) {
        toast.error((error as Error).message, {
          theme: "colored",
          autoClose: 2000,
        });
      }
    }
  };

  const handleRemoveImage = async (photo: IImage) => {
    try {
      if (fromCustomer) {
        await CustomerInspectionService.removeImage(
          { image_id: photo.id },
          contractId
        );
      } else {
        await VehicleService.removeImage({ image_id: photo.id }, contractId);
      }
      const filteredImages = photos.filter((image) => image.id !== photo.id);
      onChangeImage(filteredImages);
    } catch (err) {
      console.log("remove image error: ", err);
    }
  };

  const handleeCarousel = (activeIndex: number) => {
    setIsCarousel(true);
    setActiveCarouselItem(activeIndex);
  };

  return (
    <div className="cosmetic-item">
      {!!onChangeCost && (
        <>
          <div className="cosmetic-item__cost">
            <label>Repair Cost</label>
            <div className="cosmetic-item__cost__input">
              <label className="mr-1">$</label>
              <FieldText
                type="number"
                value={typeof cost === "number" ? cost : 0}
                onChange={handleInputCost}
              />
            </div>
          </div>
          <div className="cosmetic-item__slider">
            <Slider
              min={0}
              max={limit}
              value={Number(cost)}
              defaultValue={0}
              onChange={handleCost}
              handleStyle={handleStyle}
              trackStyle={trackStyle}
            />
          </div>
        </>
      )}
      <div className="cosmetic-item__photos">
        {photos?.map((photo, index) => (
          <div
            key={photo.id}
            className="photo__item"
            onClick={() => handleeCarousel(index)}
          >
            <img
              src={photo.thumb}
              alt="cosmetic logo"
              className="w-full h-full"
            />
            <div className="photo__item--delete">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemoveImage(photo);
                }}
              >
                <SvgImage name="TrashIcon" width={14} />
              </button>
            </div>
          </div>
        ))}
        {/* TODO: need to add more functionalities later */}
        <ButtonLink onClick={handleAddNewImage}>
          <div className="photo__item--add">
            <SvgImage name="CosmeticPhotoIcon" />
            <label className="mt-2">Add Photo</label>
          </div>
          <input
            type="file"
            ref={fileInputField}
            onChange={handleNewFileUpload}
            className="photo__item--upload"
            accept="image/*"
          />
        </ButtonLink>
      </div>
      <ModalCarouselCar
        open={isCarousel}
        images={photos}
        activeIndex={activeCarouselItem}
        closeModal={() => setIsCarousel(false)}
      />
    </div>
  );
};
