import { useQuery } from "@tanstack/react-query";

import { LinkButton } from "components/Button";

import { showErrorToast, StorageKeys } from "utils";

import { VerificationService } from "api/client/VerificationService";

type VerificationCertificateProps = {
  requestID?: string;
};

export const VerificationCertificate = ({
  requestID,
}: VerificationCertificateProps) => {
  const { isFetching: isDownloading, refetch: downloadCertificate } = useQuery<
    string,
    Error
  >(
    [StorageKeys.Verification, requestID],
    async () => await VerificationService.downloadCertificate(requestID || ""),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: async (data: string) => {
        window.open(data, "_blank", "noreferrer");
      },
      onError: (err: Error) => {
        console.log("err", err);
        showErrorToast("Failed to Open Certificate");
      },
    }
  );

  return (
    <div className="flex justify-start p-2">
      {isDownloading ? (
        <span className="text-label text-sm">Opening Certificate...</span>
      ) : (
        <LinkButton onClick={downloadCertificate}>
          <span className="!text-sm underline">
            Vehicle Verification Certificate
          </span>
        </LinkButton>
      )}
    </div>
  );
};
