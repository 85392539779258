import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect } from "react";

import { LoadItem } from "components/app";
import { modalOfferTestID } from "components/app/dataTestIDs";
import { ValuationLog } from "components/app/Summary/ValuationLog";

import { AuthContext } from "context/AuthContext";

import { IValuationLogHistory } from "types";

import { ValuationService } from "api/client";

import "../styles.scss";

export interface ModalOfferLogProps {
  open: boolean;
  valuationID: string | undefined;
  dataTestID?: string;
  declined?: boolean;
}

export const ModalOfferLog = ({
  open,
  valuationID,
  dataTestID,
  declined,
}: ModalOfferLogProps) => {
  const { userID } = useContext(AuthContext);

  const {
    data: logs,
    refetch,
    isLoading,
  } = useQuery<any, Error>(
    [`fetchValuationLogs_${valuationID}_${userID}`, valuationID],
    ValuationService.getValuationHistory
  );

  useEffect(() => {
    if (open && valuationID) {
      refetch();
    }
  }, [open, valuationID, refetch]);

  if (isLoading)
    return (
      <div className="offer" data-testid={dataTestID || modalOfferTestID}>
        <LoadItem />
      </div>
    );

  return (
    <div className="offer" data-testid={dataTestID || modalOfferTestID}>
      {declined
        ? logs?.data
            ?.filter((log: IValuationLogHistory) => log.status === "declined")
            .map((log: IValuationLogHistory, idx: number) => (
              <ValuationLog
                key={idx}
                name={log.valuer.name}
                price={log.new_amount}
                role={log.valuer.role || ""}
                date={log.created_at}
                status={log.status}
              />
            ))
        : logs?.data.map((log: IValuationLogHistory, idx: number) => (
            <ValuationLog
              key={idx}
              name={log.valuer.name}
              price={log.new_amount}
              role={log.valuer.role || ""}
              date={log.created_at}
              status={log.status}
            />
          ))}
    </div>
  );
};
