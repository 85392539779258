import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  TenderDealInformation,
  TenderSummaryHeader,
  TenderVehicleInformation,
} from "components/app";
import { BidSection } from "components/app/Tender/BidSection";
import { ErrorMessage } from "components/ErrorMessage";
import { RemoveTenderVehicleModal } from "components/Modal/RemoveVehicleModal/RemoveVehicleModal";
import { Banner, Breadcrumbs, Button } from "components/shared";
import { VehicleComments } from "components/VehicleNotes";
import { VehiclePhotoContainer } from "components/VehiclePhotoContainer";

import { AuthContext } from "context/AuthContext";

import {
  SELLING,
  showSuccessToast,
  SOLDTENDERED,
  TENDER_FLOW_TYPE,
  TENDER_MENU,
  TENDERED,
  VEHICLE_SUMMARY_TITLE,
} from "utils";

import {
  ITenderSummaryResponse,
  ITenderVehicleResponse,
  IVehicleSummaryResponse,
  NavigationMenuLabel,
} from "types";

import { AcquisitionService, TenderService } from "api/client";

export const TenderSellerVehicleSummary = () => {
  const { userID, hasPermission } = useContext(AuthContext);
  const params = useParams();
  const navigate = useNavigate();
  const [openRemoveModal, setOpenRemoveModal] = useState(false);

  const { data, refetch, isLoading, isError } = useQuery<
    ITenderVehicleResponse,
    Error
  >(
    [`tender-seller-vehicle-${userID}`, params.vehicleId, SELLING.name],
    TenderService.getTenderVehicleSummary,
    {
      cacheTime: 0,
      onError: (err) => {
        console.log(err);
      },
    }
  );

  const { data: tenderData } = useQuery<ITenderSummaryResponse | null>(
    [`tender-seller-summary-${userID}`, params.tenderId],
    TenderService.getTenderSummary,
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const { data: inspectionData, refetch: refetchVehicle } =
    useQuery<IVehicleSummaryResponse>(
      [
        ["tender-seller-acquisition-summary", data?.data.contract_id || 0],
        data?.data.contract_id,
        {
          with: "dealership,owner,seller,assessments,valuations,images,buyer,comment,expenses",
        },
      ],
      AcquisitionService.getAcquisitionSummary,
      {
        enabled: !!data?.data,
        cacheTime: 0,
        refetchOnWindowFocus: false,
      }
    );

  const { mutate } = useMutation(
    (data: { contract_ids: string[] }) =>
      TenderService.deleteVehicleFromTender(data, params.tenderId ?? ""),
    {
      onSuccess: () => {
        showSuccessToast("Vehicle Removed");
        navigate(-1);
      },
      onError: () => {
        showSuccessToast("Failed to Remove");
      },
    }
  );

  const handleOpenRemoveModal = () => setOpenRemoveModal(true);

  const handleCloseRemoveModal = () => setOpenRemoveModal(false);

  const handleRemoveFromTender = () => {
    mutate({ contract_ids: [data?.data.contract_id ?? ""] });
  };

  return (
    <div className="dashboard__container">
      <div className="dashboard__header">
        <Breadcrumbs
          paths={[
            {
              title: NavigationMenuLabel.TENDER,
              route: TENDER_MENU.selling.route,
            },
            { title: "Selling", route: TENDER_MENU.selling.route },
            {
              title: "Tender Summary",
              route: `${TENDER_MENU.selling.route}/${params.tenderId}/summary`,
            },
            {
              title: VEHICLE_SUMMARY_TITLE,
              route: `${TENDER_MENU.selling.route}/${params.tenderId}/${params.vehicleId}/summary`,
            },
          ]}
          showBackButton
        />
      </div>
      <div className="dashboard__content">
        {isLoading && <span>Loading...</span>}
        {isError && <ErrorMessage />}
        {data && inspectionData && !isError && (
          <>
            <div className="dashboard__content--left">
              <TenderSummaryHeader
                data={inspectionData.data}
                onRefresh={() => {
                  refetch();
                  refetchVehicle();
                }}
              />
              <TenderDealInformation
                data={{ ...data.data, owner: data.data.seller }}
                inspectionData={inspectionData.data}
                onRefresh={() => {
                  refetch();
                  refetchVehicle();
                }}
                defaultOpen
              />
              <TenderVehicleInformation
                data={inspectionData.data}
                from={TENDER_FLOW_TYPE.SELLING}
                onRefresh={() => {
                  refetchVehicle();
                }}
              />
              <VehiclePhotoContainer data={{ ...inspectionData.data }} />
            </div>
            <div className="dashboard__content--right">
              <Banner
                name={data.data?.status?.name || ""}
                label={data.data?.status?.label ?? TENDERED.label}
              />
              {data.data.status?.name !== SOLDTENDERED.key && (
                <>
                  <BidSection
                    tenderStatus={tenderData?.data.status || ""}
                    tenderVehicleId={data.data.tender_vehicle_id || ""}
                  />
                  <div className="w-52">
                    <Button variant="secondary" onClick={handleOpenRemoveModal}>
                      <b>REMOVE FROM TENDER</b>
                    </Button>
                  </div>
                  <VehicleComments contractID={data?.data?.contract_id} />
                </>
              )}
            </div>
          </>
        )}
      </div>
      <RemoveTenderVehicleModal
        open={openRemoveModal}
        onConfirm={handleRemoveFromTender}
        onCancel={handleCloseRemoveModal}
      />
    </div>
  );
};
