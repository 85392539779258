import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { useParams } from "react-router-dom";

import {
  LoadVehicleSummary,
  TenderDealershipInformation,
  TenderSaleOptions,
  TenderSection,
  TenderSummaryHeader,
  TenderVehicleInformation,
} from "components/app";
import { ErrorMessage } from "components/ErrorMessage";
import { Banner, Breadcrumbs } from "components/shared";
import { VehiclePhotoContainer } from "components/VehiclePhotoContainer";

import { AuthContext } from "context/AuthContext";
import { useDealership } from "context/DealershipContext";

import {
  BUYING,
  TENDER_FLOW_TYPE,
  TENDER_MENU,
  TENDERED,
  VEHICLE_SUMMARY_TITLE,
  WON,
} from "utils";
import { PermissionAction, PermissionCategory } from "utils/userPermissions";

import {
  ITenderSummaryResponse,
  ITenderVehicleResponse,
  IVehicleSummaryResponse,
  NavigationMenuLabel,
} from "types";

import { AcquisitionService, TenderService } from "api/client";

type TenderBuyerVehicleSummaryProps = {
  vehicleID?: string;
  tenderID?: string;
};

export const TenderBuyerVehicleSummary = ({
  tenderID,
  vehicleID,
}: TenderBuyerVehicleSummaryProps) => {
  const { dealershipID } = useDealership();
  const { userID, hasPermission } = useContext(AuthContext);
  const params = useParams();

  const tenderIdParam = params.tenderId || tenderID;
  const vehicleIdParam = params.vehicleId || vehicleID;

  const { data, refetch, isLoading, isError } = useQuery<
    ITenderVehicleResponse,
    Error
  >(
    [
      `tender-buyer-vehicle-${userID}`,
      vehicleIdParam,
      BUYING.name,
      dealershipID,
    ],
    TenderService.getTenderVehicleSummary,
    {
      cacheTime: 0,
      onError: (err) => {
        console.log(err);
      },
    }
  );

  const { data: tenderData, isLoading: tenderDataIsLoading } =
    useQuery<ITenderSummaryResponse>(
      [
        `tender-buyer-summary-${tenderIdParam}_${userID}`,
        tenderIdParam,
        dealershipID,
      ],
      TenderService.getTenderBuyingSummary,
      {
        cacheTime: 0,
        refetchOnWindowFocus: false,
      }
    );

  const { data: inspectionData, isLoading: inspectionDataIsLoading } =
    useQuery<IVehicleSummaryResponse>(
      [
        "tender-buyer-acquisition-summary",
        data?.data.contract_id,
        {
          with: "dealership,owner,seller,assessments,valuations,images,buyer,comment,expenses",
        },
      ],
      AcquisitionService.getAcquisitionSummary,
      {
        enabled: !!data?.data,
        cacheTime: 0,
      }
    );

  return (
    <div className="dashboard__container">
      <div className="dashboard__header">
        <Breadcrumbs
          paths={[
            {
              title: NavigationMenuLabel.TENDER,
              route: TENDER_MENU.buying.route,
            },
            { title: "Buying", route: TENDER_MENU.buying.route },
            {
              title: "Tender Summary",
              route: `${TENDER_MENU.buying.route}/${tenderIdParam}/summary`,
            },
            {
              title: VEHICLE_SUMMARY_TITLE,
              route: `${TENDER_MENU.buying.route}/${tenderIdParam}/${vehicleIdParam}/summary`,
            },
          ]}
          showBackButton
        />
      </div>
      <div className="dashboard__content">
        {(isLoading || tenderDataIsLoading || inspectionDataIsLoading) && (
          <LoadVehicleSummary />
        )}
        {isError && <ErrorMessage />}
        {data && inspectionData && !isError && (
          <>
            <div className="dashboard__content--left">
              <TenderSummaryHeader
                data={{
                  ...inspectionData.data,
                  ...(tenderData?.data.created_by
                    ? { created_by: tenderData?.data.created_by }
                    : ""),
                }}
                from={TENDER_FLOW_TYPE.BUYING}
                onRefresh={refetch}
                summaryData={data.data}
              />
              <TenderVehicleInformation
                data={inspectionData.data}
                defaultOpen
                from={TENDER_FLOW_TYPE.BUYING}
              />
              <TenderDealershipInformation data={inspectionData.data} />
              <VehiclePhotoContainer
                data={{ ...inspectionData.data, is_locked: true }}
              />
            </div>
            <div className="dashboard__content--right">
              <Banner
                name={data.data.status?.name ?? ""}
                label={data.data.status?.label ?? TENDERED.label}
              />
              {data.data.status?.name === WON.key && (
                <TenderSaleOptions
                  route={`/wholesale-dealership/stocklist/${inspectionData.data?.acquisitions?.new_contract_id}/undefined/summary`}
                />
              )}
              <TenderSection
                data={data.data}
                inspectionData={inspectionData.data}
                onRefresh={refetch}
                tenderDealershipId={tenderData?.data.dealership.id || ""}
                tenderStatus={tenderData?.data.status || ""}
                canBid={hasPermission(
                  PermissionCategory.TENDER_BUYING,
                  PermissionAction.BID
                )}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
