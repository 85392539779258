import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import { InformativeModal } from "components/Modal/InformativeModal";
import { Button, Modal, SvgImage } from "components/shared";
import { ContactSupportLink } from "components/Support/ContactSupportLink";

import { ActionResult, ActionResultMessage } from "utils";

import { CustomerInspectionService } from "api/client";

import { useGeolocation } from "hooks/useGeolocation";

type GeolocationInfoProps = {
  id: string;
  onDeclined: () => void;
  onContinue: () => void;
};

export const GeolocationInfo = ({
  id,
  onDeclined,
  onContinue,
}: GeolocationInfoProps) => {
  const [enableChecking, setEnableChecking] = useState(false);
  const [showSettingInfo, setShowSettingInfo] = useState(false);

  const { state } = useGeolocation({ enableChecking });

  const { mutate } = useMutation(
    (payload: { latitude: string; longitude: string }) =>
      CustomerInspectionService.sendGPSLocation(payload, id)
  );

  useEffect(() => {
    if (state.permission === "granted" && state.latitude && state.longitude) {
      onContinue();
      mutate({
        latitude: `${state.latitude}`,
        longitude: `${state.longitude}`,
      });
    }

    if (state.permission === "denied") {
      setShowSettingInfo(true);
    }
  }, [state, mutate, onContinue]);

  const onCloseSettingInfo = () => {
    window.location.reload();
  };

  return (
    <>
      <div className="flex-1 w-full flex flex-col overflow-auto px-5 mt-0 md:mt-[40px]">
        <h5 className="text-primary font-bold text-23 mt-10 mb-0">
          GPS Location Needed
        </h5>
        <p className="text-txt my-3 !text-base">
          We require your location to validate where the photos are taken.
        </p>
        <div className="my-15 flex justify-center">
          <SvgImage name="MapIcon" />
        </div>

        <Button
          type="submit"
          onClick={() => {
            setEnableChecking(true);
          }}
        >
          <span className="font-bold uppercase">Enable my Location</span>
        </Button>
        <div className="flex justify-center mt-8">
          <span className="font-bold uppercase text-sm" onClick={onDeclined}>
            Maybe Later
          </span>
        </div>
        <div className="mt-10">
          <ContactSupportLink hasMPLogo />
        </div>
      </div>
      <Modal
        open={showSettingInfo}
        size="auto"
        canClose={false}
        closeModal={() => onCloseSettingInfo()}
      >
        <InformativeModal
          content={{
            title:
              ActionResultMessage[ActionResult.DisabledGPSPermission].title,
            message: `${
              ActionResultMessage[ActionResult.DisabledGPSPermission].message
            }`,
          }}
          onClose={() => onCloseSettingInfo()}
        />
      </Modal>
    </>
  );
};
