import { ModalContainer } from "components/ModalContainer";
import { SvgImage } from "components/shared";

type SupportModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const SupportModal = ({ isOpen, onClose }: SupportModalProps) => {
  return (
    <ModalContainer isOpen={isOpen} onClose={onClose}>
      <div className="w-full  md:w-[768px] lg:w-[1024px] flex flex-col md:flex-row my-5">
        <div className="w-full md:w-[500px] flex-shrink-0">
          <SvgImage
            name="SupportHero"
            className="mx-auto lg:mr-4 mb-4 lg:mb-0"
          />
        </div>
        <div className="w-full flex flex-col justify-center gap-5">
          <h4 className="text-sm font-bold">Customer Care</h4>
          <h2 className="text-4xl text-bold ">We are here to help</h2>
          <p className="text-sm">
            We’re dedicated to helping you make the most of MotorPlatform.
            Whether you have a query, want to add new users, or have feedback
            and ideas for improvement, our support team is just a call or email
            away.
          </p>
          <div className="flex my-3">
            <a
              href="mailto:support@motorplatform.com.au"
              className="rounded-full text-white w-[125px] py-2 text-sm bg-primary text-center uppercase mr-5 font-bold"
            >
              Email Us
            </a>

            <a
              href="tel:+611300278998"
              className="rounded-full text-white w-[125px] py-2 text-sm  bg-primary uppercase text-center font-bold"
            >
              Call Us
            </a>
          </div>
          <p className="text-sm">
            Visit our website at{" "}
            <a
              href="https://www.motorplatform.com.au/"
              className="text-secondary"
              target="_blank"
              rel="noreferrer"
            >
              www.motorplatform.com.au
            </a>{" "}
            to explore more features and tools designed to streamline your
            operations.
          </p>
        </div>
      </div>
    </ModalContainer>
  );
};
