import { IQueryKeyProps } from "types";
import {
  IMotorMarket,
  IMotorMarketBidDetails,
  IMotorMarketBidHistory,
  IMotorMarketBuyerResponse,
  IMotorMarketDraftResponse,
  IMotorMarketForm,
  IMotorMarketResponse,
  IMotorMarketVehicleListAcquired,
  IMotorMarketVehicleListAcquiredPaginated,
} from "types/IMotorMarket";

import { api, url } from "api";

export const MotorMarketService = {
  getMyListings: ({ queryKey }: IQueryKeyProps) =>
    api.get(
      url.motorMarketSelling,
      queryKey[1]
    ) as Promise<IMotorMarketResponse>,

  getGroupDetails: ({ queryKey }: IQueryKeyProps) =>
    api.get(`${url.motorMarketSelling}/groups/${queryKey[1]}`) as Promise<{
      data: IMotorMarket;
    }>,

  createGroup: (payload: IMotorMarketForm) =>
    api.post(`${url.motorMarketSelling}/groups/draft`, payload) as Promise<{
      data: IMotorMarketDraftResponse;
    }>,

  updateGroup: (auctionID: string, status: string, payload: IMotorMarketForm) =>
    api.put(`${url.motorMarketSelling}/groups/${auctionID}/${status}`, payload),

  getVehicleList: ({ queryKey }: IQueryKeyProps) =>
    api.get(
      `${url.motorMarketSelling}/acquired`,
      queryKey[1]
    ) as Promise<IMotorMarketVehicleListAcquiredPaginated>,

  addVehicleToMotorMarket: (id: string, payload: { contract_ids: string[] }) =>
    api.post(`${url.motorMarketSelling}/${id}/vehicles`, payload),

  deleteGroup: (actionId: string) =>
    api.delete(`${url.motorMarketSelling}/groups/${actionId}`),

  getGroupList: () =>
    api.get(
      `${url.motorMarketSelling}/groups`
    ) as Promise<IMotorMarketResponse>,

  getBids: ({ queryKey }: IQueryKeyProps) =>
    api.get(`${url.motorMarketBids}/${queryKey[1]}`) as Promise<{
      data: IMotorMarketBidDetails[];
    }>,

  getBidHistory: ({ queryKey }: IQueryKeyProps) =>
    api.get(`${url.motorMarketBids}/${queryKey[1]}/all-bids`, {
      dealership_id: queryKey[2],
    }) as Promise<{
      data: IMotorMarketBidHistory[];
    }>,

  getSellerBidHistory: ({ queryKey }: IQueryKeyProps) =>
    api.get(`${url.motorMarketBids}/${queryKey[1]}/histories`) as Promise<{
      data: IMotorMarketBidHistory[];
    }>,

  deleteVehicle: (auctionVehicleID: string) =>
    api.delete(`${url.motorMarketSelling}/${auctionVehicleID}`),

  getBuying: ({ queryKey }: IQueryKeyProps) =>
    api.get(
      url.motorMarketBuying,
      queryKey[1]
    ) as Promise<IMotorMarketBuyerResponse>,

  getWatchlist: () =>
    api.get(
      `${url.motorMarketBuying}/watchlist`
    ) as Promise<IMotorMarketBuyerResponse>,

  addWatchList: (auctionVehicleID: string) =>
    api.post(`${url.motorMarketBuying}/${auctionVehicleID}/watchlist`, {
      auction_vehicle_id: auctionVehicleID,
    }),

  deleteWatchList: (auctionVehicleID: string) =>
    api.delete(`${url.motorMarketBuying}/watchlist/${auctionVehicleID}`),

  addBid: (
    payload: { amount: number; dealership_id?: string | null },
    auction_vehicle_id: string
  ) => api.post(`${url.motorMarketBids}/${auction_vehicle_id}`, payload),

  addToWatchlist: (auctionVehicleID: string) =>
    api.post(`${url.motorMarketBuying}/${auctionVehicleID}/watchlist`, {
      auction_vehicle_id: auctionVehicleID,
    }),

  removeFromWatchlist: (auctionWatchlistID: string) =>
    api.delete(`${url.motorMarketBuying}/watchlist/${auctionWatchlistID}`),

  selectAuctionWinner: (auctionBidID: string) =>
    api.post(`${url.motorMarketBids}/${auctionBidID}/winner`, {}),
};
