import { useMemo } from "react";

export const useFormattedCoordinates = (location?: string) => {
  return useMemo(() => {
    if (!location) return null;

    const getCoordinatesFromUrl = (url: string) => {
      try {
        const queryParam = new URL(url).searchParams.get("query");
        if (!queryParam) return null;

        const [lat, lng] = queryParam.split(",");
        if (!lat || !lng) return null;

        const toDMS = (decimal: number, isLat: boolean) => {
          const absolute = Math.abs(decimal);
          const degrees = Math.floor(absolute);
          const minutes = Math.floor((absolute - degrees) * 60);
          const seconds = ((absolute - degrees - minutes / 60) * 3600).toFixed(
            1
          );

          const direction =
            decimal >= 0 ? (isLat ? "N" : "E") : isLat ? "S" : "W";
          return `${degrees}°${minutes}'${seconds}"${direction}`;
        };

        return `${toDMS(parseFloat(lat), true)}, ${toDMS(
          parseFloat(lng),
          false
        )}`;
      } catch (error) {
        console.error("Error parsing location:", error);
        return null;
      }
    };

    return getCoordinatesFromUrl(location);
  }, [location]);
};
