import { Tab as UITab } from "@headlessui/react";
import classNames from "classnames";
import { ReactElement } from "react";

import { Button } from "../Buttons";
import "./styles.scss";

export interface ITabItem {
  label: string;
  key: string;
  tabPanel: ReactElement;
  hidden?: boolean;
  unmount?: boolean;
  onEdit?: () => void;
}

export interface ITabProps {
  defaultActiveTab?: number;
  items: ITabItem[];
  showPanel: boolean;
  onSetActiveTab: (tabIndex: number) => void;
  fitContent?: boolean;
}

export const Tab = ({
  defaultActiveTab,
  items,
  showPanel,
  onSetActiveTab,
  fitContent,
}: ITabProps) => {
  return (
    <div className="tab">
      <UITab.Group
        defaultIndex={defaultActiveTab}
        onChange={(index: number) => onSetActiveTab(index)}
      >
        <UITab.List className="tab__list no-scrollbar">
          {items.map((item) => (
            <UITab
              key={item.key}
              className={({ selected }) =>
                selected
                  ? classNames("tab__list__title--selected !px-3", {
                      hidden: item.hidden,
                      "!px-[14px]": fitContent,
                    })
                  : classNames("tab__list__title !px-3", {
                      hidden: item.hidden,
                      "!px-[14px]": fitContent,
                    })
              }
            >
              {item.label}
            </UITab>
          ))}
        </UITab.List>
        {showPanel && (
          <UITab.Panels className="tab__panels">
            {items.map((item) => (
              <UITab.Panel
                key={item.key}
                unmount={item.unmount}
                className="tab__panel"
              >
                <>
                  {item.tabPanel}
                  {item.onEdit && (
                    <div className="w-full flex justify-between items-end flex-1">
                      <p className="text-sm text-label h-9">
                        * Access editing page to add information
                      </p>
                      <div className="tab__panel__edit">
                        <Button type="button" onClick={item.onEdit}>
                          <span className="tab__panel__edit__title">Edit</span>
                        </Button>
                      </div>
                    </div>
                  )}
                </>
              </UITab.Panel>
            ))}
          </UITab.Panels>
        )}
      </UITab.Group>
    </div>
  );
};
