import { IQueryKeyProps, PPSRCheckInfo, PPSRCheckInfoResponse } from "types";

import { api, url } from "api";

export const PPSRService = {
  getRequests: ({ queryKey }: IQueryKeyProps) =>
    api.get(url.ppsr, queryKey[1]) as Promise<PPSRCheckInfoResponse>,
  request: ({
    vin,
    refetch,
    dealership_id,
    force_search,
  }: {
    vin: string;
    refetch: boolean;
    dealership_id: string;
    force_search: boolean;
  }) =>
    api.post(`${url.ppsr}/search`, {
      vin,
      dealership_id,
      refetch,
      force_search,
    }) as Promise<{ data: PPSRCheckInfo }>,
  fetchCertificate: (ppsrID: string) =>
    api.post(`${url.ppsr}/certificate/${ppsrID}`, {}) as Promise<string>,
  refresh: (searchID: string) =>
    api.post(`${url.ppsr}/status/${searchID}`, {}) as Promise<PPSRCheckInfo>,
  history: (contractID: string, page: number) =>
    api.get(`${url.ppsr}/${contractID}/history`, {
      page,
    }) as Promise<PPSRCheckInfoResponse>,
};
