import { ModalConfirm } from "components/app";
import { Modal } from "components/shared";

type DeclineRequestModalProps = {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  isLoading?: boolean;
};

export const DeclineRequestModal = ({
  open,
  onCancel,
  onConfirm,
  isLoading,
}: DeclineRequestModalProps) => (
  <Modal open={open} closeModal={onCancel} size="auto">
    <ModalConfirm
      title="Decline This Request?"
      description="This will move the vehicle into Lost and inform the requesting dealership."
      onCancel={onCancel}
      onConfirm={onConfirm}
      isLoading={isLoading}
    />
  </Modal>
);
