import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect } from "react";

import { modalMotorIntelLogTestID } from "components/app/dataTestIDs";
import { LoadItem } from "components/app/LoadVehicle";
import { ValuationLog } from "components/app/Summary/ValuationLog";

import { AuthContext } from "context/AuthContext";

import { ValuationService } from "api/client";

import "../styles.scss";

export interface ModalMotorIntelLogProps {
  open: boolean;
  valuation_id: string;
  dataTestID?: string;
}

export const ModalMotorIntelLog = ({
  open,
  valuation_id,
  dataTestID,
}: ModalMotorIntelLogProps) => {
  const { userID } = useContext(AuthContext);

  const {
    data: logs,
    refetch,
    isLoading,
  } = useQuery<any, Error>(
    [`fetchValuationLogs_${valuation_id}_${userID}`, valuation_id],
    ValuationService.getValuationHistory
  );

  useEffect(() => {
    if (open && valuation_id) {
      refetch();
    }
  }, [open, valuation_id, refetch]);

  return (
    <div
      className="mp-valuation"
      data-testid={dataTestID || modalMotorIntelLogTestID}
    >
      <div className="flex flex-col justify-center text-center">
        <label className="mp-valuation__title pb-2">
          MotorIntel Valuation Log
        </label>
        <p className="text-sm text-label w-min min-w-fit">
          MotorIntel is the valuation engine for MotorPlatform.
        </p>
      </div>

      <div className="mt-1">
        {isLoading ? (
          <LoadItem />
        ) : (
          logs?.data?.map((log: any, idx: number) => (
            <ValuationLog
              key={idx}
              name={log.valuer.name}
              price={log.new_amount}
              role={log.valuer.role || ""}
              date={log.created_at}
              status={log.status}
            />
          ))
        )}
      </div>
    </div>
  );
};
