export enum VehicleInformationTab {
  ReportedDetails = 0,
  Comments = 1,
  Specification = 2,
  FeaturesAndOptions = 3,
  FactoryOptions = 4,
  Condition = 5,
  Compliance = 6,
  PPSR = 7,
}

export enum VehicleConditionTypes {
  SpareKey = "spare-key",
  TestDriven = "test-driven",
  ServiceHistory = "service-history",
  ServiceDueIn3Months = "service-due-in-3-months",
  MechanicalAndRoadworthy = "mechanical-and-roadworthy",
  CosmeticCondition = "cosmetic-condition",
  AdditionalInformation = "additional-information",
}
