import { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { AuthContext } from "context/AuthContext";

import { useResetPasswordParams } from "hooks/useResetPasswordParams";

const AuthorizedRoute = ({ children }: { children: JSX.Element }) => {
  const { authToken } = useContext(AuthContext);
  const location = useLocation();
  const { email, token } = useResetPasswordParams();
  if (token && email) {
    return <Navigate to={`/reset/${token}/${email}`} replace />;
  }

  if (!authToken) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default AuthorizedRoute;
