import { useMutation, useQuery } from "@tanstack/react-query";
import { VehicleConditionTypes } from "enums/VehicleInformation";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { CosmeticItem } from "pages/RetailDealership/Checklists/Condition/CosmeticItem";

import {
  Button,
  ButtonLink,
  FieldCheckbox,
  FieldDropdown,
  FieldRadio,
  FieldTextarea,
  SvgImage,
} from "components/shared";
import { ContactSupportLink } from "components/Support/ContactSupportLink";

import { AuthContext } from "context/AuthContext";

import { CUSTOMER_INSPECTION_DATA, INSPECTION_STEP } from "utils";

import { ICustomerInspection, IFormRange, IImage, IQuestionnaire } from "types";

import { CustomerInspectionService, InspectionService } from "api/client";

import { useLocalStorage } from "hooks";
import { useGeolocation } from "hooks/useGeolocation";

import { GeolocationInfo } from "./GeolocationInfo";

type AnswerProps = {
  [key in string]?: Array<TCheckbox> | string | string[];
};

type TCheckbox = {
  slug: string;
  costs: string | number | undefined | null;
  images: IImage[];
};

export const Conditions = () => {
  const { userID } = useContext(AuthContext);
  const navigate = useNavigate();
  const params = useParams();
  const [answers, setAnswers] = useState<any>({});
  const [savedData, saveInspection] = useLocalStorage(
    CUSTOMER_INSPECTION_DATA,
    null
  );

  const [showGeolocation, setShowGeolocation] = useState(true);

  const { data: verifyData } = useQuery(
    [`verify_inspection_link_${userID}`, params.id],
    CustomerInspectionService.verifyInspectionLInk
  );

  const { data: questionnaire } = useQuery<{ data: IQuestionnaire[] }, Error>(
    [`fetchQuestionnaire_${userID}`],
    InspectionService.getQuestionnaire,
    {
      refetchOnWindowFocus: false,
    }
  );

  const isVehicleVerification = useMemo(() => {
    return verifyData?.data?.is_vehicle_verification || false;
  }, [verifyData?.data]);

  const { state } = useGeolocation({ enableChecking: isVehicleVerification });
  const { latitude, longitude } = state;

  const { mutate, isLoading } = useMutation(
    (data: any) => CustomerInspectionService.saveAnswers(data, params.id!),
    {
      onSuccess: (res: any) => {
        toast.success("Saved Successfully", {
          autoClose: 2000,
          theme: "colored",
        });
        saveInspection(res.data);
        navigate("../picture");
      },
      onError: (e: any) => {
        let message = "";

        if (e.data.message !== "") {
          if (e.data.message === "The answers field is required.") {
            message = "Answers are required.";
          } else {
            message = e.data.message;
          }
        } else {
          message = "Failed to Save";
        }
        toast.error(message, {
          autoClose: 2000,
          theme: "colored",
        });
      },
    }
  );

  useEffect(() => {
    if (verifyData) {
      const { status } = verifyData.data;

      if (status.name === INSPECTION_STEP.INSPECTION_SUBMITTED) {
        return navigate(`/inspection/customer/${params.id}/complete`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyData]);

  const formData = questionnaire ? questionnaire.data[1].questionnaire : [];

  useEffect(() => {
    if (savedData) {
      const newAnswers: any = {};
      const { assessments } = savedData as ICustomerInspection;

      assessments[1].questionnaire.forEach((field) => {
        if (
          field.question_slug === "additional-information" &&
          field.answers?.comments &&
          field.answers?.comments.length !== 0 &&
          field?.answers?.comments[0] !== "undefined"
        ) {
          newAnswers[field.question_slug] = `${field.answers?.comments[0]}`;
        } else if (field.answers?.options) {
          if (
            field.answers.options.length === 1 &&
            field.question_slug !== VehicleConditionTypes.CosmeticCondition &&
            field.question_slug !==
              VehicleConditionTypes.MechanicalAndRoadworthy
          ) {
            newAnswers[field.question_slug] =
              field.answers.options[0].option_slug;
          } else {
            newAnswers[field.question_slug] = field.answers.options.map(
              (option) => ({
                slug: option.option_slug,
                costs: option.costs || 0,
                images:
                  option.images && Object.entries(option.images).length > 0
                    ? Object.values(option.images)
                    : [],
              })
            );
          }
        }
      });

      setAnswers(newAnswers);
    } else {
      // navigate('../enter-kms')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedData]);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleContinue = () => {
    const payload: any[] = [];
    Object.keys(answers).forEach((key) => {
      if (typeof answers[key] === "string") {
        if (key === "additional-information") {
          payload.push({ question_slug: key, comments: [answers[key]] });
        } else {
          payload.push({ question_slug: key, options: [answers[key]] });
        }
      } else {
        if (
          [
            VehicleConditionTypes.CosmeticCondition,
            VehicleConditionTypes.MechanicalAndRoadworthy,
          ].includes(key as VehicleConditionTypes) &&
          answers[key].length > 0
        ) {
          payload.push({
            question_slug: key,
            options: answers[key].map((answer: TCheckbox) => answer.slug),
          });
        } else {
          payload.push({
            question_slug: key,
            options: answers[key].map((answer: TCheckbox) => answer.slug),
            costs: answers[key]
              .filter((answer: TCheckbox) => answer.costs !== null)
              .map((answer: TCheckbox) => ({
                cost_for: answer.slug,
                amount: answer.costs,
              })),
          });
        }
      }
    });

    const data = {
      answers: payload,
      type: "conditions",
    };

    mutate(data);
  };

  const handleToggleCheck = (categorySlug: string, optionSlug: string) => {
    if (!answers[categorySlug]) {
      setAnswers({
        ...answers,
        [categorySlug]: [{ slug: optionSlug, costs: null, images: [] }],
      });
    } else if (
      (answers[categorySlug] as TCheckbox[])?.findIndex(
        (val: TCheckbox) => val.slug === optionSlug
      ) > -1
    ) {
      setAnswers({
        ...answers,
        [categorySlug]: (answers[categorySlug] as TCheckbox[])?.filter(
          (val: TCheckbox) => val.slug !== optionSlug
        ),
      });
    } else {
      setAnswers({
        ...answers,
        [categorySlug]: [
          ...((answers?.[categorySlug] ?? []) as TCheckbox[]),
          { slug: optionSlug, costs: null, images: [] },
        ],
      });
    }
  };

  const handleChangeImages = (
    categorySlug: string,
    optionSlug: string,
    imageIds: IImage[]
  ) => {
    const selectedOption = (answers[categorySlug] as TCheckbox[])?.find(
      ({ slug }: TCheckbox) => slug === optionSlug
    );

    setAnswers({
      ...answers,
      [categorySlug]: [
        ...((answers[categorySlug] as TCheckbox[])?.filter(
          (c: TCheckbox) => c.slug !== optionSlug
        ) ?? []),
        { ...selectedOption, images: imageIds },
      ] as TCheckbox[],
    });
  };

  const onContinue = () => {
    setShowGeolocation(false);
  };

  if (showGeolocation && isVehicleVerification) {
    return (
      <GeolocationInfo
        onDeclined={handleGoBack}
        onContinue={onContinue}
        id={params.id || ""}
      />
    );
  }

  return (
    <div className="flex flex-col justify-between text-primary w-full h-[calc(100%-50px)]">
      <div className="absolute h-0 md:h-10 w-full left-0 border-b-[7px] border-softPeach">
        <div className="bg-primary h-[7px] mt-0 md:mt-33 w-3/4" />
      </div>
      <div className="flex-1 w-full flex flex-col overflow-auto px-5 mt-0 md:mt-[40px]">
        <div className="text-black text-sm pt-7">
          <ButtonLink onClick={handleGoBack}>
            {"< Back to Specifications"}
          </ButtonLink>
        </div>
        <h5 className="text-primary font-bold text-23 mt-4 mb-0">
          Vehicle Inspection
        </h5>
        <div>
          <label className="text-primary text-sm font-bold leading-10">
            STEP 2/3 - CONDITIONS
          </label>
          <div className="text-sm mb-2 flex uppercase rounded-10 text-white bg-darkRed w-[120px] justify-around border-r px-2 py-1">
            <SvgImage name="WarningTriangleIcon" />
            Important
          </div>

          <p className="text-label text-sm font-normal">
            You must provide photos and details of any damage or faults
            affecting value. If missing or incorrect information is discovered,
            we may revise the valuation, and you will be liable for any
            difference.
          </p>
        </div>
        <div className="view-specification__content mt-3">
          <div>
            {formData.map((category) => (
              <div className="mb-10" key={category.slug}>
                {category.field_type === "switch" && (
                  <>
                    <p className="text-lg	text-lightGrey font-bold">
                      {category.title}:
                    </p>
                    <div className="grid grid-cols-2">
                      {category.options.map((option) => (
                        <FieldRadio
                          key={option.slug}
                          name={category.slug}
                          label={option?.title}
                          checked={answers[category.slug] === option.slug}
                          onChange={() => {
                            setAnswers({
                              ...answers,
                              [category.slug]: option.slug,
                            });
                          }}
                        />
                      ))}
                    </div>
                  </>
                )}
                {category.field_type === "dropdown" && (
                  <>
                    <p className="ctext-lg	text-lightGrey font-bold">
                      {category.title}:
                    </p>
                    <FieldDropdown
                      placeholder="Select"
                      options={category.options.map((option) => ({
                        key: option.slug,
                        label: option.title,
                      }))}
                      value={answers[category.slug]}
                      onChange={(val) => {
                        setAnswers({
                          ...answers,
                          [category.slug]: val,
                        });
                      }}
                    />
                  </>
                )}
                {category.field_type === "checkbox" && (
                  <>
                    <label className="condition-title">{category.title}</label>
                    {category.options.map((option) => (
                      <div className="mt-6" key={option.slug}>
                        <FieldCheckbox
                          checked={
                            answers?.[category.slug]?.findIndex(
                              (item: TCheckbox) => item?.slug === option.slug
                            ) > -1
                          }
                          onChange={() =>
                            handleToggleCheck(category.slug, option.slug)
                          }
                          label={option.title}
                        />
                        {answers[category.slug] &&
                          typeof answers[category.slug] !== "string" &&
                          (answers[category.slug] as TCheckbox[]).findIndex(
                            (val: TCheckbox) => val.slug === option.slug
                          ) > -1 && (
                            <CosmeticItem
                              limit={Number(
                                (option.children[0] as IFormRange).data.max
                              )}
                              label={option.slug}
                              onChangeImage={(imageIds: IImage[]) =>
                                handleChangeImages(
                                  category.slug,
                                  option.slug,
                                  imageIds
                                )
                              }
                              contractId={params.id as string}
                              photos={
                                (
                                  answers[
                                    category.slug as keyof AnswerProps
                                  ] as TCheckbox[]
                                )?.find(
                                  (val: TCheckbox) => val.slug === option.slug
                                )?.images ?? []
                              }
                              fromCustomer
                              latitude={latitude}
                              longitude={longitude}
                              cost={null}
                            />
                          )}
                      </div>
                    ))}
                  </>
                )}
                {category.field_type === "textbox" && (
                  <FieldTextarea
                    title={category.title}
                    placeholder={
                      "Enter any comments regarding the condition of your vehicle"
                    }
                    value={answers[category.slug]}
                    onChange={(evt) => {
                      setAnswers({
                        ...answers,
                        [category.slug]: evt.target.value,
                      });
                    }}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="w-full flex justify-center flex-col items-center mb-2">
          <div className="w-[310px] pb-2">
            <Button
              type="submit"
              isLoading={isLoading}
              onClick={handleContinue}
            >
              <span className="font-bold">Continue</span>
            </Button>
          </div>
          <ContactSupportLink hasMPLogo />
        </div>
      </div>
    </div>
  );
};
