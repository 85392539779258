import { Breadcrumbs } from "components/shared";

import {
  RETAIL_DEALERSHIP_MENU,
  VEHICLE_SUMMARY_TITLE,
  WHOLESALE_DEALERSHIP_MENU,
} from "utils";

import { NavigationMenuLabel } from "types";

import { useGetSummaryRoute } from "hooks/useGetSummaryRoute";

type NavigationLinkProps = {
  isRetail: boolean;
  title: string;
  isGlobalSearch: boolean;
};

export const SummaryNavigationLink = ({
  title,
  isRetail,
  isGlobalSearch,
}: NavigationLinkProps) => {
  const route = useGetSummaryRoute({ isRetail, title });

  if (isGlobalSearch) {
    return null;
  }

  return (
    <Breadcrumbs
      paths={[
        {
          title: isRetail
            ? NavigationMenuLabel.RETAIL_DEALERSHIP
            : NavigationMenuLabel.WHOLESALE_DEALERSHIP,
          route: isRetail
            ? RETAIL_DEALERSHIP_MENU[0].route
            : WHOLESALE_DEALERSHIP_MENU[0].route,
        },
        { title: title, route },
        {
          title: VEHICLE_SUMMARY_TITLE,
        },
      ]}
      showBackButton
    />
  );
};
