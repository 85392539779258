import { IVehicleSummary } from "types";
import { SubmitVerificationPayload } from "types/IVerification";

import { api, url } from "api";

export const VerificationService = {
  submitVerification: (payload: SubmitVerificationPayload, requestID: string) =>
    api.post(`${url.verification}/${requestID}`, payload) as Promise<{
      data: IVehicleSummary;
    }>,
  downloadCertificate: (requestID: string) =>
    api.get(`${url.verification}/${requestID}/download`) as Promise<string>,
};
