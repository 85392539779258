import { LoadingSpinner } from "components/Loading/LoadingSpinner";

type FieldDocumentProps = {
  label: string;
  value: { name: string; documentID: string }[];
  onDownload: ({
    documentID,
    name,
  }: {
    documentID: string;
    name: string;
  }) => void;
  isLoading: boolean;
};

export const FieldDocument = ({
  label,
  value,
  onDownload,
  isLoading,
}: FieldDocumentProps) => {
  return (
    <div className="flex items-baseline w-full min-h-[2.25rem]">
      <span className="flex-1 max-w-120 text-sm text-darktxt font-semibold">
        {label}
      </span>
      <div>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          value?.map(({ documentID, name }) => (
            <div
              key={documentID}
              className="underline cursor-pointer"
              onClick={() => onDownload({ documentID, name })}
            >
              {name}
            </div>
          ))
        )}
      </div>
    </div>
  );
};
