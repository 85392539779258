import { useMutation } from "@tanstack/react-query";
import { useContext, useState } from "react";

import { Card } from "components/Card";
import { ConfirmationModal } from "components/Modal/ConfirmationModal";
import { Odometer } from "components/Odometer";
import { Button } from "components/shared";
import { Timer } from "components/Timer";
import { Tooltip } from "components/Tooltip";

import { AuthContext } from "context/AuthContext";

import {
  ActionResult,
  ActionResultMessage,
  MOTOR_MARKET_DRAFT,
  MOTOR_MARKET_ENDED,
  MOTOR_MARKET_SOLD,
  MOTOR_MARKET_WITHDRAWN,
  createEllipsisText,
  formatDateToJsDate,
  formatNumber,
  showErrorToast,
  showSuccessToast,
} from "utils";
import { PermissionAction, PermissionCategory } from "utils/userPermissions";

import { IMotorMarket } from "types/IMotorMarket";

import { ReactComponent as PinIcon } from "assets/images/pin.svg";

import { MotorMarketService } from "api/client";

export const ListVehicles = ({
  data,
  status,
  onClick,
  refetch,
}: {
  data: IMotorMarket[];
  status: string;
  onClick: (id: IMotorMarket) => void;
  refetch: () => void;
}) => {
  const { hasPermission } = useContext(AuthContext);
  const [
    isRemoveVehicleConfirmationModalOpen,
    setIsRemoveVehicleConfirmationModalOpen,
  ] = useState(false);

  const [
    isDeleteGroupConfirmationModalOpen,
    setIsDeleteGroupConfirmatonModalOpen,
  ] = useState(false);

  const [isConfirmSaleModalOpen, setIsConfirmSaleModalOpen] = useState(false);

  const [currentClickedAuction, setCurrentClickedAuction] = useState<
    IMotorMarket | undefined
  >(undefined);

  const { mutate: deleteGroup, isLoading: isDeletingGroup } = useMutation(
    (id: string) => MotorMarketService.deleteGroup(id),
    {
      onSuccess: () => {
        showSuccessToast("Deleted Successfully");
        setIsDeleteGroupConfirmatonModalOpen(false);
        setCurrentClickedAuction(undefined);
        refetch();
      },
      onError: () => {
        showErrorToast("Failed to Remove");
        setIsDeleteGroupConfirmatonModalOpen(false);
        setCurrentClickedAuction(undefined);
      },
    }
  );

  const { mutate: removeVehicle, isLoading: isRemovingVehicle } = useMutation(
    (id: string) => MotorMarketService.deleteVehicle(id),
    {
      onSuccess: () => {
        showSuccessToast("Vehicle Removed");
        setIsRemoveVehicleConfirmationModalOpen(false);
        setCurrentClickedAuction(undefined);
        refetch();
      },
      onError: () => {
        showErrorToast("Failed to Remove");
        setIsRemoveVehicleConfirmationModalOpen(false);
        setCurrentClickedAuction(undefined);
      },
    }
  );

  const { mutate: sellVehicle, isLoading: isSellingVehicle } = useMutation(
    (auctionBidID: string) =>
      MotorMarketService.selectAuctionWinner(auctionBidID),
    {
      onSuccess: () => {
        setIsConfirmSaleModalOpen(false);
        showSuccessToast("Sold Successfully");
        refetch();
      },
      onError: () => {
        showErrorToast("Failed To Sell");
      },
    }
  );

  return (
    <>
      {data.map((auction) => {
        if (status === MOTOR_MARKET_DRAFT.key) {
          return (
            <Card
              coverSource={
                auction?.seller?.logo_url || auction?.images?.[0]?.url
              }
              key={auction.auction_group_id || auction.auction_vehicle_id}
              content={[
                <div
                  className="flex flex-col gap-2.5"
                  key={auction.auction_group_id || auction.auction_vehicle_id}
                >
                  <p className="text-base">
                    <span className="font-semibold">{"Auction Ending: "} </span>
                    {auction.ends_at}
                  </p>
                  <div className="flex items-center">
                    <PinIcon />
                    <p className="text-sm text-label mx-1">
                      {auction.contact.location ?? "TBD"}
                    </p>
                  </div>
                  <p className="text-sm">
                    {"Contact Name: "}
                    <span className="font-semibold">
                      {auction.contact.name}
                    </span>
                  </p>
                  <p className="text-sm">
                    {"Contact Number: "}
                    <span className="font-semibold">
                      {auction.contact.phone}
                    </span>
                  </p>
                </div>,
              ]}
              actions={[
                <button
                  key={"delete-card-button"}
                  className="underline text-darkRed"
                  onClick={(e) => {
                    e.stopPropagation();
                    setCurrentClickedAuction(auction);
                    setIsDeleteGroupConfirmatonModalOpen(true);
                  }}
                >
                  <p className="text-sm text-darkRed">Delete</p>
                </button>,
              ]}
              onClick={() => onClick(auction)}
              showAllImage
            />
          );
        }

        return (
          <Card
            key={auction.auction_group_id || auction.auction_vehicle_id}
            coverSource={auction?.seller?.logo_url || auction?.images?.[0]?.url}
            hasWithdrawn={auction.has_withdrawn}
            content={[
              <div
                className="flex flex-row gap-5"
                key={auction.auction_group_id || auction.auction_vehicle_id}
              >
                <div className="flex flex-col gap-2.5 max-w-md">
                  <p className="text-base">
                    <span className="font-semibold">
                      <Tooltip
                        className="truncate whitespace-normal line-clamp-2 max-w-sm"
                        tooltip={auction.vehicle.name}
                      >
                        {auction.vehicle.name}
                      </Tooltip>
                    </span>
                  </p>
                  <p className="text-sm">
                    <Odometer value={auction?.vehicle?.odometer} />
                  </p>
                  <div className="flex items-center">
                    <p className="text-sm text-label ">
                      {createEllipsisText(
                        Object.values(auction.vehicle.specification)
                          .filter(
                            (value) =>
                              !!value &&
                              !auction.vehicle.name.includes(
                                value as unknown as string
                              )
                          )
                          .join(" | "),
                        80
                      )}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col gap-2.5 max-w-sm">
                  <p className="text-sm">
                    {"Acquisition Price: "}
                    <span className="font-semibold">
                      {auction.acquisitions.acquisition
                        ? `$${formatNumber(
                            auction.acquisitions.acquisition?.amount
                          )}`
                        : "-"}
                    </span>
                  </p>
                  <p className="text-sm">
                    {"Bids: "}
                    <span className="font-semibold">
                      {auction.bid_summary.total_bids ?? "-"}
                    </span>
                  </p>
                  <p className="text-sm">
                    {"Highest Bid: "}
                    <span className="font-semibold">
                      {auction.bid_summary.highest_bid
                        ? `$${formatNumber(auction.bid_summary.highest_bid)}`
                        : "-"}
                    </span>
                  </p>
                  <p className="text-sm">
                    {"Bidder: "}
                    <span className="font-semibold">
                      {auction.bid_summary.bidder_name ?? "-"}
                    </span>
                  </p>
                </div>
              </div>,
            ]}
            status={
              status === MOTOR_MARKET_SOLD.key
                ? {
                    status: MOTOR_MARKET_SOLD.key,
                    label: MOTOR_MARKET_SOLD.label,
                    amount: auction.bid_summary.highest_bid,
                  }
                : auction.has_withdrawn
                ? {
                    status: MOTOR_MARKET_WITHDRAWN.key,
                    label: MOTOR_MARKET_WITHDRAWN.label,
                  }
                : undefined
            }
            liveTimer={
              status !== MOTOR_MARKET_DRAFT.key && (
                <Timer
                  targetDate={formatDateToJsDate(auction.ends_at)}
                  disabled={auction.has_withdrawn}
                />
              )
            }
            actions={
              !auction.has_withdrawn &&
              hasPermission(
                PermissionCategory.MOTOR_MARKET_SELLING,
                PermissionAction.DELETE
              )
                ? [
                    status !== MOTOR_MARKET_SOLD.key && (
                      <button
                        key={"delete-card-button"}
                        className="underline text-darkRed"
                        onClick={(e) => {
                          e.stopPropagation();
                          setCurrentClickedAuction(auction);
                          setIsRemoveVehicleConfirmationModalOpen(true);
                        }}
                      >
                        <p className="text-sm text-darkRed">Remove Vehicle</p>
                      </button>
                    ),
                    status === MOTOR_MARKET_ENDED.key &&
                      hasPermission(
                        PermissionCategory.MOTOR_MARKET_SELLING,
                        PermissionAction.SELL
                      ) && (
                        <div key={"motor-market-sell"} className="sell-button">
                          <Button
                            disabled={auction.bid_summary.total_bids === 0}
                            onClick={() => {
                              setCurrentClickedAuction(auction);
                              setIsConfirmSaleModalOpen(true);
                            }}
                          >
                            Sell
                          </Button>
                        </div>
                      ),
                  ]
                : []
            }
            onClick={
              !auction.has_withdrawn ? () => onClick(auction) : undefined
            }
          />
        );
      })}
      <ConfirmationModal
        title={ActionResultMessage[ActionResult.DeleteMotorMarketDraft].title}
        description={
          ActionResultMessage[ActionResult.DeleteMotorMarketDraft].message
        }
        isOpen={isDeleteGroupConfirmationModalOpen}
        onClose={() => setIsDeleteGroupConfirmatonModalOpen(false)}
        onConfirm={() =>
          deleteGroup(currentClickedAuction?.auction_group_id || "")
        }
        isLoading={isDeletingGroup}
        confirmButtonLabel="YES"
        cancelButtonLabel="NO"
      />
      <ConfirmationModal
        title={ActionResultMessage[ActionResult.RemoveMotorMarketVehicle].title}
        description={
          ActionResultMessage[ActionResult.RemoveMotorMarketVehicle].message
        }
        isOpen={isRemoveVehicleConfirmationModalOpen}
        onClose={() => setIsRemoveVehicleConfirmationModalOpen(false)}
        onConfirm={() =>
          removeVehicle(currentClickedAuction?.auction_vehicle_id || "")
        }
        isLoading={isRemovingVehicle}
        confirmButtonLabel="YES"
        cancelButtonLabel="NO"
      />
      <ConfirmationModal
        title={ActionResultMessage[ActionResult.ConfirmMotorMarketSale].title}
        description={
          ActionResultMessage[ActionResult.ConfirmMotorMarketSale].message
        }
        isOpen={isConfirmSaleModalOpen}
        onClose={() => setIsConfirmSaleModalOpen(false)}
        content={
          <div className="flex flex-col space-y-4 my-8">
            <div className="flex flex-col">
              <label className="text-sm">Selling To:</label>
              <span className="font-semibold">
                {currentClickedAuction?.bid_summary?.bidder_name || "-"}
              </span>
            </div>
            <div className="flex flex-col">
              <label className="text-sm">Selling Price:</label>
              <span className="font-medium">
                {currentClickedAuction?.bid_summary?.highest_bid
                  ? `$${formatNumber(
                      currentClickedAuction?.bid_summary?.highest_bid
                    )}`
                  : "-"}
              </span>
            </div>
          </div>
        }
        onConfirm={() => {
          sellVehicle(currentClickedAuction?.bid_summary?.id || "");
        }}
        isLoading={isSellingVehicle}
        confirmButtonLabel="YES"
        cancelButtonLabel="NO"
      />
    </>
  );
};
