import { useMutation, useQuery } from "@tanstack/react-query";
import { useState, useMemo, ChangeEvent, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { CardMotorMarket, LoadVehicle, SearchBar } from "components/app";
import { Button, ButtonLink, Pagination } from "components/shared";

import { AuthContext } from "context/AuthContext";

import {
  DEFAULT_ITEMS_PER_PAGE,
  ERROR_MESSAGE,
  showErrorToast,
  showSuccessToast,
} from "utils";
import { dealership } from "utils/dealership";

import { IMotorMarketVehicleListAcquired } from "types/IMotorMarket";

import { MotorMarketService } from "api/client";

interface AddVehiclesFormProps {
  auctionID: string;
  sellerID: string;
  onAdded: (status: boolean) => void;
}

export const AddVehiclesForm = ({
  auctionID,
  sellerID,
  onAdded,
}: AddVehiclesFormProps) => {
  const { userID, user } = useContext(AuthContext);

  const [searchTxt, setSearchTxt] = useState("");
  const [selectedVehicles, setSelectedVehicles] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();

  const {
    data: vehicles,
    isLoading: isLoadingVehicles,
    isError: isErrorVehicles,
    isSuccess: isSuccessVehicles,
  } = useQuery(
    [
      `fetch_vehicles_${searchTxt}_${userID}`,
      {
        with: "owner,seller,dealership",
        dealership_id: dealership.getIdWithChildren(
          user?.data.dealership!,
          sellerID
        ),
        page: currentPage,
        search: searchTxt,
      },
    ],
    MotorMarketService.getVehicleList
  );

  const { mutate: addVehicles, isLoading: isLoadingAddVehicles } = useMutation(
    () =>
      MotorMarketService.addVehicleToMotorMarket(auctionID, {
        contract_ids: selectedVehicles,
      }),
    {
      onSuccess: () => {
        showSuccessToast("Vehicles Added");
        onAdded(true);
        navigate(`/motor-market/my-listings/${auctionID}/draft`);
      },
      onError: () => {
        showErrorToast("Failed to Save");
      },
    }
  );

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTxt(e.target.value);
    setCurrentPage(1);
  };

  const handleToggleSelect = (vehicle: IMotorMarketVehicleListAcquired) => {
    const { contract_id } = vehicle;
    selectedVehicles.includes(contract_id)
      ? setSelectedVehicles((vehicles) =>
          vehicles.filter((id) => id !== contract_id)
        )
      : setSelectedVehicles((vehicles) => [...vehicles, contract_id]);
  };

  const filteredVehicles = useMemo(() => {
    if (!vehicles?.data) return [];
    if (!searchTxt) return vehicles?.data;

    return vehicles?.data?.filter((vehicle) =>
      vehicle.vehicle.name.toLowerCase().includes(searchTxt.toLowerCase())
    );
  }, [vehicles, searchTxt]);

  const handleSelectAll = () => {
    filteredVehicles.length === selectedVehicles.length
      ? setSelectedVehicles([])
      : setSelectedVehicles([
          ...filteredVehicles.map((vehicle) => vehicle.contract_id),
        ]);
  };

  const onAddVehicles = () => {
    addVehicles();
  };

  const onNextPage = () => {
    setCurrentPage((current) => current + 1);
  };

  const onPrevPage = () => {
    setCurrentPage((current) => current - 1);
  };

  const onIndexedPage = (pageIndex: number) => {
    setCurrentPage(pageIndex);
  };

  const renderList = () => {
    if (isLoadingVehicles) {
      return <LoadVehicle />;
    }

    if (isErrorVehicles) {
      return <div className="text-sm text-center">{ERROR_MESSAGE}</div>;
    }

    if (isSuccessVehicles) {
      return (
        <div className="w-full flex-1 flex flex-col gap-4">
          {filteredVehicles?.length ? (
            filteredVehicles.map((vehicle) => (
              <CardMotorMarket
                key={vehicle.contract_id}
                data={vehicle}
                selected={selectedVehicles.includes(vehicle.contract_id)}
                onClick={() => handleToggleSelect(vehicle)}
              />
            ))
          ) : (
            <div className="mt-7">There are no vehicles in your stocklist.</div>
          )}
        </div>
      );
    }
  };

  return (
    <div className="flex flex-col h-[550px] overflow-hidden min-w-lg !mt-10">
      <div className="w-full flex justify-between gap-11 mb-6 items-center">
        <div>
          {selectedVehicles?.length
            ? `${selectedVehicles?.length} Vehicle Selected`
            : "No Vehicles Selected"}
        </div>
        <div className="flex flex-row-reverse gap-4">
          <div className="w-72 relative">
            <SearchBar onSearch={handleSearch} />
          </div>
          <ButtonLink onClick={handleSelectAll}>
            <span
              className={
                filteredVehicles.length
                  ? "text-secondary"
                  : "text-primary cursor-default"
              }
            >
              {filteredVehicles.length &&
              filteredVehicles.length === selectedVehicles.length
                ? "Deselect All"
                : "Select All"}
            </span>
          </ButtonLink>
        </div>
      </div>
      <Pagination
        title="MotorMarket"
        currentPage={currentPage}
        pageLimit={vehicles?.meta?.per_page || DEFAULT_ITEMS_PER_PAGE}
        total={vehicles?.meta?.total || 0}
        onNextPage={onNextPage}
        onPrevPage={onPrevPage}
        onIndexedPage={onIndexedPage}
      />
      <div className="w-full flex-1 overflow-auto mb-5 min-w-1230 pr-3">
        {renderList()}
      </div>
      <div className="flex justify-center">
        <div className="w-150">
          <Button
            className="btn-confirm font-semibold uppercase"
            onClick={onAddVehicles}
            isLoading={isLoadingAddVehicles}
            disabled={!filteredVehicles.length || !selectedVehicles.length}
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
};
