import { VehicleConditionTypes } from "enums/VehicleInformation";

import { ICondition, IImage } from "types";

import { FieldState } from "../Field";

type VehicleConditionProps = {
  condition: ICondition;
  handleCarousel?: (index: number, images: IImage[]) => void;
};

export const VehicleCondition = ({
  condition,
  handleCarousel,
}: VehicleConditionProps) => {
  const value = condition.question_slug as VehicleConditionTypes;

  if (
    [
      VehicleConditionTypes.SpareKey,
      VehicleConditionTypes.ServiceHistory,
      VehicleConditionTypes.TestDriven,
      VehicleConditionTypes.ServiceDueIn3Months,
    ].includes(value)
  ) {
    return (
      <FieldState
        key={condition.title}
        name={condition.title}
        value={
          condition.answers?.options ? condition.answers.options[0]?.title : ""
        }
      />
    );
  }

  if (
    [
      VehicleConditionTypes.MechanicalAndRoadworthy,
      VehicleConditionTypes.CosmeticCondition,
    ].includes(value)
  ) {
    return (
      <>
        <FieldState name={condition.title} value=" " />
        {condition.answers?.options && (
          <div className="pl-5 flex flex-col gap-3">
            {condition?.answers?.options.map((option, index) => (
              <div key={index}>
                <div className="condition-content__info">
                  <FieldState name={option.title} value=" " />
                  <FieldState
                    name="Repair Cost"
                    value={`$${option.costs || 0}`}
                  />
                </div>
                <div className="condition-content__images">
                  {Object.entries(option.images).length > 0 &&
                    Object.values(option.images).map((image, idx) => (
                      <img
                        src={image?.url}
                        key={image?.id}
                        alt={image.label}
                        onClick={() => handleCarousel?.(idx, option.images)}
                      />
                    ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </>
    );
  }

  return (
    <FieldState
      name={condition?.title}
      value={
        condition?.answers?.comments?.[0]
          ? condition?.answers?.comments?.[0]
          : "-"
      }
    />
  );
};
