import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

import { LoadItem } from "components/app/LoadVehicle";
import { ModalMotorMarketBidsHistory } from "components/Modal/MotorMarketBids";

import { formatNumber } from "utils";

import { IMotorMarketBidDetails } from "types/IMotorMarket";

import { ReactComponent as EyeIcon } from "assets/images/eye-icon.svg";

import { MotorMarketService } from "api/client";

type MotorMarketBidsProps = {
  auctionVehicleID: string;
};

export const MotorMarketBids = ({ auctionVehicleID }: MotorMarketBidsProps) => {
  const [selectedBidder, setSelectedBidder] = useState("");

  const { data: motorMarketBids, isLoading } = useQuery<
    { data: IMotorMarketBidDetails[] },
    Error
  >(
    [`fetch_bids_${auctionVehicleID}`, auctionVehicleID],
    MotorMarketService.getBids
  );

  const onOpenBidsLog = (id: string) => {
    setSelectedBidder(id);
  };

  return (
    <div className="disclosure__pannel--wide">
      <div className="flex flex-col w-full -mt-4">
        {isLoading ? (
          <LoadItem />
        ) : (
          motorMarketBids?.data?.map((bid: IMotorMarketBidDetails) => (
            <div
              key={bid?.id}
              className="flex flex-col w-full py-2 border-b border-softPeach gap-2"
            >
              <div className="flex justify-between">
                <div className="flex items-start text-sm">
                  {bid?.dealership?.name}
                  <div
                    className="cursor-pointer ml-2 mt-1 inline-block"
                    onClick={() => onOpenBidsLog(bid?.id)}
                  >
                    <EyeIcon />
                  </div>
                </div>
                <div className="text-sm">${formatNumber(bid?.bid_amount)}</div>
              </div>
              <div className="flex justify-between -mt-1 text-sm text-lightGrey">
                <div>{bid?.latest_bidder?.name}</div>
                <div>{bid?.created_at}</div>
              </div>
              <div className="flex justify-between -mt-1 text-sm text-lightGrey">
                <div>Contact Number</div>
                <div>{bid?.latest_bidder?.phone}</div>
              </div>
            </div>
          ))
        )}
      </div>
      <ModalMotorMarketBidsHistory
        bidderID={selectedBidder}
        isSeller
        isOpen={!!selectedBidder}
        onClose={() => setSelectedBidder("")}
      />
    </div>
  );
};
