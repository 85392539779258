import { useMutation } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { FieldState } from "components/app";
import { Button, SvgImage } from "components/shared";
import { ContactSupportLink } from "components/Support/ContactSupportLink";

import { CUSTOMER_INSPECTION_DATA, CUSTOMER_SEARCHED_VEHICLE } from "utils";

import { CustomerInspectionService } from "api/client";

import { useLocalStorage } from "hooks";

export const VehicleConfirmation = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [savedVehicle] = useLocalStorage(CUSTOMER_SEARCHED_VEHICLE, null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, saveInspection] = useLocalStorage(CUSTOMER_INSPECTION_DATA, null);

  const { mutate, isLoading } = useMutation(
    () => CustomerInspectionService.startInspection(savedVehicle, params.id!),
    {
      onSuccess: (res: any) => {
        saveInspection(res.data);
        navigate("../enter-kms");
      },
      onError: () => {
        toast.error("Failed to proceed", {
          theme: "colored",
          autoClose: 2000,
        });
      },
    }
  );

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="flex flex-col justify-between text-primary w-full max-h-[calc(100vh-50px)] overflow-auto">
      <div className="flex-1 w-full flex flex-col px-5">
        <h5 className="font-bold text-2xl mt-60 mb-5">Is this your vehicle?</h5>
        <div className="mx-auto mt-18">
          <SvgImage name="VehicleConfirmationIcon" />
        </div>
        <label className="font-semibold text-base text-txt mt-9">
          {savedVehicle?.name}
        </label>
        <div className="vehicle-confirmation__content">
          <FieldState
            name="Vehicle Year"
            value={savedVehicle?.specification.year}
          />
          <FieldState
            name="Vehicle Make"
            value={savedVehicle?.specification.make}
          />
          <FieldState
            name="Vehicle Model"
            value={savedVehicle?.specification.model}
          />
          <FieldState
            name="Body Type"
            value={savedVehicle?.specification.body_type}
          />
          <FieldState
            name="Transmission"
            value={savedVehicle?.specification.transmission}
          />
          <FieldState name="Color" value={savedVehicle?.specification.colour} />
        </div>
      </div>
      <p className="text-label text-sm my-5 px-5">
        Not totally right? No worries. You can edit any information in the next
        steps.
      </p>
      <div className="py-3 px-8 flex flex-col gap-4">
        <Button type="submit" isLoading={isLoading} onClick={mutate}>
          <span className="font-bold uppercase">Yes Proceed</span>
        </Button>

        <div className="customer__tool--secondary">
          <Button
            type="button"
            onClick={handleBack}
            className=" bg-white border border-solid border-whiteWhale"
          >
            <span className="uppercase text-sm font-bold text-darkKnight ">
              No, Let's Go Back
            </span>
          </Button>
        </div>
      </div>
      <ContactSupportLink hasMPLogo />
    </div>
  );
};
