type ResearchVehicleLinkProps = {
  value?: string;
  title: string;
};

export const ResearchVehicleLink = ({
  value,
  title,
}: ResearchVehicleLinkProps) => {
  if (!value) {
    return null;
  }

  return (
    <a
      className="block text-sm text-txt mt-3 ml-4 hover:underline"
      target="_blank"
      href={value}
      rel="noreferrer"
    >
      {title}
    </a>
  );
};
