import { IAuth } from "types";

import { api, url } from "api";

interface ILoginRequest {
  token: string;
  device_name: string;
  app_version?: string;
}

export const AuthService = {
  validateAuth: (deviceName: string, appVersion?: string) =>
    api.get(url.validate, {
      device_name: deviceName,
      app_version: appVersion,
    }) as Promise<IAuth>,

  forgotPassword: (data: any) =>
    api.get(`${url.forgotPassword}`, data) as Promise<IAuth>,

  resetPassword: (data: any) => api.post(url.resetPassword, data),

  login: (params: ILoginRequest) =>
    api.post(url.login, params) as Promise<IAuth>,

  logout: () => api.post(`${url.logout}`, {}),
};
