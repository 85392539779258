import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { toast } from "react-toastify";

import { FieldDropdown, TextError } from "components/shared";

import { IOption } from "types";

import { VehicleSearchService } from "api/client";

type ManualSearchFormFieldsProps = {
  isRequired?: boolean;
};

export const ManualSearchFormFields = ({
  isRequired,
}: ManualSearchFormFieldsProps) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const year = watch("year");
  const make = watch("make");

  const [yearOptions, setYearOptions] = useState<Array<IOption>>([]);
  const [makeOptions, setMakeOptions] = useState<Array<IOption>>([]);
  const [modelOptions, setModelOptions] = useState<Array<IOption>>([]);

  const showErrorToast = (toastFor: string) => {
    toast.error(`Failed to Find ${toastFor}`, {
      autoClose: 2000,
      theme: "colored",
    });
  };

  useEffect(() => {
    VehicleSearchService.searchYears()
      .then((res: any) => {
        const options = (res.data as Array<any>).map((item) => ({
          key: item.key,
          label: item.value,
        }));
        setYearOptions(options);
      })
      .catch(() => {
        showErrorToast("year");
      });
  }, []);

  useEffect(() => {
    if (year) {
      VehicleSearchService.searchMakes({ year })
        .then((res: any) => {
          const options = (res.data as Array<any>).map((item) => ({
            key: item.key,
            label: item.value,
          }));
          setMakeOptions(options);
        })
        .catch(() => {
          showErrorToast("make");
        });
    }
  }, [year]);

  useEffect(() => {
    if (year && make) {
      VehicleSearchService.searchModels({ year, make })
        .then((res: any) => {
          const options = (res.data as Array<any>).map((item) => ({
            key: item.key,
            label: item.value,
          }));
          setModelOptions(options);
        })
        .catch(() => {
          showErrorToast("model");
        });
    }
  }, [make, year]);

  return (
    <>
      <div>
        <Controller
          name="year"
          control={control}
          rules={{ required: "Year is required" }}
          render={({ field }) => (
            <FieldDropdown
              {...field}
              title={`Year${isRequired ? "*" : ""}`}
              placeholder="Select year"
              options={yearOptions}
            />
          )}
        />
        {errors.year && <TextError error={errors.year.message} />}
      </div>

      <div>
        <Controller
          name="make"
          control={control}
          rules={{ required: "Make is required" }}
          render={({ field }) => (
            <FieldDropdown
              {...field}
              title={`Make${isRequired ? "*" : ""}`}
              placeholder="Select make"
              options={makeOptions}
            />
          )}
        />
        {errors.make && <TextError error={errors.make.message} />}
      </div>

      <div>
        <Controller
          name="model"
          control={control}
          rules={{ required: "Model is required" }}
          render={({ field }) => (
            <FieldDropdown
              {...field}
              title={`Model${isRequired ? "*" : ""}`}
              placeholder="Select model"
              options={modelOptions}
            />
          )}
        />
        {errors.model && <TextError error={errors.model.message} />}
      </div>
    </>
  );
};
