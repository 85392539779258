import QRCode from "react-qr-code";

import { ModalContainer } from "components/ModalContainer";
import { Button } from "components/shared";

type CameraRequiredModalProps = {
  link: string;
  isOpen: boolean;
  onClose: () => void;
};

export const CameraRequiredModal = ({
  link,
  isOpen,
  onClose,
}: CameraRequiredModalProps) => {
  return (
    <ModalContainer
      size="responsive"
      isOpen={isOpen}
      canClose={false}
      onClose={onClose}
    >
      <div className="w-full md:w-[400px] lg:w-[400px] flex flex-col md:flex-row my-5">
        <div className="w-full flex flex-col items-center gap-5 text-center px-10">
          <h4 className="text-xl font-bold">
            Please switch to a mobile or tablet with a camera
          </h4>
          <div className="py-5">
            <QRCode value={link} size={180} />
          </div>
          <p className="text-sm">
            This process requires taking photos of your vehicle to proceed.
          </p>
          <p className="text-sm">
            Please open this link on a smartphone or tablet with a camera to
            complete the required steps.
          </p>
          <div className="w-150">
            <Button type="submit" className="font-bold" onClick={onClose}>
              OK
            </Button>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};
