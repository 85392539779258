import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import { Button } from "components/shared";

import { showErrorToast, StorageKeys } from "utils";
import { PPSR_STATUS_NAMES } from "utils/ppsrStatuses";

import { IPPSR, IPPSRStatus } from "types";

import { VehicleService } from "api/client";

import { DataContainer } from "./DataContainer";
import { PPSRHistory } from "./PPSRHistory";

interface PPSRProps {
  contractID: string;
  status?: null | IPPSRStatus;
  hasVinNumber?: boolean;
  refetch?: () => void;
}

export const PPSR = ({
  contractID,
  status,
  hasVinNumber,
  refetch,
}: PPSRProps) => {
  const [isCertificateAvailable, setIsCertificateAvailable] = useState(false);
  const [isPollingTriggered, setIsPollingTriggered] = useState(false);
  const [isRefreshClicked, setIsRefreshClicked] = useState(false);
  const [data, setData] = useState<IPPSR>();
  const queryClient = useQueryClient();

  const { mutate: onSearchPPSR, isLoading: isLoadingSearchPPSR } = useMutation(
    ({ refetch, forceSearch }: { refetch: boolean; forceSearch?: boolean }) =>
      VehicleService.searchPPSR(contractID, refetch, forceSearch),
    {
      onSuccess: (data) => {
        setData(data.data);

        if (data?.data?.has_certificate) {
          setIsCertificateAvailable(true);
          setIsPollingTriggered(false);

          refetch?.();
        } else {
          setIsCertificateAvailable(false);
          setIsPollingTriggered(true);
        }

        queryClient.invalidateQueries([
          `${StorageKeys.PPSRHistory}-${contractID}`,
        ]);
        queryClient.invalidateQueries([
          `${StorageKeys.GLOBAL_SEARCH_SUMMARY}-${contractID}`,
        ]);
      },
      onError: () => {
        showErrorToast("Something went wrong. Try Again");
      },
    }
  );

  useEffect(() => {
    if (
      PPSR_STATUS_NAMES.includes(status as typeof PPSR_STATUS_NAMES[number])
    ) {
      onSearchPPSR({ refetch: false });
    }
  }, [status, onSearchPPSR]);

  useEffect(() => {
    const pollSearch = window.setInterval(() => {
      if (isPollingTriggered && !isCertificateAvailable) {
        onSearchPPSR({ refetch: false });
      }
    }, 5000);

    return () => {
      clearInterval(pollSearch);
    };
  }, [isPollingTriggered, isCertificateAvailable, onSearchPPSR]);

  const onRequestPPSR = () => {
    onSearchPPSR({ refetch: true, forceSearch: true });
    setIsPollingTriggered(false);
    setIsRefreshClicked(false);
  };

  const onRefreshPPSR = () => {
    onSearchPPSR({ refetch: true });
    setIsRefreshClicked(true);
  };

  return (
    <div className="flex flex-col gap-10">
      {!!data?.search_id || data?.status === "changed" ? (
        <DataContainer data={data} contractID={contractID} />
      ) : (
        <div className="text-sm text-label">
          {isLoadingSearchPPSR ? (
            "Please do not leave the page to avoid stopping the process."
          ) : (
            <>
              Conduct a PPSR search for this vehicle.
              <div className="mt-3">
                PPSR results will take approximately 5-10 seconds to retrieve.
                PDF will take 10-15 seconds to download.
              </div>
            </>
          )}
        </div>
      )}
      <div className="flex gap-5">
        {data?.id && (
          <Button
            type="button"
            variant="secondary"
            className="!w-100 !border-softPeach"
            isLoading={
              isRefreshClicked && isLoadingSearchPPSR && !isPollingTriggered
            }
            onClick={onRefreshPPSR}
          >
            <span className="font-bold uppercase">Refresh</span>
          </Button>
        )}
        <Button
          className="!w-36 font-bold uppercase"
          isLoading={
            (!status || !!data) &&
            !isRefreshClicked &&
            isLoadingSearchPPSR &&
            !isPollingTriggered
          }
          onClick={onRequestPPSR}
          isLoadingText="Checking"
          disabled={!hasVinNumber}
        >
          Request PPSR
        </Button>
      </div>
      {PPSR_STATUS_NAMES.includes(
        status as typeof PPSR_STATUS_NAMES[number]
      ) && (
        <PPSRHistory contractID={contractID} isPolling={isPollingTriggered} />
      )}
    </div>
  );
};
