import { useMutation } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ModalConfirm } from "components/app";
import { NoData } from "components/pages/List/NoData";
import { Modal } from "components/shared";

import { AuthContext } from "context/AuthContext";
import { useDealership } from "context/DealershipContext";

import {
  ActionResult,
  ActionResultMessage,
  DEFAULT_ITEMS_PER_PAGE,
  MOTOR_MARKET,
  MOTOR_MARKET_DRAFT,
  MOTOR_MARKET_MY_LISTINGS,
  MOTOR_MARKET_SELLING,
  STATUS_LIST,
  StorageKeys,
  showErrorToast,
  showSuccessToast,
} from "utils";
import { PermissionAction, PermissionCategory } from "utils/userPermissions";

import { IMotorMarket } from "types/IMotorMarket";

import { MotorMarketService } from "api/client";

import { useSessionStorage } from "hooks";
import { useListQuery } from "hooks/useListQuery";

import { List } from "../../../components/pages/List/List";
import { ListVehicles } from "./ListVehicles";

interface StateProps {
  filterStatus?: string;
}

export const MyListings = () => {
  const [deleteGroupID, setDeleteGroupID] = useState("");

  const { state } = useLocation();
  const filterStatus = (state as StateProps)?.filterStatus;

  const { hasPermission } = useContext(AuthContext);
  const { dealershipID } = useDealership();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useSessionStorage(
    StorageKeys.MotorMarketMyListings,
    1
  );

  const [selectedFilterStatus, setSelectedFilterStatus] = useSessionStorage(
    StorageKeys.MotorMarketMyListingsFilterStatus,
    filterStatus || MOTOR_MARKET_DRAFT.key
  );

  const [{ label: status }] = STATUS_LIST.MotorMarketMyListings.filter(
    (item) => {
      return item.key === selectedFilterStatus;
    }
  );

  useEffect(() => {
    if (filterStatus) {
      setSelectedFilterStatus(filterStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStatus]);

  const { data, isInitialLoading, isError, refetch } = useListQuery(
    {
      queryKey: StorageKeys.MotorMarketMyListings,
      withParams: "bids",
      status: selectedFilterStatus,
      currentPage: currentPage,
      dealershipID,
    },
    MotorMarketService.getMyListings
  );

  const { mutate: deleteGroup, isLoading: isLoadingDeleteGroup } = useMutation(
    (id: string) => MotorMarketService.deleteGroup(id),
    {
      onSuccess: () => {
        refetch();
        setDeleteGroupID("");
        showSuccessToast("Deleted Successfully");
      },
      onError: () => {
        showErrorToast("Failed to Remove");
      },
    }
  );

  const filteredData = data?.data;

  const onFilterStatusChange = (status: string | number) => {
    setSelectedFilterStatus(status);
    setCurrentPage(1);
  };

  const onClick = (selectedVehicle: IMotorMarket) => {
    if (selectedFilterStatus === MOTOR_MARKET_DRAFT.key) {
      navigate(`${selectedVehicle.auction_group_id}/draft`, {
        state: { selectedVehicle },
      });
    } else {
      navigate(
        `/motor-market/my-listings/${selectedVehicle.contract_id}/summary`,
        { state: { selectedVehicle } }
      );
    }
  };

  return (
    <>
      <List
        pageTitle={MOTOR_MARKET_MY_LISTINGS}
        breadcrumbs={[
          { title: MOTOR_MARKET, route: "/motor-market/my-listings" },
          { title: MOTOR_MARKET_SELLING, route: "/motor-market/my-listings" },
        ]}
        pagination={{
          title: MOTOR_MARKET_MY_LISTINGS,
          total: data?.meta?.total ?? 0,
          currentPage: currentPage,
          pageLimit: data?.meta?.per_page ?? DEFAULT_ITEMS_PER_PAGE,
          onNextPage: () => setCurrentPage(currentPage + 1),
          onPrevPage: () => setCurrentPage(currentPage - 1),
          onIndexedPage: (index) => setCurrentPage(index),
        }}
        selectedFilterStatus={selectedFilterStatus}
        filterStatusOptions={STATUS_LIST.MotorMarketMyListings}
        isLoading={isInitialLoading}
        isError={isError}
        onFilterStatusChange={onFilterStatusChange}
        hasViewingPermission={hasPermission(
          PermissionCategory.MOTOR_MARKET_SELLING,
          PermissionAction.VIEW
        )}
        viewPermissionCategory={PermissionCategory.MOTOR_MARKET_SELLING}
      >
        {filteredData?.length ? (
          <ListVehicles
            data={filteredData}
            status={selectedFilterStatus}
            onClick={onClick}
            refetch={refetch}
          />
        ) : (
          <NoData message={`No ${status} Listings.`} />
        )}
      </List>
      <Modal
        open={!!deleteGroupID}
        closeModal={() => setDeleteGroupID("")}
        size="auto"
      >
        <ModalConfirm
          title={ActionResultMessage[ActionResult.DeleteMotorMarketDraft].title}
          description={
            ActionResultMessage[ActionResult.DeleteMotorMarketDraft].message
          }
          onCancel={() => setDeleteGroupID("")}
          onConfirm={() => deleteGroup(deleteGroupID)}
          cancelButtonLabel="NO"
          confirmButtonLabel="YES"
          isLoading={isLoadingDeleteGroup}
        />
      </Modal>
    </>
  );
};
