import { VEHICLE_SEARCH_OPTIONS } from "enums/VehicleSearch";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { FieldText, TextError } from "components/shared";
import { VehicleSearch } from "components/VehicleSearch";

import { CUSTOMER_SEARCHED_VEHICLE } from "utils";

import { VehicleSearchService } from "api/client";

import { useLocalStorage } from "hooks";

interface IVin {
  plate: string;
}

export const FindVehicleByVin = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [isSearching, setIsSearching] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, saveVehicle] = useLocalStorage(CUSTOMER_SEARCHED_VEHICLE, null);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      plate: "",
    },
  });

  const handleFindVehicle = async (data: IVin) => {
    try {
      setIsSearching(true);
      const searchResult = await VehicleSearchService.searchPlate(
        { ...data, force_search: 1 },
        params.id || ""
      );

      if (searchResult) {
        setIsSearching(false);
        toast.success("Vehicle found!", {
          autoClose: 2000,
          theme: "colored",
        });
        saveVehicle(searchResult.data);
        navigate("../vehicle-confirmation");
      }
    } catch (err) {
      setIsSearching(false);
      toast.error("Failed to Find Vehicle", {
        theme: "colored",
        autoClose: 2000,
      });
    }
  };

  const handleVin = (value: string) => {
    setValue("plate", value.replace(/\s/g, "").toUpperCase());
  };

  return (
    <VehicleSearch
      vehicleSearchOption={VEHICLE_SEARCH_OPTIONS.VIN}
      handleSearch={handleSubmit(handleFindVehicle)}
      isSearching={isSearching}
    >
      <div className="mb-10">
        <FieldText
          title="VIN"
          type="text"
          {...register("plate", { required: true })}
          placeholder="XXXXXXXXXXXXXXXXX"
          onChange={(e) => handleVin(e.target.value)}
        />
        {errors.plate && <TextError error="Please check your entry." />}
      </div>
    </VehicleSearch>
  );
};
