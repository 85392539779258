import { DeviceType } from "enums/DeviceType";

export const deviceDetector = () => {
  // @ts-ignore
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (
    /android|avantgo|blackberry|bada|mobile|kindle|silk|midp|phone|pocket|palm|windows phone|ipad|ipod|iphone/i.test(
      userAgent
    )
  ) {
    return DeviceType.Mobile;
  }

  if (/tablet|ipad|playbook|silk/i.test(userAgent)) {
    return DeviceType.Tablet;
  }

  return DeviceType.Desktop;
};
