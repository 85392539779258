import {
  IWholesaleDealership,
  IQueryKeyProps,
  IVehicleResponse,
  IVehicleSummaryResponse,
  IValuationOfferResponse,
} from "types";
import { ValuationHistoryResponse } from "types/Valuation";

import { api, url } from "api";

export const ValuationService = {
  getValuationHistory: (
    { queryKey }: IQueryKeyProps // params: queryKey[1]: valuation id
  ) => {
    if (queryKey[1]) {
      return api.get(`${url.valuations}/${queryKey[1]}/histories`);
    }
    return null;
  },

  getValuationRequests: ({ queryKey }: IQueryKeyProps) => {
    const screenName: string = queryKey[2] as string;
    let secondParam = "valuations";
    if (screenName) {
      secondParam = screenName.toLocaleLowerCase() + "s";
    }
    return api.get(
      `${url.valuationRequests}/${secondParam}`,
      queryKey[1]
    ) as Promise<IVehicleResponse>;
  },

  getValuationAcquired: ({ queryKey }: IQueryKeyProps) => {
    return api.get(
      `${url.valuationRequests}/acquisitions`,
      queryKey[1]
    ) as Promise<IVehicleResponse>;
  },

  getValuationNegotiation: ({ queryKey }: IQueryKeyProps) => {
    return api.get(
      `${url.valuationRequests}/negotiations`,
      queryKey[1]
    ) as Promise<IVehicleResponse>;
  },

  getValuationRequestSummary: ({ queryKey }: IQueryKeyProps) => {
    const screenName: string = queryKey[4] as string;
    let secondParam = "valuations";
    if (screenName) {
      secondParam = screenName.toLowerCase() + "s";
    }
    return api.get(
      `${url.valuationRequests}/${secondParam}/${queryKey[1]}${
        queryKey[3] && queryKey[3] !== "undefined" ? `/${queryKey[3]}` : ""
      }`,
      { with: "assessments,requester,buyer,comment,owner,valuations,images" }
    ) as Promise<IVehicleSummaryResponse>;
  },

  getWholesaleValuation: (
    { queryKey }: IQueryKeyProps // params: queryKey[1]: contract_id
  ) => {
    return api.get(`${url.valuations}/${queryKey[1]}/external`) as Promise<{
      data: IWholesaleDealership[];
    }>;
  },

  requestMPValuation: (
    contract_id: string,
    payload: { type: string; dealership_ids?: string[] }
  ) => api.post(`${url.valuationRequests}/${contract_id}`, payload),

  addValuationAmount: (
    contractID: string,
    payload: {
      amount: string;
      type: string;
      valuation_request_id?: string;
    }
  ) =>
    api.post(
      `${url.valuations}/${contractID}/amount`,
      payload
    ) as Promise<IValuationOfferResponse>,

  requestMotorIntelValuationCertificate: (contractID: string) =>
    api.get(
      `${url.valuationCertificate}/${contractID}/motorplatform`
    ) as Promise<string>,

  requestValuationCertificate: (contractID: string) =>
    api.get(
      `${url.valuationCertificate}/${contractID}/dealership`
    ) as Promise<string>,

  declineValuationRequest: (contractID: string, requestID: string) =>
    api.post(`${url.valuationRequests}/${contractID}/${requestID}/decline`, {}),

  getHistory: ({ queryKey }: IQueryKeyProps) =>
    api.get(`${url.valuations}/${queryKey[1]}/histories`, {
      page: queryKey[2],
    }) as Promise<ValuationHistoryResponse>,
};
