import { useInfiniteQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import { OdometerReadingLog } from "components/app/Summary/OdometerReadingLog";
import { Modal } from "components/shared";

import { IOdometerHistoryResponse } from "types";

import { VehicleService } from "api/client";

export interface ModalOdometerReadingLogProps {
  contractID: string;
  open: boolean;
  onClose: () => void;
}

export const ModalOdometerReadingLog = ({
  contractID,
  open,
  onClose,
}: ModalOdometerReadingLogProps) => {
  const {
    data,
    hasNextPage,
    fetchNextPage,
    isError,
    isInitialLoading,
    isFetching,
  } = useInfiniteQuery<IOdometerHistoryResponse, Error>(
    ["fetchOdometerHistory", contractID],
    {
      queryFn: ({ pageParam = 1 }) =>
        VehicleService.getOdometerHistory(contractID, pageParam),
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.meta.current_page + 1;
        return lastPage.meta.last_page >= nextPage ? nextPage : undefined;
      },
    }
  );

  const odometerItems = useMemo(
    () => data?.pages?.map((page) => page.data).flat() || [],
    [data?.pages]
  );

  const handleScrollHitBottom = () => {
    fetchNextPage();
  };

  return (
    <Modal
      title="Odomerter Reading Log"
      open={open}
      size="auto"
      closeModal={onClose}
    >
      <OdometerReadingLog
        hasNextPage={hasNextPage}
        isLoading={isInitialLoading || isFetching}
        isError={isError}
        data={odometerItems}
        onScrollHitBottom={handleScrollHitBottom}
      />
    </Modal>
  );
};
