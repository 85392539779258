import { VEHICLE_SEARCH_OPTIONS } from "enums/VehicleSearch";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { ModalVinLocation } from "components/app";
import { Button, ButtonLink, Modal, SvgImage } from "components/shared";
import { ContactSupportLink } from "components/Support/ContactSupportLink";

type VehicleSearchProps = {
  vehicleSearchOption: VEHICLE_SEARCH_OPTIONS;
  children: React.ReactNode;
  handleSearch: () => void;
  isSearching?: boolean;
};

export const VehicleSearch = ({
  vehicleSearchOption,
  children,
  handleSearch,
  isSearching,
}: VehicleSearchProps) => {
  const navigate = useNavigate();
  const [showVinLocationModal, setVinLocationModal] = useState<boolean>(false);

  const searchOptions = [
    {
      key: VEHICLE_SEARCH_OPTIONS.REGO,
      label: "Enter REGO",
      handler: () => navigate(`../find-vehicle`),
      visibleFor: [
        VEHICLE_SEARCH_OPTIONS.VIN,
        VEHICLE_SEARCH_OPTIONS.MANUAL_SEARCH,
      ],
    },
    {
      key: VEHICLE_SEARCH_OPTIONS.VIN,
      label: "Enter VIN number",
      handler: () => navigate(`../find-vehicle-vin`),
      visibleFor: [
        VEHICLE_SEARCH_OPTIONS.REGO,
        VEHICLE_SEARCH_OPTIONS.MANUAL_SEARCH,
      ],
    },
    {
      key: VEHICLE_SEARCH_OPTIONS.MANUAL_SEARCH,
      label: "Manual Search",
      handler: () => navigate(`../manual-search`),
      visibleFor: [VEHICLE_SEARCH_OPTIONS.REGO, VEHICLE_SEARCH_OPTIONS.VIN],
    },
  ];

  const handleVinLocationModal = () => {
    setVinLocationModal(true);
  };

  return (
    <div className="flex flex-col justify-between text-primary w-full mb-5 h-[calc(100%-60px)]">
      <div className="flex-1 w-full flex flex-col overflow-auto px-5">
        <div className="font-medium text-sm mt-[50px]">
          <h5 className="font-bold text-2xl mb-5">Find your vehicle</h5>
          <p className="text-label font-normal mb-4">
            Please enter the following details:
          </p>
          {children}
        </div>
        {vehicleSearchOption === VEHICLE_SEARCH_OPTIONS.VIN && (
          <div className="mb-8">
            <ButtonLink onClick={handleVinLocationModal}>
              <p className="text-label font-normal underline">
                Where can I find my VIN?
              </p>
            </ButtonLink>
          </div>
        )}
        <p className="text-label text-sm font-normal mt-10 mb-5">
          Or select one of the options below:
        </p>

        {searchOptions
          .filter((option) => option.visibleFor.includes(vehicleSearchOption))
          .map((option) => (
            <div
              key={option.key}
              className="text-darkKnight font-semibold text-sm block mb-5"
            >
              <ButtonLink onClick={option.handler}>
                <p className="underline">{option.label}</p>
              </ButtonLink>
            </div>
          ))}

        <div className="mt-auto">
          <Button type="submit" isLoading={isSearching} onClick={handleSearch}>
            <span className="uppercase text-white text-sm font-bold">
              Search
            </span>
          </Button>
        </div>

        <ContactSupportLink hasMPLogo />
      </div>
      <Modal
        size="auto"
        open={showVinLocationModal}
        closeModal={() => setVinLocationModal(false)}
      >
        <ModalVinLocation />
      </Modal>
    </div>
  );
};
