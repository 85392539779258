import { useState, useEffect } from "react";

type GeolocationState = {
  latitude: number | null;
  longitude: number | null;
  error: string | null;
  status: "idle" | "pending" | "success" | "error" | "disabled";
  permission: PermissionState | null;
};

type useGeolocationProps = {
  enableChecking: boolean;
};

export const useGeolocation = ({ enableChecking }: useGeolocationProps) => {
  const [state, setState] = useState<GeolocationState>({
    latitude: null,
    longitude: null,
    error: null,
    status: "idle",
    permission: null,
  });

  const detectGeolocation = () => {
    if (!enableChecking) {
      return;
    }

    if (!navigator.geolocation) {
      setState({
        ...state,
        error: "Geolocation is not supported by this browser.",
        status: "disabled",
      });
      return;
    }

    setState((prevState) => ({ ...prevState, status: "pending" }));

    navigator.geolocation.getCurrentPosition(
      (position) => {
        setState({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          error: null,
          status: "success",
          permission: "granted",
        });
      },
      (error) => {
        setState({
          latitude: null,
          longitude: null,
          error: error.message,
          status: "error",
          permission: "denied",
        });
      }
    );
  };

  const monitorPermission = async () => {
    if (!navigator.permissions) {
      setState((prevState) => ({
        ...prevState,
        permission: null,
      }));
      return;
    }

    try {
      const permissionStatus = await navigator.permissions.query({
        name: "geolocation",
      });
      setState((prevState) => ({
        ...prevState,
        permission: permissionStatus.state,
      }));

      permissionStatus.onchange = () => {
        setState((prevState) => ({
          ...prevState,
          permission: permissionStatus.state,
        }));
      };
    } catch (error) {
      console.error("Failed to query geolocation permission:", error);
    }
  };

  useEffect(() => {
    if (enableChecking) {
      monitorPermission();
      detectGeolocation();
    }
  }, [enableChecking]);

  return { state, requestGeolocation: detectGeolocation };
};
