import { useRef, useState } from "react";
import { useFormContext } from "react-hook-form";

import { Button, FieldText } from "components/shared";
import { Modal } from "components/shared";

import { formatNumber } from "utils";
import "../styles.scss";

export interface ModalTradeAddProps {
  open: boolean;
  onClose: () => void;
}

export const ModalTradeAdd = ({ open, onClose }: ModalTradeAddProps) => {
  const { setValue } = useFormContext();
  const [amount, setAmount] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  const handleConfirm = () => {
    setValue("valuation_trade", amount);
    onClose();
  };

  const handleAmount = (value: string) => {
    setAmount(value.replaceAll(",", ""));
  };

  return (
    <Modal
      open={open}
      size="auto"
      title="Add Trade Amount"
      closeModal={onClose}
    >
      <div className="valuation-add !w-310">
        <div className="valuation-add__txt">
          <FieldText
            type="text"
            value={formatNumber(amount)}
            onChange={(e) => handleAmount(e.target.value)}
            placeholder="Enter amount"
            ref={inputRef}
          />
          <span className="valuation-add__txt__unit">$</span>
        </div>
        <div className="valuation-add__tool">
          <Button type="button" isLoading={false} onClick={handleConfirm}>
            <span className="valuation-add__tool__title">CONFIRM AMOUNT</span>
          </Button>
        </div>
      </div>
    </Modal>
  );
};
