import { useContext, useState } from "react";

import { SvgImage } from "components/shared";

import { AuthContext } from "context/AuthContext";

import { PermissionAction, PermissionCategory } from "utils/userPermissions";

import { FieldValuation } from "../Field/FieldValuation";
import { ResearchVehicleLink } from "./ResearchVehicleLink";

type Reference = {
  car_sales?: string;
  red_book?: string;
  live_market?: string;
};

type ResearchVehicleProps = {
  reference?: Reference;
};

export const ResearchVehicle = ({ reference }: ResearchVehicleProps) => {
  const [showResearchVehicle, setShowResearchVehicle] = useState(false);
  const { hasPermission } = useContext(AuthContext);

  const { car_sales, red_book, live_market } = reference || {};

  if (
    !hasPermission(PermissionCategory.PRICE_GUIDE, PermissionAction.VIEW_ANY)
  ) {
    return null;
  }

  return (
    <div>
      <div onClick={() => setShowResearchVehicle((prev) => !prev)}>
        <FieldValuation
          title="Research Vehicle"
          tool={
            <SvgImage
              name="SelectDown"
              className={`${showResearchVehicle ? "transform rotate-180" : ""}`}
            />
          }
        />
      </div>
      {showResearchVehicle && (
        <div className="!mt-2">
          <ResearchVehicleLink title="on CarSales" value={car_sales} />
          <ResearchVehicleLink title="on RedBook" value={red_book} />
          <ResearchVehicleLink title="on LiveMarket" value={live_market} />
        </div>
      )}
    </div>
  );
};
