import { useMutation, useQuery } from "@tanstack/react-query";
import { ContractType } from "enums/ContractType";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";

import { BidsHistory } from "pages/MotorMarket/Summary/BidsHistory";

import {
  DealershipInformation,
  DealInformation,
  Footer,
  LoadVehicleSummary,
  NotifyUpdate,
  SaleDetailSection,
  SellerInformation,
  SummaryHeader,
  ValuationsSection,
  VehicleInformation,
} from "components/app";
import { BidSection } from "components/app/Tender/BidSection";
import { ErrorMessage } from "components/ErrorMessage";
import { RemoveTenderVehicleModal } from "components/Modal/RemoveVehicleModal/RemoveVehicleModal";
import { RemoveFromMotorMarket } from "components/MotorMarket/RemoveFromMotorMarket";
import { NoPermissionMessage } from "components/NoPermissionMessage";
import { Banner, Breadcrumbs, Button } from "components/shared";
import { Timer } from "components/Timer";
import { VehicleComments } from "components/VehicleNotes";
import { VehiclePhotoContainer } from "components/VehiclePhotoContainer";

import { AuthContext } from "context/AuthContext";

import {
  ACQUIRED,
  FLOW_TYPE,
  formatDateToJsDate,
  MOTOR_MARKET_AUCTION,
  MOTOR_MARKET_SOLD_AUCTION,
  RETAIL_DEALERSHIP_MENU,
  showSuccessToast,
  TENDERED,
  VEHICLE_ROUTES,
  VEHICLE_SUMMARY_TITLE,
  WHOLESALE_DEALERSHIP_MENU,
} from "utils";
import { getPageTitle, SECTIONS } from "utils/pageSections";
import { PermissionAction, PermissionCategory } from "utils/userPermissions";

import {
  ITenderSummaryResponse,
  IVehicleSummaryResponse,
  NavigationMenuLabel,
} from "types";

import { AcquisitionService, TenderService } from "api/client";

import "./styles.scss";

export const AcquisitionSummary = ({ flow }: { flow: FLOW_TYPE }) => {
  const params = useParams();
  const { userID, hasPermission } = useContext(AuthContext);
  const navigate = useNavigate();
  const [openRemoveModal, setOpenRemoveModal] = useState(false);

  const isRetailDealershipFlow = flow === FLOW_TYPE.RETAIL_DEALERSHIP;

  const canRevertVehicle = hasPermission(
    isRetailDealershipFlow
      ? PermissionCategory.RETAIL_INSPECTIONS
      : PermissionCategory.WHOLESALE_INSPECTIONS,
    PermissionAction.REVERT
  );

  const canView = hasPermission(
    isRetailDealershipFlow
      ? PermissionCategory.RETAIL_INSPECTIONS
      : PermissionCategory.WHOLESALE_INSPECTIONS,
    [PermissionAction.VIEW, PermissionAction.VIEW_ANY]
  );

  const { data, isLoading, isError, refetch } =
    useQuery<IVehicleSummaryResponse>(
      [
        `retail-summary-${params.id}`,
        params.id,
        {
          with: "dealership,owner,assessments,valuations,expenses,comment,buyer,seller",
        },
      ],
      AcquisitionService.getAcquisitionSummary,
      {
        refetchOnWindowFocus: false,
        enabled: canView,
      }
    );

  const { data: tenderData } = useQuery<ITenderSummaryResponse | null>(
    [`tender-seller-summary-${userID}`, data?.data.tender_vehicle?.tender_id],
    TenderService.getTenderSummary,
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    const savedScrollPosition = sessionStorage.getItem("SCROLL_POSITION");
    if (savedScrollPosition) {
      document
        .getElementsByClassName("dashboard")[0]
        .scrollTo({ behavior: "smooth", top: parseInt(savedScrollPosition) });
      sessionStorage.removeItem("SCROLL_POSITION");
    }
  }, []);

  const { mutate } = useMutation(
    (dataparam: { contract_ids: string[] }) =>
      TenderService.deleteVehicleFromTender(
        dataparam,
        data?.data?.tender_vehicle?.tender_id ?? ""
      ),
    {
      onSuccess: () => {
        showSuccessToast("Vehicle Removed");
        navigate(-1);
      },
      onError: () => {
        showSuccessToast("Failed to Remove");
      },
    }
  );

  const isStatusSold = data?.data.status?.name.includes("sold");

  const handleOpenRemoveModal = () => setOpenRemoveModal(true);

  const handleCloseRemoveModal = () => setOpenRemoveModal(false);

  const handleRemoveFromTender = () => {
    mutate({ contract_ids: [data?.data.contract_id ?? ""] });
  };

  return (
    <>
      <Helmet>
        <title>{getPageTitle("Stocklist", SECTIONS.RETAIL_DEALERSHIP)}</title>
      </Helmet>
      <div className="dashboard__container">
        <div className="dashboard__header">
          <Breadcrumbs
            paths={[
              {
                title: isRetailDealershipFlow
                  ? NavigationMenuLabel.RETAIL_DEALERSHIP
                  : NavigationMenuLabel.WHOLESALE_DEALERSHIP,
                route: isRetailDealershipFlow
                  ? RETAIL_DEALERSHIP_MENU[0].route
                  : WHOLESALE_DEALERSHIP_MENU[0].route,
              },
              {
                title: "Stocklist",
                route: isRetailDealershipFlow
                  ? RETAIL_DEALERSHIP_MENU[2].route
                  : WHOLESALE_DEALERSHIP_MENU[2].route,
              },
              {
                title: VEHICLE_SUMMARY_TITLE,
                route: `${
                  isRetailDealershipFlow
                    ? RETAIL_DEALERSHIP_MENU[0].route
                    : WHOLESALE_DEALERSHIP_MENU[0].route
                }/stocklist/${params.id}/summary`,
              },
            ]}
            showBackButton
          />
        </div>
        <div className="dashboard__content">
          {!canView ? (
            <NoPermissionMessage
              viewPermissionCategory={PermissionCategory.RETAIL_INSPECTIONS}
            />
          ) : (
            <>
              {isLoading && <LoadVehicleSummary />}{" "}
              {isError && <ErrorMessage />}
              {data && !isError && (
                <>
                  <div className="dashboard__content--left">
                    <SummaryHeader
                      data={data.data}
                      onRefresh={refetch}
                      flow={flow}
                    />
                    <DealInformation
                      data={data.data}
                      defaultOpen
                      onRefresh={refetch}
                      auctionID={data.data?.auction_vehicle_id}
                      showInMotorMarketInfo
                      flow={
                        data?.data?.owner?.id
                          ? FLOW_TYPE.RETAIL_DEALERSHIP
                          : flow
                      }
                    />
                    <VehicleInformation
                      data={data.data}
                      vehicleRoute={VEHICLE_ROUTES.Stocklist}
                      flow={flow || FLOW_TYPE.RETAIL_DEALERSHIP}
                      refetch={refetch}
                      isLocked={
                        !hasPermission(
                          isRetailDealershipFlow
                            ? PermissionCategory.RETAIL_INSPECTIONS
                            : PermissionCategory.WHOLESALE_INSPECTIONS,
                          PermissionAction.UPDATE
                        )
                      }
                    />
                    {data?.data?.is_contract_owner &&
                      data?.data?.type === ContractType.Valuation && (
                        <DealershipInformation data={data.data} />
                      )}
                    <SellerInformation data={data.data} flow={flow} />
                    <VehiclePhotoContainer data={data.data} refetch={refetch} />
                  </div>
                  <div className="dashboard__content--right">
                    <Banner
                      name={data.data.status.name}
                      label={
                        data.data.status.name === ACQUIRED.key
                          ? ACQUIRED.label
                          : data.data.status.label
                      }
                    />
                    {data.data.status.name === MOTOR_MARKET_AUCTION.key && (
                      <div>
                        {!!data.data.auction?.ends_at && (
                          <Timer
                            targetDate={formatDateToJsDate(
                              data.data.auction.ends_at
                            )}
                            isSummaryView
                          />
                        )}
                        <BidsHistory
                          vehicleAuctionID={data.data.auction_vehicle_id}
                          selectedVehicle={data.data}
                          panelView
                          refetchSummary={refetch}
                        />
                      </div>
                    )}
                    {data.data.status?.name === MOTOR_MARKET_AUCTION.key &&
                      hasPermission(
                        PermissionCategory.MOTOR_MARKET_SELLING,
                        PermissionAction.DELETE
                      ) && (
                        <RemoveFromMotorMarket
                          auctionVehicleId={data.data.auction_vehicle_id || ""}
                          onRefresh={refetch}
                        />
                      )}
                    {data.data.status?.name === TENDERED.key && (
                      <>
                        <BidSection
                          tenderStatus={tenderData?.data.status || ""}
                          tenderVehicleId={
                            data.data.tender_vehicle?.tender_vehicle_id || ""
                          }
                        />
                        <div className="w-52">
                          <Button
                            variant="secondary"
                            onClick={handleOpenRemoveModal}
                          >
                            <b>REMOVE FROM TENDER</b>
                          </Button>
                        </div>
                      </>
                    )}
                    <Footer
                      data={data.data}
                      flow={FLOW_TYPE.RETAIL_DEALERSHIP}
                      canSellVehicle={hasPermission(
                        isRetailDealershipFlow
                          ? PermissionCategory.RETAIL_INSPECTIONS
                          : PermissionCategory.WHOLESALE_INSPECTIONS,
                        PermissionAction.SELL
                      )}
                      canRevertVehicle={canRevertVehicle}
                      onRefresh={refetch}
                    />
                    {data.data.status.name === ACQUIRED.key && (
                      <ValuationsSection
                        data={data.data}
                        onRefresh={refetch}
                        canRevertVehicle={canRevertVehicle}
                      />
                    )}
                    {isStatusSold && canRevertVehicle && (
                      <SaleDetailSection data={data.data} refetch={refetch} />
                    )}
                    <VehicleComments contractID={data.data?.contract_id} />
                    {!data.data.is_locked && (
                      <NotifyUpdate
                        contract_id={data.data.contract_id}
                        hidden={data?.data?.updates_notified_to_valuers}
                        refetch={refetch}
                      />
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <RemoveTenderVehicleModal
        open={openRemoveModal}
        onConfirm={handleRemoveFromTender}
        onCancel={handleCloseRemoveModal}
      />
    </>
  );
};
