import { OdometerPlaceholder } from "components/OdometerPlaceholder";

import { ERROR_MESSAGE, formatNumber } from "utils";

import { IOdometerHistory } from "types";

import { useInfiniteScroll } from "hooks/useInfiniteScroll";

type OdometerReadingLogProps = {
  hasNextPage?: boolean;
  isLoading: boolean;
  isError: boolean;
  data: IOdometerHistory[];
  onScrollHitBottom: () => void;
};

export const OdometerReadingLog = ({
  hasNextPage,
  isLoading,
  isError,
  data,
  onScrollHitBottom,
}: OdometerReadingLogProps) => {
  const renderContent = () => {
    return data.map((odometer: IOdometerHistory) => {
      return (
        <div key={odometer.id} className="odometer__log">
          <div className="odometer__info">
            {odometer.logger.name || odometer.created_at}
            <span>{formatNumber(odometer.odometer)}</span>
          </div>
          {odometer.logger.name && (
            <div className="odometer__detail">
              <span />
              <span>{odometer.created_at}</span>
            </div>
          )}
        </div>
      );
    });
  };

  const renderSkeletonPlaceholder = (hasNextPage || isLoading) && (
    <OdometerPlaceholder />
  );

  const { onScroll } = useInfiniteScroll({
    threshold: 400,
    preventInvokingCallback: isLoading,
    onScrollHitBottom,
  });

  if (isError) return <div>{ERROR_MESSAGE}</div>;

  return (
    <div className="odometer" onScroll={onScroll}>
      {renderContent()}
      {renderSkeletonPlaceholder}
    </div>
  );
};
