import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

import { showErrorToast, showSuccessToast, StorageKeys } from "utils";

import { IWebhookResponse } from "types";

export const useWebhookResponse = () => {
  const queryClient = useQueryClient();

  const processWebhook = useCallback((data: IWebhookResponse) => {
    if (data?.webhook_status?.status_code === 200) {
      showSuccessToast("Submission Successful");
      queryClient.invalidateQueries([StorageKeys.VALUATION_HISTORY]);
    } else {
      showErrorToast("Something went wrong. Try again");
    }
  }, []);

  return { processWebhook };
};
