import { useFormattedCoordinates } from "hooks/useFormattedCoordinates";

type RedirectToGoogleMapsProps = {
  location?: string;
};

export const RedirectToGoogleMaps = ({
  location,
}: RedirectToGoogleMapsProps) => {
  const coordinates = useFormattedCoordinates(location);

  if (!location) {
    return null;
  }

  return (
    <div className="w-[260px]">
      <a
        href={location}
        className="text-secondary"
        target="_blank"
        rel="noreferrer"
      >
        {coordinates || location}
      </a>
    </div>
  );
};
