import * as Sentry from "@sentry/react";
import React, { Component, ReactNode } from "react";

interface ErrorBoundaryProps {
  children: ReactNode;
  fallback?: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.error("Error caught by ErrorBoundary:", error, errorInfo);

    Sentry.captureException(error, {
      extra: errorInfo as unknown as Record<string, unknown>,
    });
  }

  render() {
    const { hasError, error } = this.state;
    const { fallback, children } = this.props;

    if (hasError) {
      return (
        fallback || (
          <div className="text-center h-[100vh] flex flex-col justify-center">
            <h1>Something went wrong.</h1>
            {error && <p>{error.message}</p>}
          </div>
        )
      );
    }

    return children;
  }
}

export default ErrorBoundary;
