import imageCompression from "browser-image-compression";
import { DeviceType } from "enums/DeviceType";
import { FC, useEffect, useMemo, useRef, useState } from "react";

import { ModalPlatesLocations } from "components/app";
import { ButtonLink, Modal, SvgImage } from "components/shared";

import { showErrorToast } from "utils";
import { deviceDetector } from "utils/deviceDetector";
import { imageBlurDetector } from "utils/imageBlurDetector";

import { ICustomerInspection, IImage, ISection } from "types";

import { CustomerInspectionService } from "api/client";

import { useGeolocation } from "hooks/useGeolocation";

import "../../styles.scss";

interface IPhotoItemProps {
  title: string;
  inspectionId: string;
  placeholder: React.ReactNode;
  images: IImage[];
  onRefresh: () => void;
  disableUpload?: boolean;
  isVehicleVerification: boolean;
}

const PhotoItem: FC<IPhotoItemProps> = ({
  title,
  inspectionId,
  placeholder,
  images,
  onRefresh,
  disableUpload,
  isVehicleVerification,
}) => {
  const { state } = useGeolocation({ enableChecking: isVehicleVerification });
  const { latitude, longitude } = state;

  const fileInputField = useRef<HTMLInputElement>(null);
  const [previewImg, setPreviewImg] = useState<any>();
  const [imgIndex, setImgIndex] = useState<number>(-1);
  const isDesktop = deviceDetector() === DeviceType.Desktop;
  const disableDesktopUpload = isDesktop && disableUpload;

  useEffect(() => {
    if (images) {
      setImgIndex(
        images.findIndex(
          (img) => img.label?.toLowerCase() === title.toLowerCase()
        )
      );
    }
  }, [images, title]);

  const handleNewFileUpload = async (e: any) => {
    if (!disableDesktopUpload && e.target.files.length) {
      const imageFile = e.target.files[0];

      try {
        if (await imageBlurDetector(imageFile)) {
          showErrorToast(
            "The image is detected as blurred. Please take another photo."
          );
          return;
        }

        const previewImage = URL.createObjectURL(imageFile);

        if (title !== "Add More") {
          setPreviewImg(previewImage);
        }

        const options = {
          maxSizeMB: 0.5,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };

        const compressedFile = await imageCompression(imageFile, options);

        const formData = new FormData();
        let uploadLabel = title;
        let uploadcategory = "masthead";
        if (title === "Add More") {
          uploadLabel = "Additional Images";
          uploadcategory = "additional";
        }
        formData.append("image", compressedFile);
        formData.append("category", uploadcategory);
        formData.append("label", uploadLabel);

        if (isVehicleVerification) {
          formData.append("latitude", `${latitude}`);
          formData.append("longitude", `${longitude}`);
        }
        await CustomerInspectionService.uploadImages(formData, inspectionId);
        onRefresh();
      } catch (error) {
        setPreviewImg(null);
        showErrorToast(
          error instanceof Error
            ? error?.message
            : "An unexpected error occurred"
        );
      }
    } else if (disableDesktopUpload) {
      showErrorToast("Only photo capture is allowed");
    }
  };

  const handleImageClick = () => {
    if (!disableDesktopUpload && fileInputField.current) {
      fileInputField.current.click();
    } else if (disableDesktopUpload) {
      showErrorToast("Only photo capture is allowed");
    }
  };

  const handleRemoveFile = async (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
    try {
      if (images[imgIndex]) {
        setImgIndex(-1);
        await CustomerInspectionService.removeImage(
          { image_id: images[imgIndex].id },
          inspectionId
        );
        onRefresh();
      } else {
        showErrorToast(`Image uploading. Please wait a moment and try again`);
      }
    } catch (err) {
      showErrorToast(`Something is wrong.`);
    }
  };

  if (imgIndex > -1 && previewImg) {
    setPreviewImg(null);
  }

  if (title === "Add More") {
    return (
      <div className="photo-item--empty" onClick={handleImageClick}>
        <div className="photo-item__content">
          {placeholder}
          <span>{title}</span>
        </div>
        {!disableDesktopUpload && (
          <input
            type="file"
            ref={fileInputField}
            onChange={handleNewFileUpload}
            className="photo-item--upload"
            accept="image/*"
            capture={disableUpload ? "environment" : undefined}
          />
        )}
      </div>
    );
  }

  if (imgIndex > -1 || previewImg) {
    return (
      <div className="photo-item">
        <div className="photo-item__content">
          <img
            className="photo-item__content__img"
            src={images[imgIndex] ? images[imgIndex].url : previewImg}
            alt="Car Img"
          />
          <div className="photo-item__content__delete">
            <button onClick={handleRemoveFile}>
              <SvgImage name="DeleteIcon" width={15} />
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="photo-item--empty" onClick={handleImageClick}>
      <div className="photo-item__content">
        {placeholder}
        <span>{title}</span>
      </div>
      {!disableDesktopUpload && (
        <input
          type="file"
          ref={fileInputField}
          onChange={handleNewFileUpload}
          className="photo-item--upload"
          accept="image/*"
          capture={disableUpload ? "environment" : undefined}
        />
      )}
    </div>
  );
};

interface IProps {
  data: ICustomerInspection | undefined;
  inspectionId: string;
  onRefresh: () => void;
  sections: ISection[];
  disableUpload?: boolean;
  isVehicleVerification: boolean;
}
export const UploadScreen: FC<IProps> = ({
  data,
  inspectionId,
  onRefresh,
  sections,
  disableUpload,
  isVehicleVerification,
}) => {
  const [showPlatesModal, setShowPlatesModal] = useState<boolean>(false);

  const handlePlatesModal = () => {
    setShowPlatesModal(true);
  };

  const allImages = useMemo(() => {
    if (data?.images) {
      let additionalIndex = 0;
      return data?.images.map((image) => {
        if (image.category === "additional") {
          additionalIndex++;
          return {
            ...image,
            label: `${image.label}-${additionalIndex - 1}`,
          };
        } else {
          return image;
        }
      });
    }

    return [];
  }, [data?.images]);

  if (!sections || !data) {
    return <></>;
  }

  const { images } = data;
  const additionalImages = images.filter(
    (img) => img.category === "additional"
  );

  return (
    <div className="take-picture__content">
      <div className="take-picture__content__photos">
        {[
          ...sections,
          ...additionalImages.map(
            (img, idx) =>
              ({
                label: `${img.label}-${idx}`,
                key: `additional-${idx}`,
              } as ISection)
          ),
          { key: "add-more", label: "Add More" } as ISection,
        ].map((section, index) => (
          <PhotoItem
            key={index}
            title={section.label}
            inspectionId={inspectionId}
            placeholder={
              section.key === "add-more" ? (
                <SvgImage name="AddMorePlaceholderImage" />
              ) : (
                <img src={section.placeholder} alt="Placeholder" />
              )
            }
            images={allImages}
            onRefresh={onRefresh}
            disableUpload={disableUpload}
            isVehicleVerification={isVehicleVerification}
          />
        ))}
      </div>
      <p className="take-picture__desc mb-5">
        If you want to know where to find the VIN, please click{" "}
        <ButtonLink onClick={handlePlatesModal}>here</ButtonLink>.
      </p>
      <Modal
        size="auto"
        open={showPlatesModal}
        closeModal={() => setShowPlatesModal(false)}
      >
        <ModalPlatesLocations />
      </Modal>
    </div>
  );
};
