import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { formNewDealershipSalesTestID } from "components/app/dataTestIDs";
import {
  Button,
  ButtonLink,
  FieldText,
  Modal,
  SvgImage,
  TextError,
} from "components/shared";
import { FieldPhone } from "components/shared/Fields/Text/FieldPhone";

import {
  checkIsValidNumber,
  convertToNumber,
  formatNumber,
  formatPhoneNumber,
} from "utils";
import { pattern } from "utils/validation";

import { AcquisitionService, DealershipInspectionService } from "api/client";

import { ConfirmSale } from "./ConfirmSale";

export interface FormNewDealershipSalesProps {
  contractId: string;
  dealershipId: string;
  onGoBack: () => void;
  onRefresh: () => void;
  dataTestID?: string;
}

interface SaleDetailsFormType {
  dealershipName: string;
  contactEmail: string;
  dealershipLicense: string;
  contactName: string;
  contactNumber: string;
  contactAddress: string;
  sale_price: string;
  abn?: string;
}

export const FormNewDealershipSales = ({
  contractId,
  dealershipId,
  onGoBack,
  onRefresh,
  dataTestID,
}: FormNewDealershipSalesProps) => {
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [salePayload, setSalePayload] = useState<any>();
  const [isNumber, setIsNumber] = useState(true);
  const [savedSalesPrice, setSavedSalesPrice] = useState("");

  const navigate = useNavigate();

  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<SaleDetailsFormType>({
    defaultValues: {
      sale_price: "",
      dealershipName: "",
      dealershipLicense: "",
      contactName: "",
      contactNumber: "",
      contactEmail: "",
      contactAddress: "",
      abn: "",
    },
  });

  const { mutate, isLoading } = useMutation(
    (data: any) => AcquisitionService.sellTo(contractId, data.type, data),
    {
      onSuccess: () => {
        toast.success("Sold successfully!", {
          autoClose: 2000,
          theme: "colored",
        });
        onRefresh();
      },
      onError: () => {
        toast.error("Failed to Sell", {
          autoClose: 2000,
          theme: "colored",
        });
      },
    }
  );
  const {
    mutate: handleCreateNewDealership,
    isLoading: isCreatingNewDealership,
  } = useMutation(
    (data: any) =>
      DealershipInspectionService.createDealershipPool(
        data,
        dealershipId || ""
      ),
    {
      onSuccess: (res: any) => {
        const payload = {
          type: "dealership",
          sale_price: convertToNumber(savedSalesPrice),
          dealership_id: res.data?.id,
        };
        mutate(payload);
      },
      onError: () => {
        toast.error("Failed to create new dealership", {
          autoClose: 2000,
          theme: "colored",
        });
      },
    }
  );

  const handleSalePrice = (value: string) => {
    setValue("sale_price", formatNumber(value.replaceAll(",", "")));
    setIsNumber(checkIsValidNumber(getValues("sale_price")));
    clearErrors("sale_price");
  };

  const handleSell = () => {
    handleCreateNewDealership(salePayload);
  };

  const handleConfirm = (data: SaleDetailsFormType) => {
    const {
      sale_price,
      dealershipName,
      dealershipLicense,
      contactEmail,
      contactName,
      contactNumber,
      contactAddress,
      abn,
    } = data;

    const payload = {
      type: "dealership",
      name: dealershipName,
      email: contactEmail,
      phone: contactNumber,
      license: dealershipLicense,
      contact: contactName,
      address: contactAddress,
      abn: abn || undefined,
    };
    setSalePayload(payload);
    setOpenConfirmModal(true);
    setSavedSalesPrice(sale_price);
  };

  return (
    <div
      className="sale-details"
      data-testid={dataTestID || formNewDealershipSalesTestID}
    >
      <div className="absolute top-11">
        <ButtonLink onClick={onGoBack}>
          <SvgImage name="LeftRoundedIcon" />
        </ButtonLink>
      </div>
      <div className="sale-details__row">
        <div>
          <FieldText
            title="Sale Price*"
            type="text"
            {...register("sale_price", { required: true })}
            placeholder="Enter Sale Price"
            onChange={(e) => handleSalePrice(e.target.value)}
          />
          <div>
            {errors.sale_price && <TextError error="Sale Price is required" />}
          </div>
          <div>
            {isNumber === false && (
              <TextError error="Please enter numbers only" />
            )}
          </div>
        </div>

        <div>
          <FieldText
            title="Dealership Name*"
            type="text"
            {...register("dealershipName", { required: true })}
            placeholder="Enter Dealership Name."
          />
          {errors.dealershipName && (
            <TextError error="Dealership Name is required" />
          )}
        </div>

        <div>
          <FieldText
            title="Dealership License No*"
            type="text"
            {...register("dealershipLicense", { required: true })}
            placeholder="Enter Dealership License No."
          />
          {errors.dealershipLicense && (
            <TextError error="Dealership License No is required" />
          )}
        </div>

        <div>
          <FieldText
            title="Contact Name*"
            type="text"
            {...register("contactName", { required: true })}
            placeholder="Enter Contact Name"
          />
          {errors.contactName && <TextError error="Contact Name is required" />}
        </div>

        <div>
          <FieldPhone
            register={register}
            setValue={setValue}
            name="contactNumber"
          />
          {errors.contactNumber && (
            <TextError
              error={
                getValues("contactName")
                  ? "Please enter the correct contact number"
                  : "Contact Number is required"
              }
            />
          )}
        </div>

        <div>
          <FieldText
            title="Email Address*"
            type="text"
            {...register("contactEmail", {
              required: true,
              pattern: pattern.email,
            })}
            placeholder="Enter Email Address"
          />
          {errors.contactEmail && (
            <TextError error="Email Address is required" />
          )}
        </div>

        <div>
          <FieldText
            title="Address"
            type="text"
            {...register("contactAddress", { required: false })}
            placeholder="Enter Dealership Address"
          />
        </div>

        <div>
          <FieldText
            title="ABN"
            type="text"
            {...register("abn", {
              required: false,
              pattern: /^\d{11}$/,
            })}
            placeholder="Enter ABN"
          />
          {errors.abn && <TextError error="The ABN must be 11 digits." />}
        </div>
      </div>

      <div className="sale-details__footer">
        <Button
          type="submit"
          isLoading={isLoading || isCreatingNewDealership}
          onClick={handleSubmit(handleConfirm)}
          disabled={!isNumber}
        >
          <span>Save</span>
        </Button>
      </div>
      <Modal
        open={openConfirmModal}
        size="auto"
        closeModal={() => setOpenConfirmModal(false)}
      >
        <ConfirmSale
          onClose={() => setOpenConfirmModal(false)}
          onConfirm={handleSell}
        />
      </Modal>
    </div>
  );
};
