import { DateTime } from "luxon";
import {
  Control,
  Controller,
  FormState,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetFocus,
  UseFormSetValue,
} from "react-hook-form";

import { LoadItem } from "components/app";
import { FieldText, SvgImage, TextError, Tooltip } from "components/shared";
import { FieldPhone } from "components/shared/Fields/Text/FieldPhone";

import { IMotorMarketForm } from "types/IMotorMarket";

export const AuctionDetailsForm = ({
  data,
  isLoading,
  register,
  setValue,
  control,
  setFocus,
  formState,
  getValues,
  canEdit = true,
}: {
  data?: IMotorMarketForm;
  isLoading: boolean;
  register: UseFormRegister<IMotorMarketForm>;
  setValue: UseFormSetValue<IMotorMarketForm>;
  control: Control<IMotorMarketForm>;
  setFocus: UseFormSetFocus<IMotorMarketForm>;
  formState: FormState<IMotorMarketForm>;
  getValues: UseFormGetValues<IMotorMarketForm>;
  canEdit?: boolean;
}) => {
  const { errors } = formState;

  const convertDateToFormFormat = (dateTime: string) => {
    const [date, time] = dateTime.split(" - ");
    return {
      date: DateTime.fromFormat(date, "dd LLL y").toFormat("yyyy-MM-dd"),
      time: DateTime.fromFormat(time, "hh:mm a").toFormat("HH:mm"),
    };
  };

  if (isLoading) return <LoadItem />;

  return (
    <>
      {data && (
        <div className="flex flex-row justify-center mt-10 gap-8 min-h-[330px]">
          <div className="flex flex-col w-[433px]">
            <span className="font-medium">Auction Starts</span>
            <div className="text-label text-sm font-normal w-[400px] mt-3">
              As soon as you decide to Publish your listings, the vehicles will
              go live.
            </div>
            <div className="flex items-center mt-8 gap-2">
              <p className="font-medium">Auction Ends</p>
              <Tooltip
                label={
                  "Vehicle cannot be sold to a buyer until the auction ends. \nThe Maximum Duration is 10 Days. On average shorter duration \nauctions always perform better than longer auctions."
                }
                show={true}
              >
                <SvgImage name={"MarketInfoIcon"} width={20} />
              </Tooltip>
            </div>
            <div className="text-label text-sm font-normal w-[400px] mt-3">
              The Maximum Duration is 10 Days. Vehicles cannot be sold to a
              buyer until the auction ends.
            </div>
            <div>
              <div className="grid grid-cols-2 gap-5 mt-3">
                <Controller
                  control={control}
                  render={() => (
                    <FieldText
                      type="date"
                      {...register("ends_at", { required: true })}
                      min={DateTime.now().toFormat("yyyy-MM-dd")}
                      max={DateTime.now()
                        .plus({ days: 10 })
                        .toFormat("yyyy-MM-dd")}
                      title="End Date*"
                      defaultValue={
                        data.ends_at
                          ? convertDateToFormFormat(data.ends_at).date
                          : ""
                      }
                      disabled={!canEdit}
                    />
                  )}
                  name="ends_at"
                />
                <Controller
                  control={control}
                  render={() => (
                    <FieldText
                      {...register("ends_at_time", { required: true })}
                      type="time"
                      title="End Time*"
                      onInput={() => setFocus("contact_name")}
                      defaultValue={
                        data.ends_at
                          ? convertDateToFormFormat(data.ends_at).time
                          : ""
                      }
                      disabled={!canEdit}
                    />
                  )}
                  name="ends_at_time"
                />
              </div>
              {errors.ends_at?.type === "required" && (
                <TextError error={"End Date and Time Required."} />
              )}
              {errors.ends_at?.type === "validate" && (
                <TextError
                  error={
                    "End date has already passed. Please select correct one."
                  }
                />
              )}
            </div>
          </div>
          <div className="flex flex-col  w-[433px]">
            <span className="font-medium">Auction Information (Public)</span>
            <div className="flex-col mt-5 space-y-4">
              <div>
                <FieldText
                  title="Contact Name"
                  type="text"
                  {...register("contact_name", { required: true })}
                  placeholder="Enter Your Name"
                  maxLength={255}
                  defaultValue={data.contact_name}
                  disabled={!canEdit}
                />
                {errors.contact_name && (
                  <TextError error="Contact Name Required." />
                )}
              </div>
              <div>
                <FieldPhone
                  title="Contact Number"
                  name="contact_phone"
                  register={register}
                  setValue={setValue}
                  defaultValue={data?.contact_phone}
                  disabled={!canEdit}
                  placeholder="Enter Contact Number."
                />
                {errors.contact_phone && (
                  <TextError
                    error={
                      getValues("contact_phone")
                        ? "Please enter correct phone number"
                        : "Phone number required."
                    }
                  />
                )}
              </div>
              <div>
                <FieldText
                  title="Location"
                  type="text"
                  {...register("location", { required: false })}
                  placeholder="Enter Location of Vehicles."
                  maxLength={255}
                  defaultValue={data.location}
                  disabled={!canEdit}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
