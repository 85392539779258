import { FC, MouseEvent, useMemo } from "react";
import ClipLoader from "react-spinners/ClipLoader";

import { buttonTestID } from "../dataTestIDs";

import "./styles.scss";

export interface ButtonProps {
  onClick?: () => void;
  type?: "button" | "reset" | "submit";
  variant?: "primary" | "secondary";
  disabled?: boolean;
  isLoading?: boolean;
  loadingSize?: number;
  className?: string;
  dataTestID?: string;
  isLoadingText?: string;
}

export const Button: FC<ButtonProps> = ({
  onClick = () => {},
  type = "button",
  variant = "primary",
  disabled,
  children,
  isLoading,
  loadingSize = 24,
  className,
  dataTestID,
  isLoadingText,
}) => {
  const formClassName = useMemo(() => {
    if (variant === "primary") {
      if (disabled) return "primary-button--disabled";
      return "primary-button";
    } else {
      if (disabled) return "secondary-button--disabled";
      return "secondary-button";
    }
  }, [variant, disabled]);

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (disabled) return;

    e.stopPropagation();
    onClick();
  };

  return (
    <button
      type={type}
      onClick={handleClick}
      disabled={disabled || isLoading ? true : false}
      className={`${formClassName} ${className}`}
      data-testid={dataTestID || buttonTestID}
    >
      {isLoading ? (
        <div
          className="loading"
          data-testid={`${dataTestID || buttonTestID}-loading`}
        >
          <ClipLoader
            color={variant === "primary" ? "#fff" : "#000"}
            size={loadingSize}
          />
          <span className="uppercase">&nbsp;{isLoadingText}</span>
        </div>
      ) : (
        children
      )}
    </button>
  );
};
