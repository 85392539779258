import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { useParams } from "react-router-dom";

import { SvgImage } from "components/shared";

import { AuthContext } from "context/AuthContext";

import { CustomerInspectionService } from "api/client";

import "../styles.scss";

const Complete = () => {
  const { userID } = useContext(AuthContext);
  const params = useParams();
  const inspectionId = params.id;

  const { data } = useQuery(
    [`verify_inspection_link_${userID}`, inspectionId],
    CustomerInspectionService.verifyInspectionLInk
  );

  return (
    <div className="customer">
      <div className="customer__content--complete">
        <h5 className="complete__title">Congratulations!</h5>
        <div className="complete__desc">
          Your Inspection has been sent to{" "}
          <span>{data?.data?.salesperson?.name},</span>{" "}
          <span>{data?.data?.dealership?.name}.</span> They will contact you as
          soon as they have reviewed your inspection.
        </div>
        <div className="complete__footer">
          <span>You have completed</span>
          <span className="flex items-center">
            your Inspection with{" "}
            <SvgImage
              name="MPLogo"
              className="ml-2 mt-1"
              width={116}
              height={39}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default Complete;
