import { IMotorMarketBuyer } from "types/IMotorMarket";

import { BuyingCard } from "./BuyingCard";

export const ListVehicles = ({
  data,
  status,
  onClick,
  refetch,
  canView = true,
}: {
  data: IMotorMarketBuyer[];
  status: string;
  onClick: (auction: IMotorMarketBuyer) => void;
  onClickWatchlist?: (
    auctionVehicleID: string,
    action: "add" | "remove"
  ) => void;
  refetch: () => void;
  canView?: boolean;
}) => {
  return (
    <>
      {data.map((auction, index) => {
        return (
          <BuyingCard
            key={index}
            auction={auction}
            onClick={onClick}
            refetch={refetch}
            status={status}
            canView={canView}
          />
        );
      })}
    </>
  );
};
