import { FormProvider, useForm } from "react-hook-form";

import { formManualSearchTestID } from "components/app/dataTestIDs";
import { Button } from "components/shared";
import { ManualSearchFormFields } from "components/VehicleSearch/ManualSearchFormFields";

export interface ISearchFormType {
  year: string;
  make: string;
  model: string;
}

export interface FormManualSearchProps {
  onNextStep: (val: ISearchFormType) => void;
  dataTestID?: string;
}

export const FormManualSearch = ({
  onNextStep,
  dataTestID,
}: FormManualSearchProps) => {
  const methods = useForm();

  const onSubmit = (data: any) => {
    onNextStep(data);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div
          className="search-form__row"
          data-testid={dataTestID || formManualSearchTestID}
        >
          <ManualSearchFormFields isRequired />
        </div>
        <div className="search-form__footer">
          <div className="search-form__footer__tool">
            <Button type="submit">
              <span className="search-form__footer__tool__title">
                Find Vehicle
              </span>
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default FormManualSearch;
