import { useLocation } from "react-router-dom";

export const useResetPasswordParams = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  return {
    token: queryParams.get("token"),
    email: queryParams.get("email"),
  };
};
