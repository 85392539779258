import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { FieldState } from "components/app";
import { Button, ButtonLink, FieldTextarea } from "components/shared";
import { ContactSupportLink } from "components/Support/ContactSupportLink";

import { AuthContext } from "context/AuthContext";

import { CUSTOMER_INSPECTION_DATA, INSPECTION_STEP } from "utils";

import { ICustomerInspection } from "types";

import { CustomerInspectionService } from "api/client";

import { useLocalStorage } from "hooks";

export const ViewSpecification = () => {
  const { userID } = useContext(AuthContext);
  const [comment, setComment] = useState("");
  const params = useParams();
  const navigate = useNavigate();
  const [savedInspection, saveInspection] = useLocalStorage(
    CUSTOMER_INSPECTION_DATA,
    null
  );

  const { data: verifyData } = useQuery(
    [`verify_inspection_link_${userID}`, params.id],
    CustomerInspectionService.verifyInspectionLInk
  );

  const { mutate, isLoading } = useMutation(
    (payload: any) =>
      CustomerInspectionService.updateInspection(payload, params.id!),
    {
      onSuccess: (res: any) => {
        saveInspection(res.data);
        toast.success("Saved Successfully", {
          autoClose: 2000,
          theme: "colored",
        });
        navigate("../conditions");
      },
      onError: () => {
        toast.error("Failed to Save", {
          theme: "colored",
          autoClose: 2000,
        });
      },
    }
  );

  useEffect(() => {
    if (verifyData) {
      const { status } = verifyData.data;

      if (status.name === INSPECTION_STEP.INSPECTION_SUBMITTED) {
        return navigate(`/inspection/customer/${params.id}/complete`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyData]);

  const handleGoBack = () => {
    navigate("../enter-kms");
  };

  const handleContinue = () => {
    if (savedInspection) {
      const {
        vehicle: { specification },
      } = savedInspection as ICustomerInspection;
      const savedSpecification: any = {};
      Object.keys(specification).forEach((key) => {
        savedSpecification[key] = (specification as any)[key].value;
      });
      const payload = {
        type: "specification",
        specification: {
          ...savedSpecification,
          comment,
        },
      };

      mutate(payload);
    }
  };

  useEffect(() => {
    if (!savedInspection) {
      navigate("../enter-kms");
    } else {
      setComment(savedInspection.vehicle.specification.comment?.value || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedInspection]);

  if (!savedInspection) {
    return <></>;
  }

  const {
    vehicle: { specification },
  } = savedInspection as ICustomerInspection;
  return (
    <div className="flex flex-col justify-between text-primary w-full h-[calc(100%-50px)]">
      <div className="absolute h-0 md:h-10 w-full left-0 border-b-[7px] border-softPeach">
        <div className="bg-primary h-[7px] mt-0 md:mt-33 w-1/4"></div>
      </div>
      <div className="flex-1 w-full flex flex-col overflow-auto px-5 mt-0 md:mt-[40px]">
        <div className="text-black text-sm pt-7">
          <ButtonLink onClick={handleGoBack}>{"< Back"}</ButtonLink>
        </div>
        <h5 className="text-primary font-bold text-23 mt-4 mb-0">
          Vehicle Inspection
        </h5>
        <div>
          <label className="text-primary text-sm font-bold leading-10">
            STEP 1/3 - SPECIFICATIONS
          </label>
          <p className="text-label text-sm font-normal">
            Please make sure all the information is correct. The more
            information you enter, the more accurate valuation you will receive.
          </p>
        </div>
        <div className="view-specification__comment">
          <FieldTextarea
            title="Vehicle Comments"
            placeholder="Please enter location of vehicle and if any of the below information is incorrect"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </div>
        <div className="view-specification__content">
          <FieldState name="Vehicle Year" value={specification?.year?.value} />
          <FieldState name="Vehicle Make" value={specification?.make?.value} />
          <FieldState
            name="Vehicle Model"
            value={specification?.model?.value}
          />
          <FieldState name="Series" value={specification?.series?.value} />
          <FieldState name="Variant" value={specification?.variant?.value} />
          <FieldState
            name="Body Type"
            value={specification?.body_type?.value}
          />
          <FieldState
            name="Body Name"
            value={specification?.body_name?.value}
          />
          <FieldState name="Fuel" value={specification?.fuel?.value} />
          <FieldState name="Drive" value={specification?.drive?.value} />
          <FieldState
            name="Cylinders"
            value={specification?.cylinders?.value}
          />
          <FieldState name="Litres" value={specification?.litres?.value} />
          <FieldState
            name="Transmission"
            value={specification?.transmission?.value}
          />
          <FieldState name="Seats" value={specification?.seats?.value} />
          <FieldState name="Doors" value={specification?.doors?.value} />
          <FieldState
            name="Vehicle Type"
            value={specification.vehicle_type.value}
          />
          <FieldState name="Engine" value={specification?.engine?.value} />
          <FieldState name="Colour" value={specification?.colour?.value} />
        </div>
      </div>

      <div className="py-3 px-8 flex flex-col gap-2">
        <Button type="submit" isLoading={isLoading} onClick={handleContinue}>
          <span className="font-bold">Continue</span>
        </Button>
        <ContactSupportLink hasMPLogo />
      </div>
    </div>
  );
};
