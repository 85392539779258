import { SHARPNESS_TRESHOLD } from "utils/constants";

export const imageBlurDetector = async (file: File): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      try {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        if (!ctx) return reject("Failed to create canvas context.");

        canvas.width = img.width;
        canvas.height = img.height;

        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

        let sum = 0;
        const data = imageData.data;
        for (let i = 0; i < data.length; i += 4) {
          const grayscale = (data[i] + data[i + 1] + data[i + 2]) / 3;
          sum += grayscale;
        }

        const sharpness = sum / (imageData.width * imageData.height);

        resolve(sharpness < SHARPNESS_TRESHOLD);
      } catch (error) {
        reject(`Error processing the image: ${error}`);
      }
    };

    img.onerror = () => {
      reject("Failed to load the image.");
    };
  });
};
