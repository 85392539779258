import { useContext, useEffect, useMemo, useState } from "react";

import { CustomSelectDropdown } from "components/shared/Fields/Dropdown/CustomSelectDropdown";

import { AuthContext } from "context/AuthContext";
import { useDealership } from "context/DealershipContext";

import { useDealershipOptions } from "hooks/useDealershipOptions";

export const DealershipSelector = () => {
  const [dropdownSelected, setDropdownSelected] = useState<string>();

  const { user } = useContext(AuthContext);
  const {
    setDealershipID,
    setIsDealershipSelectorVisible,
    isDealershipSelectorVisible,
  } = useDealership();
  const children = user?.data?.dealership?.children;

  const { allOptions, options, prependOptions } = useDealershipOptions();

  useEffect(() => {
    const isShowDealershipSelector = options?.length > 2;

    setIsDealershipSelectorVisible?.(isShowDealershipSelector);

    if (options[1].key && !isShowDealershipSelector) {
      setDealershipID([options[1].key].join(","));
      return;
    }

    if (options[1].key && isShowDealershipSelector) {
      // options will have View all and its own dealership, if the length is only 2 or less,
      // we need to hide the dropdown since it will always have the same dealership_id
      setDropdownSelected(options[0].key);
      setDealershipID([...allOptions]?.splice(1).join(","));
    }
  }, [options, children, allOptions]);

  const onChange = (selectedKey: string) => {
    const isAllSelected = selectedKey === "all";

    if (isAllSelected) {
      setDropdownSelected(selectedKey);
      setDealershipID([...allOptions]?.splice(1).join(","));
    } else {
      setDropdownSelected(selectedKey);
      setDealershipID(selectedKey);
    }
  };

  if (!isDealershipSelectorVisible) return null;

  return (
    <div className="mr-5">
      <CustomSelectDropdown
        value={dropdownSelected || ""}
        onChange={onChange}
        placeholder="Select dealership"
        options={options}
        prependOptions={prependOptions}
        className="!text-right !bg-transparent"
        dropdownGreyBG
      />
    </div>
  );
};
