import classNames from "classnames";
import { useEffect, useState } from "react";

import { formSelectVariantTestID } from "components/app/dataTestIDs";
import { ButtonLink, SvgImage } from "components/shared";

import { IVariant } from "types";

import { VehicleSearchService } from "api/client";

import { ISearchFormType } from "./FormManualSearch";

export interface FormSelectVariantProps {
  selectedVariant?: IVariant | null;
  selectedSearchOptions?: ISearchFormType | null;
  onNextStep: (val: IVariant) => void;
  onBack?: (step: string) => void;
  dataTestID?: string;
  totalResults?: (total: number) => void;
}

export const FormSelectVariant = ({
  selectedVariant,
  selectedSearchOptions,
  onNextStep,
  onBack,
  dataTestID,
  totalResults,
}: FormSelectVariantProps) => {
  const [variants, setVariants] = useState<IVariant[]>([]);

  useEffect(() => {
    if (selectedSearchOptions) {
      VehicleSearchService.searchVariants(selectedSearchOptions).then(
        (res: any) => {
          setVariants(res.data);
          !!totalResults && totalResults(res.data.length);
        }
      );
    }
  }, [selectedSearchOptions]);

  const handleGoBack = () => {
    if (onBack) onBack("SELECT_VARIANT");
  };

  return (
    <div
      className="variant-wrapper"
      data-testid={dataTestID || formSelectVariantTestID}
    >
      {variants.map((variant, idx) => (
        <ButtonLink key={idx} onClick={() => onNextStep(variant)}>
          <div
            className={classNames("variant text-left py-6", {
              "bg-lighterBlue": selectedVariant === variant,
            })}
          >
            <label className="variant__title">{variant.model_name}</label>
          </div>
        </ButtonLink>
      ))}
      {onBack && (
        <div className="variant__back">
          <ButtonLink onClick={handleGoBack}>
            <SvgImage name="LeftRoundedIcon" />
          </ButtonLink>
        </div>
      )}
    </div>
  );
};
