import { useMutation } from "@tanstack/react-query";
import { useContext, useState } from "react";

import { DeclineRequestModal } from "components/Modal/DeclineRequestModal";
import { Button } from "components/shared";

import { AuthContext } from "context/AuthContext";

import { PENDING, showErrorToast, showSuccessToast } from "utils";
import { PermissionAction, PermissionCategory } from "utils/userPermissions";

import { ValuationService } from "api/client";

type DeclineRequestProps = {
  contractID: string;
  requestID: string;
  onRefresh: () => void;
  status: string;
};

export const DeclineRequest = ({
  contractID,
  requestID,
  onRefresh,
  status,
}: DeclineRequestProps) => {
  const { hasPermission } = useContext(AuthContext);
  const [openDeclineModal, setOpenDeclineModal] = useState(false);

  const { mutate, isLoading } = useMutation(
    () => ValuationService.declineValuationRequest(contractID, requestID),
    {
      onSuccess: () => {
        showSuccessToast("Request Declined");
        setOpenDeclineModal(false);
        onRefresh();
      },
      onError: () => {
        showErrorToast("Failed to Decline");
      },
    }
  );

  return (
    <>
      {status === PENDING.key &&
        hasPermission(
          PermissionCategory.WHOLESALE_VALUATIONS,
          PermissionAction.CREATE
        ) && (
          <div className="w-full">
            <Button
              variant="secondary"
              onClick={() => setOpenDeclineModal(true)}
              className="uppercase font-bold"
              isLoading={isLoading}
            >
              Decline Request
            </Button>
          </div>
        )}
      <DeclineRequestModal
        open={openDeclineModal}
        onConfirm={mutate}
        onCancel={() => setOpenDeclineModal(false)}
        isLoading={isLoading}
      />
    </>
  );
};
