import { useContext, useMemo } from "react";

import { AuthContext } from "context/AuthContext";

export const useDealershipOptions = () => {
  const { user } = useContext(AuthContext);
  const dealership = user?.data.dealership;
  const children = user?.data?.dealership?.children;

  const options = useMemo(() => {
    const parentDealership = Object.values(children || {})
      .map((item) => {
        const childOptions = Object.values(item.children || {}).map(
          (child) => ({
            key: child.id,
            label: child.name,
            parent: item.id,
          })
        );

        return [{ key: item.id, label: item.name }, ...childOptions];
      })
      .flat();

    return [
      {
        key: "all",
        label: "VIEW ALL",
        isHidden: true,
        noIndent: true,
      },
      {
        key: dealership?.id || "",
        label: dealership?.name || "",
        noIndent: true,
      },
      ...parentDealership,
    ];
  }, [dealership]);

  const childrenOptions = useMemo(() => {
    if (!children) return [];
    const childrenDealerships = Object.values(children).map((value) => ({
      parent: value.id,
      key: value.id,
      children: Object.values(value.children || {}).map((child) => ({
        key: child.id,
        label: child.name.toUpperCase(),
      })),
    }));

    return childrenDealerships;
  }, [children]);

  const allOptions = useMemo(() => {
    const parentOptions = options?.map((option) => option.key);
    return [...parentOptions];
  }, [options, childrenOptions]);

  const prependOptions = useMemo(
    () => options.slice(0, 1).map((option) => ({ ...option, isHidden: false })),
    [options]
  );

  return { options, childrenOptions, allOptions, prependOptions };
};
