import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PPSRStatus } from "enums/PPSRStatus";
import { useContext } from "react";

import { AuthContext } from "context/AuthContext";
import { useDealership } from "context/DealershipContext";

import { showErrorToast, showSuccessToast, StorageKeys } from "utils";

import { PPSRCheckInfo } from "types";

import { PPSRService, VehicleService } from "api/client";

type UseSearchByVinOrContractProps = {
  onRefetch?: () => void;
  contractID?: string;
  isRefresh?: boolean;
  onForceSearch?: (data: PPSRCheckInfo) => void;
  onCloseMainPrompt?: () => void;
  onCloseWarning?: () => void;
};

export const useSearchByVinOrContract = ({
  onRefetch,
  isRefresh,
  onForceSearch,
  onCloseMainPrompt,
  onCloseWarning,
}: UseSearchByVinOrContractProps) => {
  const { dealershipID, showAllDealerships } = useDealership();
  const { user } = useContext(AuthContext);
  const parentDealership = user?.data.dealership?.id || "";
  const queryClient = useQueryClient();

  const onSuccess = (data?: PPSRCheckInfo) => {
    queryClient.invalidateQueries([StorageKeys.PPSRList]);

    showSuccessToast(
      data?.already_exists ? "PPSR Status Unchanged" : "Processing PPSR"
    );

    onRefetch?.();
    onCloseWarning?.();
  };

  const { mutate: searchContractPPSR, isLoading: isLoadingSearchPPSR } =
    useMutation(
      ({ contractID, refetch }: { contractID: string; refetch: boolean }) =>
        VehicleService.searchPPSR(contractID, refetch),
      {
        onSuccess: () => {
          onSuccess();
        },
        onError: () => {
          showErrorToast("Something went wrong. Try Again");
        },
      }
    );

  const { mutate: searchPPSRByVin, isLoading: isLoadingPPSRByVin } =
    useMutation(
      ({
        vin,
        refetch,
        forceSearch,
      }: {
        vin: string;
        refetch: boolean;
        forceSearch: boolean;
      }) =>
        PPSRService.request({
          vin,
          refetch,
          dealership_id: showAllDealerships
            ? parentDealership
            : dealershipID || "",
          force_search: forceSearch,
        }),
      {
        onSuccess: (data: { data: PPSRCheckInfo }) => {
          if (data.data.already_exists) {
            onForceSearch?.(data.data);
            onCloseMainPrompt?.();
          } else {
            onSuccess(data?.data);
          }
        },
        onError: () => {
          showErrorToast("Something went wrong. Try again");
        },
      }
    );

  return {
    searchPPSRByVin,
    isLoadingPPSRByVin,
    searchByContractID: searchContractPPSR,
    isLoadingSearchPPSR,
  };
};
