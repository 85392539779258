import { useQuery } from "@tanstack/react-query";
import { VEHICLE_SEARCH_OPTIONS } from "enums/VehicleSearch";
import { useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { VinRegoFormType } from "components/app/Form/Search/FormVinRegoSearch";
import { FieldText, FieldDropdown, TextError } from "components/shared";
import { VehicleSearch } from "components/VehicleSearch";

import { AuthContext } from "context/AuthContext";

import {
  CUSTOMER_SEARCHED_VEHICLE,
  INSPECTION_STEP,
  STATE_OPTIONS,
} from "utils";

import { CustomerInspectionService, VehicleSearchService } from "api/client";

import { useLocalStorage } from "hooks";

export const FindVehicle = () => {
  const { userID } = useContext(AuthContext);

  const navigate = useNavigate();
  const params = useParams();
  const [isSearching, setIsSearching] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, saveVehicle] = useLocalStorage(CUSTOMER_SEARCHED_VEHICLE, null);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      plate: "",
      state: "",
    },
  });

  const { data: verifyData } = useQuery(
    [`verify_inspection_link_${userID}`, params.id],
    CustomerInspectionService.verifyInspectionLInk
  );

  useEffect(() => {
    if (verifyData) {
      const { status } = verifyData.data;

      if (status.name === INSPECTION_STEP.INSPECTION_SUBMITTED) {
        return navigate(`/inspection/customer/${params.id}/complete`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyData]);

  const handleFindVehicle = async (data: VinRegoFormType) => {
    try {
      setIsSearching(true);
      const searchResult = await VehicleSearchService.searchPlate(
        { ...data, force_search: 1 },
        params.id || ""
      );
      if (searchResult) {
        setIsSearching(false);
        saveVehicle(searchResult.data);
        navigate("../vehicle-confirmation");
      }
    } catch (err) {
      setIsSearching(false);
      toast.error("Failed to Find Vehicle", {
        theme: "colored",
        autoClose: 2000,
      });
    }
  };

  const handlePlate = (value: string) => {
    setValue("plate", value.replace(/\s/g, "").toUpperCase());
  };

  return (
    <VehicleSearch
      vehicleSearchOption={VEHICLE_SEARCH_OPTIONS.REGO}
      handleSearch={handleSubmit(handleFindVehicle)}
      isSearching={isSearching}
    >
      <div className="mb-[18px]">
        <FieldText
          title="Registration Plate"
          type="text"
          {...register("plate", { required: true })}
          placeholder="ABC123"
          onChange={(e) => handlePlate(e.target.value)}
        />
        {errors.plate && <TextError error="Please check your entry." />}
      </div>

      <div className="mb-[18px]">
        <Controller
          render={({ field: { value, onChange } }) => (
            <FieldDropdown
              title="Registration State"
              value={value}
              onChange={onChange}
              placeholder="Select State"
              options={STATE_OPTIONS}
            />
          )}
          control={control}
          name="state"
          rules={{ required: true }}
        />
        {errors.state && <TextError error="Please check your entry." />}
      </div>
    </VehicleSearch>
  );
};
