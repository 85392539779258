import { useMutation } from "@tanstack/react-query";

import { LinkButton } from "components/Button";
import { LoadingSpinner } from "components/Loading/LoadingSpinner";

import { showErrorToast } from "utils";

import { PPSRService, VehicleService } from "api/client";

type DownloadCertificateProps = {
  ppsrID: string;
  status: string;
  disabled?: boolean;
};

export const DownloadCertificate = ({
  ppsrID,
  status,
  disabled,
}: DownloadCertificateProps) => {
  const { mutate: fetchCertificate, isLoading: isLoadingFetchCertificate } =
    useMutation(() => PPSRService.fetchCertificate(ppsrID || ""), {
      onSuccess: (data) => {
        if (data) {
          const newWindow = window.open(data, "_blank", "noopener,noreferrer");
          if (newWindow) newWindow.opener = null;
        }
      },
      onError: () => {
        showErrorToast("Failed to Download. Please try again.");
      },
    });

  return (
    <LinkButton
      onClick={fetchCertificate}
      disabled={isLoadingFetchCertificate || disabled}
    >
      {isLoadingFetchCertificate ? (
        <LoadingSpinner />
      ) : ["available", "unavailable"].includes(status || "") ? (
        <span className="!text-secondary !text-sm">View PDF</span>
      ) : (
        <span className={"text-label !text-sm"}>Processing...</span>
      )}
    </LinkButton>
  );
};
