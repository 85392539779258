import { removeSpecialCharacters } from "utils/search.util";

interface HighlightTextProps {
  text: string;
  highlight?: string;
}

export const HighlightText = ({ text, highlight }: HighlightTextProps) => {
  if (!highlight?.trim()) {
    return <>{text}</>;
  }

  const highlights = highlight?.split(" ")?.map(removeSpecialCharacters) || [];

  const regex = new RegExp(`(${highlights.join("|")})`, "gi");

  const parts = text?.split(regex);

  return (
    <>
      {parts?.map((part, index) =>
        highlights?.some(
          (highlight) => part?.toLowerCase() === highlight?.toLowerCase()
        ) ? (
          <span key={index} className="bg-[#65EDE5]">
            {part}
          </span>
        ) : (
          part
        )
      )}
    </>
  );
};
