import { useMutation, useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import { ChangeEvent, useContext, useState } from "react";

import { ButtonStatus } from "components/app/Button";
import { ModalAddNotes, ModalConfirmTenderSale } from "components/app/Modal";
import { InformativeModal } from "components/Modal/InformativeModal";
import { RemoveTenderVehicleModal } from "components/Modal/RemoveVehicleModal/RemoveVehicleModal";
import { Odometer } from "components/Odometer";
import {
  Button,
  ButtonLink,
  Card,
  FieldText,
  Modal,
  SvgImage,
} from "components/shared";
import { HighlightText } from "components/shared/HighlightText";
import { Tooltip } from "components/Tooltip";

import { AuthContext } from "context/AuthContext";
import { useDealership } from "context/DealershipContext";

import {
  ActionResult,
  ActionResultMessage,
  ACTIVE,
  CLOSED,
  convertToNumber,
  formatNumber,
  formatNumberWithDecimal,
  getHighestBid,
  MAX_TENDER_BID,
  PENDING,
  showErrorToast,
  showSuccessToast,
  SOLDTENDERED,
  TENDER_FLOW_TYPE,
  WON,
} from "utils";
import { PermissionAction, PermissionCategory } from "utils/userPermissions";

import {
  IBids,
  IBidsWithDecimalAndComma,
  INote,
  ITenderVehicle,
  IVehicleDetail,
} from "types";

import { ReactComponent as EyeIcon } from "assets/images/eye-icon.svg";
import { ReactComponent as SendIcon } from "assets/images/send.svg";
import { ReactComponent as StarOutlineIcon } from "assets/images/star-outline.svg";
import { ReactComponent as StarIcon } from "assets/images/star.svg";

import { TenderService } from "api/client";

import { CardInfoChip } from "./CardInfoChip";

import "../styles.scss";

interface CardTenderVehicleProps {
  data: ITenderVehicle | IVehicleDetail;
  tenderId: string;
  from?: TENDER_FLOW_TYPE;
  tenderStatus?: string;
  tenderDealershipId?: string;
  editedBid?: IBids | IBidsWithDecimalAndComma;
  onClick: () => void;
  onRefresh: () => void;
  onChangeBid?: (val: IBidsWithDecimalAndComma) => void;
  canView?: boolean;
  canBid?: boolean;
  isGlobalSearch?: boolean;
  isEnded?: boolean;
  searchText?: string;
}

export const CardTenderVehicle = ({
  data,
  tenderId,
  from = TENDER_FLOW_TYPE.SELLING,
  tenderStatus,
  tenderDealershipId,
  editedBid,
  onClick,
  onRefresh,
  onChangeBid,
  canView = true,
  canBid = false,
  isGlobalSearch,
  isEnded,
  searchText,
}: CardTenderVehicleProps) => {
  const [currentBid, setCurrentBid] = useState("");
  const { dealershipID, showAllDealerships } = useDealership();
  const { user, userID, hasPermission } = useContext(AuthContext);
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [isBidSuccessOpen, setIsBidSuccessOpen] = useState(false);
  const [openSellConfirmModal, setOpenSellConfirmModal] = useState(false);

  const tenderVehicleId =
    "tender_vehicle_id" in data
      ? data.tender_vehicle_id
      : data?.tender_vehicle?.id || "";
  const isSaved =
    "is_saved" in data
      ? data?.is_saved
      : data?.tender_vehicle?.is_saved || false;
  const hasWidthdrawn =
    "has_withdrawn" in data
      ? data.has_withdrawn
      : data?.tender_vehicle?.has_withdrawn || false;
  const bidAmount =
    "bid" in data
      ? data.bid?.amount
      : "tender_vehicle" in data
      ? data.tender_vehicle?.bid?.latest_bid
      : 0;
  const bidSale = "bid" in data ? data.bid?.sale_amount : 0;

  const { data: notes, refetch: refetchNotes } = useQuery<{
    data: INote[];
  }>(
    [`fetch_note_${tenderVehicleId}_${userID}`, tenderVehicleId],
    TenderService.getNotes,
    {
      enabled: from === TENDER_FLOW_TYPE.BUYING,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const { data: bids } = useQuery(
    [`fetchTenderVehicleBids_${userID}`, tenderVehicleId],
    TenderService.getTenderVehicleBids,
    {
      enabled: from === TENDER_FLOW_TYPE.SELLING,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const { mutate: removeVehicle, isLoading: isRemoving } = useMutation(
    (data: { contract_ids: string[] }) =>
      TenderService.deleteVehicleFromTender(data, tenderId),
    {
      onSuccess: () => {
        showSuccessToast("Vehicle Removed");
        onCloseRemoveModal();
        onRefresh();
      },
      onError: () => {
        showErrorToast("Failed to Remove");
      },
    }
  );

  const { mutate: toggleFavorite } = useMutation(
    (payload: any) =>
      TenderService.toggleVehicleSave(payload, tenderVehicleId || "", isSaved),
    {
      onSuccess: () => {
        onRefresh();
      },
      onError: () => {
        showErrorToast("Failed To Add Favorite");
      },
    }
  );

  const { mutate: placeBid, isLoading: isLoadingPlaceBid } = useMutation(
    (data: any) =>
      TenderService.placeBid(
        data.payload,
        data.id,
        showAllDealerships ? user?.data.dealership?.id : dealershipID
      ),
    {
      onSuccess: () => {
        setIsBidSuccessOpen(true);
      },
      onError: (error: { data: Error }) => {
        showErrorToast(error.data.message || "Failed to save");
      },
    }
  );

  const { mutate: sell, isLoading: isSellLoading } = useMutation(
    (updatedPrice: number) =>
      TenderService.bidWinner(highestBid?.id || "", updatedPrice),
    {
      onSuccess: () => {
        setOpenSellConfirmModal(false);
        showSuccessToast("Sold Successfully");
        onRefresh();
      },
    }
  );

  const {
    images,
    acquisitions,
    vehicle: {
      name,
      odometer,
      description,
      compliance: { vin },
      specification: { transmission, drive, engine, doors, body_type },
    },
    status,
  } = data;

  const onOpenRemoveModal = () => setIsRemoveModalOpen(true);

  const onCloseRemoveModal = () => setIsRemoveModalOpen(false);

  const onToggleFavorite = () => {
    toggleFavorite({ dealership_ids: [tenderDealershipId] });
  };

  const onChangeBidAmount = (e: ChangeEvent<HTMLInputElement>) => {
    //remove all characters except numbers, dot and comma
    const bidValueWithFormat = e.target.value.replace(/[^0-9.,]/g, "");

    // remove comma so we can process the max tender bid
    const bidAmount = e.target.value.replace(/[^0-9.]/g, "");
    setCurrentBid(bidAmount);
    if (onChangeBid && +bidAmount <= MAX_TENDER_BID) {
      onChangeBid({
        amount: bidValueWithFormat,
        tenderVehicleId: tenderVehicleId || "",
      });
    }
  };

  const handleCloseSellModal = () => setOpenSellConfirmModal(false);

  const onPlaceBid = () => {
    if (!editedBid?.amount || convertToNumber(editedBid?.amount) === 0) return;

    placeBid({
      id: tenderVehicleId,
      payload: {
        amount: convertToNumber(
          isGlobalSearch ? currentBid : editedBid?.amount
        ),
      },
    });
    setCurrentBid("");
  };

  const getBidAmount = () => {
    if (from === TENDER_FLOW_TYPE.SELLING)
      return status?.name === SOLDTENDERED.key
        ? acquisitions?.sale?.amount || highestBid?.amount
        : "";
    else {
      if (status?.name === WON.key && bidSale) {
        return bidSale || "";
      }
      return bidAmount;
    }
  };

  const highestBid = getHighestBid(bids?.data);
  const isBiddingActionsDisabled = isGlobalSearch
    ? tenderStatus === PENDING.key
    : tenderStatus !== ACTIVE.key || isLoadingPlaceBid || hasWidthdrawn;

  const handleKeypress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onPlaceBid();
    }
  };

  const handleSellVehicleModal = () => setOpenSellConfirmModal(true);

  return (
    <Card onClick={hasWidthdrawn ? () => {} : onClick}>
      <div
        className={classNames("card-vehicle tender-card-vehicle", {
          "!cursor-default": hasWidthdrawn,
        })}
      >
        <div className="card-vehicle__img">
          {!images || images.length === 0 ? (
            <SvgImage name="NoImageSvg" />
          ) : (
            <img
              src={images[0].thumb}
              alt="Vehicle"
              className={classNames({
                "opacity-50": hasWidthdrawn,
              })}
            />
          )}
          <div className="card-vehicle__status">
            <ButtonStatus
              status={status?.name || ""}
              amount={getBidAmount()}
              label={status?.label || ""}
            />
          </div>
        </div>
        <div className="card-vehicle__info !flex-col">
          <div
            className={classNames(
              "flex flex-row justify-between items-center",
              { "p-3": from === TENDER_FLOW_TYPE.SELLING }
            )}
          >
            <div
              className={classNames("card-vehicle__txt--title", {
                "card-vehicle__section": from === TENDER_FLOW_TYPE.BUYING,
              })}
            >
              <Tooltip
                className={`card-vehicle__txt--title${
                  from === TENDER_FLOW_TYPE.SELLING ? "--seller" : "--buyer"
                }`}
                tooltip={name}
              >
                <HighlightText text={name} highlight={searchText} />
              </Tooltip>
            </div>
            <div
              className={classNames({
                "card-vehicle__section": from === TENDER_FLOW_TYPE.BUYING,
              })}
            >
              {from === TENDER_FLOW_TYPE.BUYING && (
                <div className="flex items-center gap-3 xl:gap-6">
                  <ButtonLink
                    onClick={onToggleFavorite}
                    disabled={hasWidthdrawn || !canView}
                  >
                    <div className="flex items-center gap-2">
                      {isSaved ? <StarIcon /> : <StarOutlineIcon />}
                      {isSaved ? "Saved" : "Save"}
                    </div>
                  </ButtonLink>
                  <ButtonLink
                    onClick={() => setIsNotesModalOpen(true)}
                    disabled={hasWidthdrawn || !canView}
                  >
                    <div className="flex items-center gap-2 whitespace-nowrap">
                      <EyeIcon />
                      {notes?.data && notes.data.length > 0
                        ? "View Notes"
                        : "Add Notes"}
                    </div>
                  </ButtonLink>
                  {canBid && (
                    <div
                      className="relative w-160"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <p className="absolute left-2 top-2.5 font-normal text-txt cursor-default">
                        $
                      </p>
                      <FieldText
                        max={MAX_TENDER_BID}
                        disabled={isBiddingActionsDisabled || isEnded}
                        value={formatNumberWithDecimal(
                          isGlobalSearch ? currentBid : editedBid?.amount
                        )}
                        onChange={onChangeBidAmount}
                        className="shadow-md-inset h-10 outline-none pl-7 rounded-4"
                        placeholder="Enter Bid"
                        onKeyPress={handleKeypress}
                      />
                      <button
                        onClick={onPlaceBid}
                        disabled={isBiddingActionsDisabled || isEnded}
                        className={`h-full absolute top-0 right-0 rounded-4 flex justify-center items-center p-2 shadow ${
                          !isBiddingActionsDisabled && !isEnded
                            ? "shadow-secondary bg-secondary"
                            : "bg-label cursor-not-allowed"
                        }`}
                      >
                        <SendIcon />
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {from === TENDER_FLOW_TYPE.SELLING ? (
            <div className="card-vehicle__info tender-card-vehicle__info">
              <div className="card-vehicle__section">
                <Odometer value={odometer} />
                <span className="card-vehicle__txt--desc">{description}</span>
              </div>
              <div className="card-vehicle__section relative">
                <CardInfoChip
                  label="Acquisition Price"
                  value={
                    acquisitions?.acquisition?.amount
                      ? `$${formatNumber(acquisitions?.acquisition?.amount)}`
                      : "-"
                  }
                />
                <CardInfoChip
                  label="Highest Bid"
                  value={
                    highestBid?.amount
                      ? `$${formatNumber(highestBid.amount)}`
                      : "-"
                  }
                />
                <CardInfoChip
                  label="Bidder"
                  value={highestBid?.dealership.name ?? "-"}
                />
                <div className="absolute top-5 right-10 flex flex-col items-end">
                  {status?.name !== SOLDTENDERED.key &&
                    !hasWidthdrawn &&
                    hasPermission(
                      PermissionCategory.TENDER_SELLING,
                      PermissionAction.UPDATE
                    ) && (
                      <div className="mr-2">
                        <ButtonLink
                          onClick={onOpenRemoveModal}
                          disabled={isRemoving}
                        >
                          <div className="text-redVibrant underline">
                            Remove
                          </div>
                        </ButtonLink>
                      </div>
                    )}
                  {tenderStatus === CLOSED.key &&
                    status?.name !== SOLDTENDERED.key &&
                    !hasWidthdrawn &&
                    !!highestBid &&
                    hasPermission(
                      PermissionCategory.TENDER_SELLING,
                      PermissionAction.SELL
                    ) && (
                      <div className="sell-button">
                        <Button onClick={handleSellVehicleModal}>Sell</Button>
                      </div>
                    )}
                </div>
              </div>
            </div>
          ) : (
            <div className="card-vehicle__info">
              <div className="card-vehicle__section">
                <CardInfoChip label="KM" value={formatNumber(odometer)} />
                <CardInfoChip label="Transmission" value={transmission} />
                <CardInfoChip label="Drive Type" value={drive} />
              </div>
              <div className="card-vehicle__section relative">
                <CardInfoChip label="Engine Type" value={engine} />
                <CardInfoChip label="Doors" value={doors} />
                <CardInfoChip label="Body Type" value={body_type} />
              </div>
            </div>
          )}
        </div>
      </div>
      <RemoveTenderVehicleModal
        open={isRemoveModalOpen}
        onConfirm={() => {
          removeVehicle({ contract_ids: [data.contract_id] });
        }}
        onCancel={onCloseRemoveModal}
      />
      <Modal
        open={openSellConfirmModal}
        closeModal={handleCloseSellModal}
        size="auto"
      >
        <ModalConfirmTenderSale
          title="Confirm Sale?"
          onCancel={handleCloseSellModal}
          onConfirm={(updatedPrice) => sell(updatedPrice)}
          currentSalePrice={highestBid?.amount || 0}
          soldTo={highestBid?.dealership?.name || ""}
          isLoading={isSellLoading}
        />
      </Modal>
      <Modal
        open={isNotesModalOpen}
        closeModal={() => setIsNotesModalOpen(false)}
        size="auto"
      >
        <ModalAddNotes
          notes={notes?.data || []}
          onRefresh={refetchNotes}
          tenderVehicleId={tenderVehicleId}
        />
      </Modal>
      <Modal
        open={isBidSuccessOpen}
        closeModal={() => {
          setIsBidSuccessOpen(false);
          onRefresh();
        }}
        size="auto"
      >
        <InformativeModal
          content={{
            title: ActionResultMessage[ActionResult.BidPlacedWithSuccess].title,
            message:
              ActionResultMessage[ActionResult.BidPlacedWithSuccess].message,
            icon: "SuccessIcon",
          }}
        />
      </Modal>
    </Card>
  );
};
