import classNames from "classnames";
import { useFormContext } from "react-hook-form";

import { FieldValuation } from "components/app";
import { Button } from "components/shared";

import "./styles.scss";
import { VERIFICATION_APPROVED, VERIFICATION_DECLINED } from "utils";

type ValuationStatusProps = {
  value?: string;
  isReadOnly?: boolean;
  status?: string;
  onChange: (value: string) => void;
};

export const ValuationStatus = ({
  value,
  isReadOnly,
  status,
  onChange,
}: ValuationStatusProps) => {
  return (
    <FieldValuation
      title="Verification"
      value=""
      className="!items-center"
      tool={
        <div className="flex gap-2">
          {!(isReadOnly && status !== VERIFICATION_DECLINED.key) && (
            <Button
              className={classNames(
                "px-2 !py-[3px] !rounded-[5px] border !border-black",
                {
                  "secondary-button--disabled !border-dustblue":
                    !!value && value !== "declined",
                }
              )}
              variant="secondary"
              onClick={() => onChange("declined")}
            >
              Declined
            </Button>
          )}
          {!(isReadOnly && status !== VERIFICATION_APPROVED.key) && (
            <Button
              className={classNames(
                "px-2 !py-[3px] !rounded-[5px] border !border-black",
                {
                  "secondary-button--disabled !border-dustblue":
                    !!value && value !== "approved",
                }
              )}
              variant="secondary"
              onClick={() => onChange("approved")}
            >
              Approved
            </Button>
          )}
        </div>
      }
    />
  );
};
