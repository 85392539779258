import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  ChangeEventHandler,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import { NoPermissionMessage } from "components/NoPermissionMessage";
import { Button, Modal, Pagination } from "components/shared";

import { AuthContext } from "context/AuthContext";
import { useDealership } from "context/DealershipContext";

import {
  ACTIVE,
  ALL,
  BUYING,
  CLOSED,
  convertToNumber,
  DEFAULT_ITEMS_PER_PAGE,
  formatNumber,
  SAVED,
  SELLING,
  SOLD,
  SOLD_VEHICLE_FILTER,
  TENDER_FLOW_TYPE,
  TENDER_VEHICLE_FILTER,
} from "utils";
import { PermissionAction, PermissionCategory } from "utils/userPermissions";

import { IBidsWithDecimalAndComma, ITender, ITenderVehicle } from "types";

import { TenderService } from "api/client";

import { useSessionStorage } from "hooks";

import { CardTenderVehicle } from "../Card";
import { SearchBar } from "../Field";
import { LoadVehicle } from "../LoadVehicle";
import { ModalTenderAddVehicles } from "../Modal";
import { StatusBarTabs } from "../StatusBarTabs";

interface TenderVehicleSectionProps {
  data: ITender;
  from?: TENDER_FLOW_TYPE;
  canView?: boolean;
  canBid?: boolean;
}

export const TenderVehicleSection = ({
  data,
  from = TENDER_FLOW_TYPE.SELLING,
  canView = true,
  canBid = false,
}: TenderVehicleSectionProps) => {
  const queryClient = useQueryClient();
  const { dealershipID } = useDealership();
  const { userID, hasPermission } = useContext(AuthContext);
  const navigate = useNavigate();
  const [searchTxt, setSearchTxt] = useState("");
  const [openAddVehicleModal, setOpenAddVehicleModal] = useState(false);
  const [currentStatus, setCurrentStatus] = useState<string | number>(ALL.key);
  const [editedBids, setEditedBids] = useState<IBidsWithDecimalAndComma[]>([]);
  const [soldVehicleFilter, setSoldVehicleFilter] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const [currentPage, setCurrentPage] = useSessionStorage(
    "TENDER_SELLING_VEHICLE_LIST",
    1
  );

  const {
    tender_id: tenderId,
    status,
    dealership: { id: tenderDealershipId },
  } = data;

  const {
    data: vehiclesData,
    refetch: refetchVehicles,
    isFetching,
  } = useQuery(
    [
      `tender_vehicles_${tenderId}_${searchTxt}_${userID}`,
      tenderId,
      from === TENDER_FLOW_TYPE.SELLING ? SELLING.name : BUYING.name,
      {
        search: searchTxt,
        with: "owner,seller,dealership",
        page: currentPage,
        dealership_id: dealershipID,
      },
    ],
    TenderService.getVehicleTender,
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
    }
  );

  useEffect(() => {
    const total = vehiclesData?.meta?.total ?? 0;
    const perPage = vehiclesData?.meta?.per_page ?? DEFAULT_ITEMS_PER_PAGE;

    if (total && perPage) {
      const toRevertToPage1 = currentPage > Math.ceil(total / perPage);
      if (toRevertToPage1) {
        setCurrentPage(1);
      }
    }
  }, [vehiclesData, currentPage, setCurrentPage]);

  const totalBidAmount = useMemo(() => {
    if (vehiclesData) {
      let sum = 0;
      vehiclesData.data.forEach((v) => {
        const selectedBid = editedBids.find(
          (b) => b.tenderVehicleId === v.tender_vehicle_id
        );
        if (selectedBid) {
          sum += Number(convertToNumber(selectedBid.amount));
        } else if (v.bid && !v.has_withdrawn) {
          sum += v.bid.amount;
        }
      });
      return sum;
    }
    return 0;
  }, [editedBids, vehiclesData]);

  const filteredVehiclesData = useMemo(() => {
    if (from === TENDER_FLOW_TYPE.SELLING) {
      if (soldVehicleFilter) {
        setIsFilterApplied(true);
        return vehiclesData?.data?.filter(({ status }) =>
          status?.name?.toLowerCase()?.includes("sold")
        );
      } else {
        setIsFilterApplied(false);
        return vehiclesData?.data;
      }
    } else {
      let newVehiclesData = vehiclesData?.data;
      if (status === CLOSED.key) {
        setIsFilterApplied(true);
        newVehiclesData = newVehiclesData?.filter(({ bid }) => bid?.amount);
      }
      if (currentStatus === SAVED.key) {
        setIsFilterApplied(true);
        return newVehiclesData?.filter(({ is_saved }) => is_saved);
      } else {
        if (status !== CLOSED.key) {
          setIsFilterApplied(false);
        }
        return newVehiclesData;
      }
    }
  }, [from, vehiclesData, soldVehicleFilter, currentStatus, status]);

  const refetchAddVehicles = () => {
    queryClient.invalidateQueries([`fetchAcquiredVehicles`]);
  };

  const handleFetchNext = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleFetchPrev = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleFetchSelected = (index: number) => {
    setCurrentPage(index);
  };

  const handleOpenAddVehicleModal = () => setOpenAddVehicleModal(true);

  const handleCloseAddVehicleModal = () => setOpenAddVehicleModal(false);

  const handleSearch: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchTxt(e.target.value);
  };

  const handleChangeFilterVehicle = (status: number | string) => {
    setCurrentStatus(status);
  };

  const handleChangeBids = (val: IBidsWithDecimalAndComma) => {
    if (
      editedBids.findIndex((b) => b.tenderVehicleId === val.tenderVehicleId) >
      -1
    ) {
      setEditedBids([
        ...editedBids.filter((b) => b.tenderVehicleId !== val.tenderVehicleId),
        val,
      ]);
    } else {
      setEditedBids([...editedBids, val]);
    }
  };

  const handleGoVehicleSummary = (vehicleId: string | undefined) => {
    navigate(
      `/tender/${
        from === TENDER_FLOW_TYPE.SELLING ? "selling" : "buying"
      }/${tenderId}/${vehicleId}/summary`
    );
  };

  const sortVehicles = (data: ITenderVehicle[]): ITenderVehicle[] => {
    return data.sort((a, b) => {
      const dateA = new Date(
        a.acquisitions?.acquisition?.created_at ?? ""
      ).getTime();

      const dateB = new Date(
        b.acquisitions?.acquisition?.created_at ?? ""
      ).getTime();

      return dateA - dateB;
    });
  };

  return (
    <div className="flex flex-col w-full">
      <div className="w-full flex justify-between mt-15">
        <div className="flex flex-col gap-2.5">
          <p className="text-2xl text-txt font-semibold">Vehicles</p>
          {canView && (
            <Pagination
              currentPage={currentPage}
              pageLimit={vehiclesData?.meta.per_page || DEFAULT_ITEMS_PER_PAGE}
              total={
                isFilterApplied
                  ? filteredVehiclesData?.length ?? 0
                  : vehiclesData?.meta.total ?? 0
              }
              onNextPage={handleFetchNext}
              onPrevPage={handleFetchPrev}
              onIndexedPage={handleFetchSelected}
            />
          )}
        </div>
        <div className="flex items-start gap-11">
          {from === TENDER_FLOW_TYPE.SELLING && status === CLOSED.key && (
            <div className="pt-1 fixed__status__width">
              <StatusBarTabs
                currentStatus={soldVehicleFilter ? SOLD.key : ALL.key}
                options={SOLD_VEHICLE_FILTER}
                onSelect={(status) => setSoldVehicleFilter(status === SOLD.key)}
              />
            </div>
          )}
          {from === TENDER_FLOW_TYPE.SELLING &&
            status !== CLOSED.key &&
            hasPermission(
              PermissionCategory.TENDER_SELLING,
              PermissionAction.UPDATE
            ) && (
              <div className="w-185 font-bold">
                <Button onClick={handleOpenAddVehicleModal}>
                  ADD VEHICLES
                </Button>
              </div>
            )}
          {from === TENDER_FLOW_TYPE.BUYING && (
            <div className="pt-1 fixed__status__width">
              <StatusBarTabs
                currentStatus={currentStatus}
                options={TENDER_VEHICLE_FILTER}
                onSelect={handleChangeFilterVehicle}
              />
            </div>
          )}
          <div className="relative w-400 flex flex-col gap-2">
            <SearchBar onSearch={handleSearch} disabled={!canView} />
            {from === TENDER_FLOW_TYPE.BUYING &&
              status === ACTIVE.key &&
              canView && (
                <p>
                  {`Total Of Bids: $${
                    totalBidAmount === 0 ? "-" : formatNumber(totalBidAmount)
                  }`}
                </p>
              )}
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col gap-7 py-7">
        {!canView ? (
          <NoPermissionMessage />
        ) : isFetching ? (
          <LoadVehicle />
        ) : filteredVehiclesData?.length ? (
          sortVehicles(filteredVehiclesData).map((vehicle) => (
            <CardTenderVehicle
              key={vehicle.contract_id}
              data={vehicle}
              from={from}
              tenderStatus={status}
              tenderId={tenderId}
              tenderDealershipId={tenderDealershipId}
              editedBid={editedBids.find(
                (bid) => bid.tenderVehicleId === vehicle.tender_vehicle_id
              )}
              onRefresh={() => {
                refetchVehicles();
                refetchAddVehicles();
                setEditedBids([]);
              }}
              onClick={() => handleGoVehicleSummary(vehicle.tender_vehicle_id)}
              onChangeBid={handleChangeBids}
              canView={canView}
              canBid={canBid}
            />
          ))
        ) : (
          <div className="dashboard__content--empty">
            No Vehicles Added Yet.
          </div>
        )}
      </div>
      <div className="py-6">
        {canView && (
          <Pagination
            currentPage={currentPage}
            pageLimit={vehiclesData?.meta.per_page || DEFAULT_ITEMS_PER_PAGE}
            total={
              isFilterApplied
                ? filteredVehiclesData?.length ?? 0
                : vehiclesData?.meta.total ?? 0
            }
            onNextPage={handleFetchNext}
            onPrevPage={handleFetchPrev}
            onIndexedPage={handleFetchSelected}
          />
        )}
      </div>
      <Modal closeModal={handleCloseAddVehicleModal} open={openAddVehicleModal}>
        <ModalTenderAddVehicles
          onAdded={() => {
            refetchAddVehicles();
            refetchVehicles();
            handleCloseAddVehicleModal();
          }}
          tenderId={tenderId}
          dealershipID={dealershipID || ""}
        />
      </Modal>
    </div>
  );
};
