import { CardInfoChip } from "components/app/Card/CardTenderVehicle/CardInfoChip";

import { formatNumber } from "utils";

type OdometerProps = {
  value?: string;
};

export const Odometer = ({ value }: OdometerProps) => {
  return <CardInfoChip label="Odometer" value={formatNumber(value)} />;
};
