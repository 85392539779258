import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect } from "react";

import { LoadItem } from "components/app/LoadVehicle";
import { ValuationLog } from "components/app/Summary/ValuationLog";

import { AuthContext } from "context/AuthContext";

import { IValuationLogHistory } from "types";

import { ValuationService } from "api/client";

import "../styles.scss";

export interface ModalInternalValuationProps {
  open: boolean;
  valuation_id: string;
  dataTestID?: string;
}

export const ModalInternalValuation = ({
  open,
  valuation_id,
  dataTestID,
}: ModalInternalValuationProps) => {
  const { userID } = useContext(AuthContext);

  const {
    data: logs,
    refetch,
    isLoading,
  } = useQuery<any, Error>(
    [`fetchValuationLogs_${valuation_id}_${userID}`, valuation_id],
    ValuationService.getValuationHistory
  );

  useEffect(() => {
    if (open && valuation_id) {
      refetch();
    }
  }, [open, valuation_id, refetch]);

  if (isLoading)
    return (
      <div className="offer" data-testid={dataTestID}>
        <LoadItem />
      </div>
    );

  return (
    <div className="internal-valuation" data-testid={dataTestID}>
      {logs?.data
        ?.filter((log: IValuationLogHistory) => log.status === "valued")
        .map((log: IValuationLogHistory, idx: number) => (
          <ValuationLog
            key={idx}
            name={log.valuer.name}
            price={log.new_amount}
            role={log.valuer.role || ""}
            date={log.created_at}
          />
        ))}
    </div>
  );
};
