import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

import { LoadItem } from "components/app/LoadVehicle";
import { Modal, Pagination, SvgImage } from "components/shared";

import { ERROR_MESSAGE, formatNumber, StorageKeys } from "utils";

import {
  AcquisitionHistory,
  AcquisitionHistoryResponse,
} from "types/IAcquisition";

import { AcquisitionService } from "api/client";

export interface ModalAcquisitionHistoryProps {
  acquisitionID: string;
}

export const ModalAcquisitionHistory = ({
  acquisitionID,
}: ModalAcquisitionHistoryProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data, isLoading, isError, isSuccess } = useQuery<
    AcquisitionHistoryResponse,
    Error
  >(
    [
      `${StorageKeys.ACQUISITION_HISTORY}-${acquisitionID}`,
      acquisitionID,
      currentPage,
    ],
    AcquisitionService.acquisitionPriceHistory,
    {
      enabled: !!acquisitionID && isModalOpen,
    }
  );

  const onNextPage = () => {
    setCurrentPage((current) => current + 1);
  };

  const onPrevPage = () => {
    setCurrentPage((current) => current - 1);
  };

  const onIndexedPage = (pageIndex: number) => {
    setCurrentPage(pageIndex);
  };

  const renderItems = () => {
    if (isLoading) {
      return <LoadItem />;
    }

    if (isError) {
      return <div className="text-sm text-center">{ERROR_MESSAGE}</div>;
    }

    if (isSuccess) {
      return (
        <>
          {data?.data?.map((item: AcquisitionHistory) => {
            return (
              <div
                key={item.acquisition_amount_id}
                className="pt-4 pb-3 border-b border-softPeach last:border-none"
              >
                <div className="flex items-center justify-between text-black text-sm font-medium mb-1">
                  {item.updated_by?.name}
                  <div>{`$${formatNumber(item.new_amount)}`}</div>
                </div>
                <div className="flex items-start justify-between text-lightGrey text-xs font-normal mb-1">
                  <div>{item.updated_by?.phone}</div>
                  <div className="min-w-[160px] text-right">
                    {item.created_at}
                  </div>
                </div>
              </div>
            );
          })}
        </>
      );
    }

    return null;
  };

  return (
    <>
      <span
        className="hover:cursor-pointer"
        onClick={() => setIsModalOpen(true)}
      >
        <SvgImage name="EyeIcon" width={20} height={14} />
      </span>
      <Modal
        open={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        title="Acquisition Price History"
        size="auto"
      >
        <div className="w-[310px] -mx-1 md:-mx-6 mt-2 max-h-[500px] overflow-y-auto mb-5 pr-2">
          {renderItems()}
        </div>
        <Pagination
          currentPage={currentPage}
          pageLimit={data?.meta.per_page || 15}
          total={data?.meta.total || 0}
          onNextPage={onNextPage}
          onPrevPage={onPrevPage}
          onIndexedPage={onIndexedPage}
          title="Logs"
        />
      </Modal>
    </>
  );
};
