import { useMutation } from "@tanstack/react-query";
import { PPSRStatus } from "enums/PPSRStatus";

import { LoadingSpinner } from "components/Loading/LoadingSpinner";
import { SvgImage } from "components/shared";

import { showErrorToast, showSuccessToast } from "utils";

import { PPSRCheckInfo } from "types";

import { PPSRService } from "api/client";

type PPSRRefreshProps = {
  searchID: string;
  onRefresh?: () => void;
};

export const PPSRRefresh = ({ searchID, onRefresh }: PPSRRefreshProps) => {
  const { mutate, isLoading } = useMutation(
    () => PPSRService.refresh(searchID),
    {
      onSuccess: (data: PPSRCheckInfo) => {
        showSuccessToast(
          data?.api_status === PPSRStatus.Changed
            ? "Saved Successfully"
            : "No Changes Recorded"
        );
        onRefresh?.();
      },
      onError: () => {
        showErrorToast("Something went wrong. Try Again");
      },
    }
  );

  if (isLoading) {
    return (
      <span className="h-3 absolute right-0">
        <LoadingSpinner size={10} />
      </span>
    );
  }

  return (
    <span onClick={() => mutate()}>
      <SvgImage name="RefreshIcon" />
    </span>
  );
};
