import { ButtonStatus } from "components/app";

import { DECLINED_VALUATION, formatNumber } from "utils";

type ValuationLogProps = {
  name: string;
  role?: string;
  price: string;
  date: string;
  status?: "declined" | "valued";
};

export const ValuationLog = ({
  name,
  price,
  role,
  date,
  status,
}: ValuationLogProps) => {
  return (
    <div className="valuation-log">
      <div className="valuation-log__info">
        {name}
        {status === "declined" ? (
          <ButtonStatus status={DECLINED_VALUATION.key} label={"Declined"} />
        ) : (
          <span>{`$${formatNumber(price)}`}</span>
        )}
      </div>
      <div className="valuation-log__detail">
        <span>{role}</span>
        <span>{date}</span>
      </div>
    </div>
  );
};
