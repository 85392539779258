export enum VEHICLE_SEARCH_OPTIONS {
  REGO = "REGO",
  VIN = "VIN",
  MANUAL_SEARCH = "MANUAL_SEARCH",
}

export enum MANUAL_SEARCH_STEPS {
  VEHICLE_SEARCH = "SEARCH",
  SELECT_VARIANT = "SELECT_VARIANT",
  CONFIRM_VARIANT = "CONFIRM_VARIANT",
}
