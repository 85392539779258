import { useEffect, useState } from "react";

import { CustomSelectDropdown } from "components/shared/Fields/Dropdown/CustomSelectDropdown";

import { useDealership } from "context/DealershipContext";

import { INSPECTION_DEALERSHIP, ALL_DEALERSHIPS } from "utils";

import { useLocalStorage } from "hooks";
import { useDealershipOptions } from "hooks/useDealershipOptions";

export const InspectionDealershipSelector = () => {
  const [localStorageDealershipID, setLocalStorageDealershipID] =
    useLocalStorage(INSPECTION_DEALERSHIP, null);

  const [selectedDealership, setSelectedDealership] = useState<string>(
    localStorageDealershipID
  );

  const { setInspectorDealershipID, isDealershipSelectorVisible } =
    useDealership();

  const { allOptions, options, prependOptions } = useDealershipOptions();
  const allOptionsCombined = [...allOptions]?.splice(1).join(",");

  useEffect(() => {
    if (isDealershipSelectorVisible && !selectedDealership) {
      setSelectedDealership(ALL_DEALERSHIPS);
      setInspectorDealershipID(allOptionsCombined);
    }
  }, []);

  useEffect(() => {
    if (selectedDealership === ALL_DEALERSHIPS) {
      setInspectorDealershipID(allOptionsCombined);
    } else {
      setInspectorDealershipID(selectedDealership);
    }
  }, [selectedDealership]);

  const onChange = (selectedKey: string) => {
    const isAllSelected = selectedKey === ALL_DEALERSHIPS;
    setLocalStorageDealershipID(selectedKey);

    if (isAllSelected) {
      setSelectedDealership(selectedKey);
      setInspectorDealershipID(allOptionsCombined);
    } else {
      setSelectedDealership(selectedKey);
      setInspectorDealershipID(selectedKey);
    }
  };

  if (!isDealershipSelectorVisible) return null;

  return (
    <>
      <p className="mb-3 text-sm">You are inspecting a vehicle for</p>
      <div>
        <CustomSelectDropdown
          value={selectedDealership || ""}
          onChange={onChange}
          placeholder="Select dealership"
          options={options}
          prependOptions={prependOptions}
          className="text-left !bg-transparent"
          dropdownGreyBG
          showBorder
        />
      </div>
    </>
  );
};
