import { ACQUIRED } from "utils";

import { ModalAcquisitionHistory } from "../Modal/ModalAcquisitionHistory";
import { ModalEditAcquisitionPrice } from "../Modal/ModalEditAcquisitionPrice";

type EditAcquisitionPriceProps = {
  contractID: string;
  acquisitionID: string;
  acquisitionPrice?: number;
  onRefresh?: () => void;
  isEditable: boolean;
};

export const EditAcquisitionPrice = ({
  contractID,
  acquisitionPrice,
  acquisitionID,
  onRefresh,
  isEditable,
}: EditAcquisitionPriceProps) => {
  return (
    <div className="flex justify-between">
      <div className="flex items-baseline justify-between text-sm text-darktxt ">
        <div className="flex gap-2 items-center">
          <label className="text-sm inline-flex whitespace-nowrap items-center font-semibold">
            Acquisition Price
          </label>
          {acquisitionID && (
            <ModalAcquisitionHistory acquisitionID={acquisitionID} />
          )}
        </div>
      </div>
      {acquisitionID ? (
        <ModalEditAcquisitionPrice
          contractID={contractID}
          currentPrice={acquisitionPrice}
          onRefresh={onRefresh}
          acquisitionID={acquisitionID}
          isEditable={isEditable}
        />
      ) : (
        "-"
      )}
    </div>
  );
};
