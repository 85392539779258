import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { formRetailSalesTestID } from "components/app/dataTestIDs";
import {
  Button,
  ButtonLink,
  FieldRadio,
  FieldText,
  FieldTextarea,
  Modal,
  SvgImage,
  TextError,
} from "components/shared";
import { FieldPhone } from "components/shared/Fields/Text/FieldPhone";

import {
  FeatureRoute,
  checkIsValidNumber,
  formatNumber,
  formatPhoneNumber,
} from "utils";
import { pattern } from "utils/validation";

import { AcquisitionService } from "api/client";

import { SOLD_OPTIONS } from ".";
import { ConfirmSale } from "./ConfirmSale";

export interface FormRetailSalesProps {
  soldOption: string;
  contract_id: string;
  onRefresh: () => void;
  onSoldTo: (val: string) => void;
  dataTestID?: string;
}

interface SaleDetailsFormType {
  sale_price: string;
  retailer_name: string;
  email: string;
  phone: string;
  address?: string;
  comment?: string;
}

export const FormRetailSales = ({
  soldOption,
  contract_id,
  onSoldTo,
  dataTestID,
  onRefresh,
}: FormRetailSalesProps) => {
  const navigate = useNavigate();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [salePayload, setSalePayload] = useState<any>();
  const [isNumber, setIsNumber] = useState(true);

  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<SaleDetailsFormType>({
    defaultValues: {
      sale_price: "",
      retailer_name: "",
      email: "",
      phone: "",
      address: "",
      comment: "",
    },
  });

  const { mutate, isLoading } = useMutation(
    (data: any) =>
      AcquisitionService.sellTo(contract_id, SOLD_OPTIONS[0].value, data),
    {
      onSuccess: () => {
        toast.success("Sold successfully", {
          autoClose: 2000,
          theme: "colored",
        });
        onRefresh?.();
      },
      onError: () => {
        toast.error("Failed to Sell", {
          autoClose: 2000,
          theme: "colored",
        });
      },
    }
  );

  const handleSalePrice = (value: string) => {
    setValue("sale_price", formatNumber(value.replaceAll(",", "")));
    setIsNumber(checkIsValidNumber(getValues("sale_price")));
    clearErrors("sale_price");
  };

  const handleConfirm = (data: SaleDetailsFormType) => {
    if (isNumber) {
      setSalePayload({
        ...data,
        sale_price: data.sale_price.replaceAll(",", ""),
      });
      setOpenConfirmModal(true);
    }
  };

  const handleSell = () => {
    mutate(salePayload);
  };

  return (
    <div
      className="sale-details"
      data-testid={dataTestID || formRetailSalesTestID}
    >
      <div className="absolute top-11">
        <ButtonLink onClick={() => onSoldTo(SOLD_OPTIONS[2].value)}>
          <SvgImage name="LeftRoundedIcon" />
        </ButtonLink>
      </div>
      <div className="sale-details__row">
        <div className="flex flex-col gap-3">
          <label className="sale-details__title">Sell To:</label>
          <FieldRadio
            name={SOLD_OPTIONS[0].value}
            label={SOLD_OPTIONS[0].label}
            checked={soldOption === SOLD_OPTIONS[0].value}
            onChange={() => onSoldTo(SOLD_OPTIONS[0].value)}
          />
        </div>
        <div>
          <FieldText
            title="Sale Price*"
            type="text"
            {...register("sale_price", { required: true })}
            placeholder="Enter Sale Price"
            onChange={(e) => handleSalePrice(e.target.value)}
          />
          <div>
            {errors.sale_price && <TextError error="Sale Price is required" />}
          </div>
          <div>
            {!isNumber && <TextError error="Please enter numbers only" />}
          </div>
        </div>
        <div>
          <FieldText
            title="Buyer Name*"
            type="text"
            {...register("retailer_name", { required: true })}
            placeholder="Enter Buyer Name"
          />
          {errors.retailer_name && <TextError error="Buyer Name is required" />}
        </div>

        <div>
          <FieldText
            title="Address"
            type="text"
            {...register("address", { required: false })}
            placeholder="Enter Address"
          />
        </div>

        <div>
          <FieldPhone register={register} setValue={setValue} name="phone" />
          {errors.phone && (
            <TextError
              error={
                getValues("phone")
                  ? "Please enter the correct phone number"
                  : "Phone Number is required"
              }
            />
          )}
        </div>

        <div>
          <FieldText
            title="Email Address*"
            type="text"
            {...register("email", {
              required: true,
              pattern: pattern.email,
            })}
            placeholder="Enter their email address"
          />
          {errors.email && <TextError error="Email Address is required" />}
        </div>

        <FieldTextarea
          title="Additional Information"
          {...register("comment")}
          placeholder="Enter Extra Info (ABN, etc)"
        />
      </div>
      <div className="sale-details__footer">
        <Button
          type="submit"
          isLoading={isLoading}
          onClick={handleSubmit(handleConfirm)}
          disabled={!isNumber}
        >
          <span>NEXT</span>
        </Button>
      </div>
      <Modal
        open={openConfirmModal}
        size="auto"
        closeModal={() => setOpenConfirmModal(false)}
      >
        <ConfirmSale
          onClose={() => setOpenConfirmModal(false)}
          onConfirm={handleSell}
        />
      </Modal>
    </div>
  );
};
