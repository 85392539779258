import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";

import {
  Button,
  FieldText,
  Modal,
  SvgImage,
  TextError,
} from "components/shared";

import {
  convertToNumber,
  formatNumber,
  showErrorToast,
  showSuccessToast,
  StorageKeys,
} from "utils";

import { AcquisitionService } from "api/client";

type ModalEditAcquisitionPriceProps = {
  contractID: string;
  currentPrice?: number;
  onRefresh?: () => void;
  acquisitionID: string;
  isEditable: boolean;
};

export const ModalEditAcquisitionPrice = ({
  contractID,
  currentPrice,
  onRefresh,
  acquisitionID,
  isEditable,
}: ModalEditAcquisitionPriceProps) => {
  const queryClient = useQueryClient();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<{ amount: string }>({
    defaultValues: {
      amount: "",
    },
  });

  const amount = watch("amount");

  useEffect(() => {
    if (isModalOpen) {
      inputRef.current?.focus();
    }
  }, [isModalOpen]);

  const { mutate: sendOffer, isLoading } = useMutation(
    async () =>
      AcquisitionService.updateAcquisitionPrice(contractID, {
        amount: amount ? +convertToNumber(amount) : 0,
      }),
    {
      onSuccess: () => {
        showSuccessToast("Saved Successfully");
        onRefresh?.();
        setIsModalOpen(false);
        queryClient.invalidateQueries([
          `${StorageKeys.ACQUISITION_HISTORY}-${acquisitionID}`,
        ]);
      },
      onError: () => {
        showErrorToast("Failed to Save");
      },
    }
  );

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSubmit(() => sendOffer())();
    }
  };

  return (
    <>
      <div className="flex gap-2">
        <span className="font-medium text-sm">{`$${formatNumber(
          currentPrice
        )}`}</span>
        {isEditable && (
          <span
            className="hover:cursor-pointer"
            onClick={() => setIsModalOpen(true)}
          >
            <SvgImage
              name="EditPen"
              className="disclosure-header__img"
              width={14}
            />
          </span>
        )}
      </div>
      <Modal
        open={isModalOpen}
        size="auto"
        closeModal={() => setIsModalOpen(false)}
      >
        <div className="flex items-center flex-col text-txt font-semibold text-xl mb-9 w-full">
          Edit Acquisition Price
        </div>

        <div className="relative">
          <FieldText
            type="text"
            placeholder="Enter offer amount"
            onKeyDown={onKeyDown}
            {...register("amount", {
              required: "Amount is required",
              onChange: (e) =>
                setValue("amount", e.target.value.replaceAll(",", ""), {
                  shouldValidate: true,
                }),
            })}
            value={formatNumber(amount)}
            ref={inputRef}
          />
          {errors.amount && <TextError error={errors?.amount?.message || ""} />}
          <span className="absolute left-1 top-2">$</span>
        </div>

        <div className="mt-9 w-[162px] mx-auto">
          <Button
            type="button"
            isLoading={isLoading}
            onClick={handleSubmit(() => sendOffer())}
          >
            <span className="uppercase font-bold">Confirm Amount</span>
          </Button>
        </div>
      </Modal>
    </>
  );
};
