type SubStatusBarTabsProps = {
  statusList: { key: string | number; label: string }[];
  isActive: boolean;
  currentStatus: string | number;
  handleChangeStatus: (status: string | number) => void;
};

export const SubStatusBarTabs = ({
  statusList,
  isActive,
  currentStatus,
  handleChangeStatus,
}: SubStatusBarTabsProps) => {
  if (!isActive) return null;

  return (
    <div className="dashboard__subtoolbar">
      {statusList.map((item) => (
        <button
          key={item.key}
          className={
            currentStatus === item.key ? `btn-subtool selected` : "btn-subtool"
          }
          onClick={() => handleChangeStatus(item.key)}
        >
          {item.label}
        </button>
      ))}
    </div>
  );
};
