import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

import { LoadItem } from "components/app/LoadVehicle";
import { Modal, Pagination, SvgImage } from "components/shared";

import { ERROR_MESSAGE, formatNumber, StorageKeys } from "utils";

import { ValuationHistory, ValuationHistoryResponse } from "types/Valuation";

import { ValuationService } from "api/client";

export interface ModalAcquisitionHistoryProps {
  historyID: string;
  isOpen: boolean;
  onClose: () => void;
  title: string;
}

export const ModalValuationHistory = ({
  historyID,
  isOpen,
  onClose,
  title,
}: ModalAcquisitionHistoryProps) => {
  const [currentPage, setCurrentPage] = useState(1);

  const { data, isLoading, isError, isSuccess } = useQuery<
    ValuationHistoryResponse,
    Error
  >(
    [`${StorageKeys.VALUATION_HISTORY}-${historyID}`, historyID, currentPage],
    ValuationService.getHistory,
    {
      enabled: !!historyID && isOpen,
    }
  );

  const onNextPage = () => {
    setCurrentPage((current) => current + 1);
  };

  const onPrevPage = () => {
    setCurrentPage((current) => current - 1);
  };

  const onIndexedPage = (pageIndex: number) => {
    setCurrentPage(pageIndex);
  };

  const renderItems = () => {
    if (isLoading) {
      return <LoadItem />;
    }

    if (isError) {
      return <div className="text-sm text-center">{ERROR_MESSAGE}</div>;
    }

    if (isSuccess) {
      return (
        <>
          {data?.data?.map((item: ValuationHistory) => {
            return (
              <div
                key={item.valuation_id}
                className="pt-4 pb-3 border-b border-softPeach last:border-none"
              >
                <div className="flex items-center justify-between text-black text-sm font-medium mb-1">
                  {item.valuer?.name}
                  <div>{`$${formatNumber(item.new_amount)}`}</div>
                </div>
                <div className="flex items-start justify-end text-lightGrey text-xs font-normal mb-1">
                  <div className="min-w-[160px] text-right">
                    {item.created_at}
                  </div>
                </div>
              </div>
            );
          })}
        </>
      );
    }

    return null;
  };

  return (
    <Modal open={isOpen} closeModal={onClose} title={title} size="auto">
      <div className="w-[310px] -mx-1 md:-mx-6 mt-2 max-h-[500px] overflow-y-auto mb-5 pr-2">
        {renderItems()}
      </div>
      <Pagination
        currentPage={currentPage}
        pageLimit={data?.meta.per_page || 15}
        total={data?.meta.total || 0}
        onNextPage={onNextPage}
        onPrevPage={onPrevPage}
        onIndexedPage={onIndexedPage}
        title="Logs"
      />
    </Modal>
  );
};
