export enum ContractType {
  Inspection = "inspection",
  Valuation = "valuation",
  Tender = "tender",
  Auction = "auction",
  DirectOffer = "direct_offer",
  Dealership = "dealership",
  Cancelled = "cancelled",
  Verification = "verification",
}
