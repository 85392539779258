import { useQuery } from "@tanstack/react-query";
import { PPSRStatus } from "enums/PPSRStatus";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";

import { LoadingSpinner } from "components/Loading/LoadingSpinner";
import { Pagination } from "components/shared/Pagination";

import { StorageKeys } from "utils";

import { PPSRService } from "api/client";

import { DownloadCertificate } from "./DownloadCertificate";

type PPSRHistoryProps = {
  contractID: string;
  isPolling?: boolean;
};

export const PPSRHistory = ({ contractID, isPolling }: PPSRHistoryProps) => {
  const [currentPage, setCurrentPage] = useState(1);

  const { data, refetch, isFetching } = useQuery(
    [`${StorageKeys.PPSRHistory}-${contractID}`],
    () => PPSRService.history(contractID, currentPage),
    {
      cacheTime: 0,
    }
  );

  useEffect(() => {
    refetch();
  }, [currentPage, refetch]);

  const onNextPage = () => {
    setCurrentPage((current) => current + 1);
  };

  const onPrevPage = () => {
    setCurrentPage((current) => current - 1);
  };

  const onIndexedPage = (pageIndex: number) => {
    setCurrentPage(pageIndex);
  };

  return (
    <div className="border-t pt-5 border-softPeach">
      <div className="font-semibold text-lightGrey text-base mb-5 relative">
        Past Checks
        {isFetching && !isPolling && (
          <div className="absolute left-44 top-0">
            <LoadingSpinner />
          </div>
        )}
      </div>
      <div>
        {data?.data?.map(
          ({
            id,
            report_date,
            status,
            certificate_number,
            certificate_status,
            api_status,
          }) => {
            return (
              <div
                key={id}
                className="grid grid-cols-[120px_80px_350px_auto] gap-4 max-w-[700px] text-sm mb-5"
              >
                <div>
                  {DateTime.fromJSDate(new Date(report_date)).toFormat(
                    "dd MMM yyyy"
                  )}
                </div>
                <div className="font-semibold">{status?.toUpperCase()}</div>
                <div>
                  <div>
                    <span className="font-semibold pr-5">
                      Certificate Number
                    </span>
                    {certificate_number}
                  </div>
                </div>
                <div>
                  <DownloadCertificate
                    ppsrID={id}
                    status={certificate_status}
                    disabled={api_status !== PPSRStatus.Completed}
                  />
                </div>
              </div>
            );
          }
        )}
      </div>
      <div className="mt-5">
        <Pagination
          title="PPSR"
          currentPage={currentPage}
          pageLimit={data?.meta?.per_page || 0}
          total={data?.meta?.total || 0}
          onNextPage={onNextPage}
          onPrevPage={onPrevPage}
          onIndexedPage={onIndexedPage}
          autoScrollTop={false}
        />
      </div>
    </div>
  );
};
