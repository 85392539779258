import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  Button,
  ButtonLink,
  SvgImage,
  FieldText,
  TextError,
} from "components/shared";
import { ContactSupportLink } from "components/Support/ContactSupportLink";

import { AuthContext } from "context/AuthContext";

import {
  checkIsValidNumber,
  CUSTOMER_INSPECTION_DATA,
  formatNumber,
  INSPECTION_STEP,
} from "utils";

import { CustomerInspectionService } from "api/client";

import { useLocalStorage } from "hooks";

export const EnterKMS = () => {
  const { userID } = useContext(AuthContext);

  const navigate = useNavigate();
  const params = useParams();
  const [isNumber, setIsNumber] = useState(true);
  const [, saveInspection] = useLocalStorage(CUSTOMER_INSPECTION_DATA, null);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      odometer: "",
    },
  });

  const { data: verifyData } = useQuery(
    [`verify_inspection_link_${userID}`, params.id],
    CustomerInspectionService.verifyInspectionLInk
  );

  const { data: { data } = {} } = useQuery(
    [`fetch_inspection_${params.id}_${userID}`, params.id],
    CustomerInspectionService.showInspection,
    { refetchOnWindowFocus: false }
  );
  const { mutate, isLoading } = useMutation(
    (payload: any) =>
      CustomerInspectionService.updateInspection(payload, params.id!),
    {
      onSuccess: () => {
        navigate("../specification");
        toast.success("Saved Successfully", {
          autoClose: 2000,
          theme: "colored",
        });
      },
      onError: () => {
        toast.error("Failed to Save", {
          theme: "colored",
          autoClose: 2000,
        });
      },
    }
  );

  useEffect(() => {
    if (verifyData) {
      const { status } = verifyData.data;

      if (status.name === INSPECTION_STEP.INSPECTION_SUBMITTED) {
        return navigate(`/inspection/customer/${params.id}/complete`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyData]);

  useEffect(() => {
    if (data) {
      saveInspection(data);
      setValue("odometer", data.vehicle?.odometer || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleGoBack = () => {
    navigate("../find-vehicle");
  };

  const handleNext = (data: any) => {
    const { odometer } = data;
    const payload = {
      type: "odometer",
      odometer: odometer.replaceAll(",", ""),
    };
    mutate(payload);
  };

  const handleKM = (value: string) => {
    setValue("odometer", formatNumber(value.replaceAll(",", "")));
    setIsNumber(checkIsValidNumber(getValues("odometer")));
  };

  return (
    <div className="flex flex-col justify-between text-primary w-full mb-5 h-full">
      <div className="flex-1 w-full flex flex-col overflow-auto px-5">
        <div className="text-black text-sm pt-5">
          <ButtonLink onClick={handleGoBack}>{"< Back"}</ButtonLink>
        </div>
        <h5 className="font-bold text-2xl mt-5 mb-5">Enter km travelled</h5>
        <div className="mx-auto mb-52">
          <SvgImage name="OdometerKmsIcon" />
        </div>
        <div className="relative">
          <FieldText
            title="Odometer reading"
            type="text"
            inputMode="numeric"
            {...register("odometer", { required: true })}
            placeholder="Enter distance"
            onChange={(e) => handleKM(e.target.value)}
          />
          <span className="absolute right-2.5 top-34">km</span>
          {errors.odometer?.type === "required" && (
            <TextError error="Please enter your Odometer Reading" />
          )}
          {isNumber === false && (
            <TextError error="Please enter numbers only" />
          )}
        </div>
        <p className="text-label text-sm mt-8">
          *If you are unsure of the exact vehicle distance travelled, a rough
          estimate of kms is accepted.
        </p>
      </div>
      <div className="py-3 px-8 flex flex-col gap-2 mt-auto">
        <Button
          type="submit"
          isLoading={isLoading}
          onClick={handleSubmit(handleNext)}
        >
          <span className="font-bold">Next</span>
        </Button>
      </div>
      <ContactSupportLink hasMPLogo />
    </div>
  );
};
