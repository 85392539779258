import { useQuery } from "@tanstack/react-query";
import { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useParams } from "react-router-dom";

import { Breadcrumbs } from "components/shared";
import { Tabs } from "components/Tabs";

import { AuthContext } from "context/AuthContext";

import { getPageTitle, SECTIONS } from "utils/pageSections";

import { ICustomerDetail } from "types";

import { CustomerService } from "api/client";

import { Comments } from "./Comments";
import { Details } from "./Details";
import { Documents } from "./Documents";
import { Vehicles } from "./Vehicles";

export const CustomerDetails = () => {
  const { userID } = useContext(AuthContext);

  const params = useParams();
  const { id, contract_id } = params;
  const { pathname } = useLocation();
  const [customerID, setCustomerID] = useState<string | undefined>(id);
  const isInRetail = pathname.includes("retail-dealership");

  const {
    data: customerData,
    isInitialLoading: isInitialLoadingCustomer,
    isError: isErrorCustomer,
  } = useQuery<{ data: ICustomerDetail }, Error>(
    [`fetch_customer_${customerID}_${userID}`, customerID],
    CustomerService.getDetails,
    {
      enabled: !!customerID,
    }
  );

  const customerTabs = [
    {
      id: 0,
      title: "DETAILS",
      content: (
        <Details
          id={customerID}
          contractID={contract_id}
          isLoading={isInitialLoadingCustomer}
          isError={isErrorCustomer && !!id}
          customerData={customerData?.data}
          onSave={(id) => {
            setCustomerID(id);
          }}
        />
      ),
    },
    {
      id: 1,
      title: "COMMENTS",
      content: <Comments id={customerID} customerData={customerData?.data} />,
      disabled: !customerID,
    },
    {
      id: 2,
      title: "DOCUMENTS",
      content: <Documents customerID={customerID} />,
      disabled: !customerID,
    },
    {
      id: 3,
      title: "VEHICLES",
      content: <Vehicles customerID={customerID} />,
      disabled: !customerID,
    },
  ];

  return (
    <>
      <Helmet>
        <title>{getPageTitle("Customers", SECTIONS.RETAIL_DEALERSHIP)}</title>
      </Helmet>
      <div className="flex flex-col max-w-1300 w-full">
        <div className="flex flex-col gap-5">
          <Breadcrumbs
            paths={
              isInRetail
                ? [
                    { title: "Retail Dealership", route: "/retail-dealership" },
                    {
                      title: "Customers",
                      route: "/retail-dealership/customers",
                    },
                    {
                      title: "Customer Details",
                      route: `/retail-dealership/customers/${id}/information`,
                    },
                  ]
                : [
                    {
                      title: "Wholesale Dealership",
                      route: "/wholesale-dealership",
                    },
                    {
                      title: "Clients",
                      route: "/wholesale-dealership/clients",
                    },
                    {
                      title: "Customer Details",
                      route: `/wholesale-dealership/clients/${id}/information`,
                    },
                  ]
            }
            showBackButton
          />
          <div className="text-2xl text-txt font-semibold">
            Customer Details
          </div>
        </div>
        <div className="flex w-full py-5 gap-10">
          <Tabs tabs={customerTabs} />
        </div>
      </div>
    </>
  );
};
