import { useMutation } from "@tanstack/react-query";
import FileSaver from "file-saver";

import { FieldDocument } from "components/app/Field/FieldDocument";
import { Item } from "components/PPSR/Item";
import { RedirectToGoogleMaps } from "components/RedirectToGoogleMaps";
import { SvgImage, TextError } from "components/shared";
import { Warning } from "components/Warning/Warning";

import { showErrorToast } from "utils";

import { ICustomerDetail, IVerification } from "types";

import { CustomerService } from "api/client";

import { FieldState } from "../Field";

type ReportedDetailsProps = {
  verificationData?: IVerification;
  owner?: ICustomerDetail;
};

export const ReportedDetails = ({
  verificationData,
  owner,
}: ReportedDetailsProps) => {
  const {
    vendor_data,
    documents,
    customer_id,
    vehicle_location,
    has_discrepancies,
  } = verificationData || {};

  const { mutate, isLoading } = useMutation(
    ({ documentID }: { documentID: string; name: string }) =>
      CustomerService.downloadCustomerDocument(customer_id || "", documentID),
    {
      onSuccess: (response, { name }) => {
        const url = URL.createObjectURL(new File([response], name));

        FileSaver.saveAs(url, name);

        URL.revokeObjectURL(url);
      },
      onError: () => {
        showErrorToast("Download Failed");
      },
    }
  );

  return (
    <div className="lg:grid-cols-2 grid grid-cols-1 md:grid-cols-2 gap-x-8 pb-5 mt-4">
      <div className="flex gap-y-6 flex-col">
        <FieldState name="Vehicle Year" value={vendor_data?.vehicle_year} />
        <FieldState name="Vehicle Make" value={vendor_data?.vehicle_make} />
        <FieldState name="Vehicle Model" value={vendor_data?.vehicle_model} />
        <FieldState name="VIN" value={vendor_data?.vehicle_vin} />
        <FieldState name="REGO" value={vendor_data?.vehicle_rego} />
        <FieldState name="Odometer" value={vendor_data?.vehicle_odometer} />
        <FieldDocument
          label="Documents"
          value={
            documents?.map(({ id, name }) => ({
              documentID: id,
              name,
            })) || []
          }
          onDownload={mutate}
          isLoading={isLoading}
        />
        <div>
          <Item
            title="Location"
            value={<RedirectToGoogleMaps location={vehicle_location} />}
            hideUnderline
          />
          {has_discrepancies && (
            <div className="flex gap-2">
              <SvgImage name="Danger" />
              <TextError error="The location has changed during inspection." />
            </div>
          )}
        </div>
      </div>
      <div className="flex gap-y-6 flex-col">
        <FieldState name="Customer Name" value={owner?.name} />
        <FieldState name="Last Name" value={owner?.last_name} />
        <FieldState name="Email" value={owner?.email} />
        <FieldState name="Mobile" value={owner?.phone} />
        <FieldState name="Address" value={owner?.address} />
        {/*
        TODO: Add this back when data is ready
        
        {has_discrepancies && (
          <div className="pr-10">
            <Warning
              iconName="Warning"
              title="Discrepancies Detected"
              description="We've identified differences between the information declared and the details submitted via the customer link."
            />
          </div>
        )} */}
      </div>
    </div>
  );
};
