import { useMutation } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";

import { FieldValuation } from "components/app";
import { ModalRetailAdd } from "components/app/Modal/Valuation/ModalRetailAdd";
import { ModalRetailAmountLog } from "components/app/Modal/Valuation/ModalRetailAmountLog";
import { ModalTradeAdd } from "components/app/Modal/Valuation/ModalTradeAdd";
import { ModalTradeAmountLog } from "components/app/Modal/Valuation/ModalTradeAmountLog";
import { ValuationStatus } from "components/app/Summary/ValuationStatus";
import { LinkButton } from "components/Button";
import { PricingInsightsModal } from "components/Modal/PricingInsightsModal";
import {
  Button,
  FieldCheckbox,
  FieldTextarea,
  TextError,
} from "components/shared";

import {
  formatNumber,
  showErrorToast,
  VERIFICATION_APPROVED,
  VERIFICATION_DECLINED,
} from "utils";
import { PPSR_CHANGED, PPSR_FAILED, PPSR_SUCCESS } from "utils/ppsrStatuses";

import { IVehicleSummary } from "types";
import { SubmitVerificationPayload } from "types/IVerification";

import { VerificationService } from "api/client/VerificationService";

import { ModalValuationHistory } from "../Modal/Valuation/ModalValuationHistory";
import { ResearchVehicle } from "./ResearchVehicle";
import { VerificationCertificate } from "./VerificationCertificate";

import "./styles.scss";

type VerificationValuationProps = {
  data: IVehicleSummary;
  canViewPricingInsights: boolean;
  onRefresh: () => void;
};

export const VerificationValuation = ({
  data,
  canViewPricingInsights,
}: VerificationValuationProps) => {
  const [showValidation, setShowValidation] = useState(false);
  const { valuations, verification, status, ppsr, vehicle } = data;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isStatusReadOnly = [
    VERIFICATION_APPROVED.key,
    VERIFICATION_DECLINED.key,
  ].includes(status.name || "");
  const isReadOnly = isStatusReadOnly && data?.is_locked;
  const tradeDetails = valuations?.verification?.trade;
  const retailDetails = valuations?.verification?.retail;
  const verificationWebhook = verification?.webhook_status;

  const defaultValues = useMemo(() => {
    return {
      valuation_trade: tradeDetails?.amount.toString() || undefined,
      valuation_retail: retailDetails?.amount.toString() || undefined,
      vin_rego_check: verification?.valuation?.vin_rego_check ? "1" : "0",
      verification_status: verification?.valuation?.status || undefined,
      verification_notes: vehicle?.remarks || undefined,
    };
  }, [data]);

  useEffect(() => {
    setIsSubmitting(false);
  }, [data]);

  const methods = useForm({
    defaultValues,
  });

  const { watch, setValue } = methods;

  const valuationTrade = watch("valuation_trade");
  const valuationRetail = watch("valuation_retail");

  const [modalStates, setModalStates] = useState({
    trade: false,
    retail: false,
    addTrade: false,
    addRetail: false,
    pricingInsights: false,
    historyID: "",
  });

  const ppsrData =
    ppsr?.status === PPSR_SUCCESS.name
      ? PPSR_SUCCESS?.label
      : ppsr?.status === PPSR_CHANGED.name
      ? PPSR_CHANGED?.label
      : ppsr?.status === PPSR_FAILED.name
      ? PPSR_FAILED?.label
      : "-";

  const { mutate, isLoading } = useMutation(
    (payload: SubmitVerificationPayload) =>
      VerificationService.submitVerification(payload, data.request_id || ""),
    {
      onSuccess: () => {
        setIsSubmitting(true);
      },
      onError: () => {
        showErrorToast("Failed to Submit");
      },
    }
  );

  const toggleModal = (
    modalName: keyof typeof modalStates,
    state: boolean | string
  ) => {
    setModalStates((prev) => ({ ...prev, [modalName]: state }));
  };

  const onSubmit = methods.handleSubmit(async (data) => {
    console.log(data.vin_rego_check);
    if (
      !ppsr?.status ||
      !valuationTrade ||
      !valuationRetail ||
      data.vin_rego_check === "0"
    ) {
      setShowValidation(true);
      return;
    }

    if (data.verification_status === "approved") {
      if (
        data.vin_rego_check !== "1" ||
        (!data.valuation_trade && !data.valuation_retail)
      ) {
        setShowValidation(true);
        return;
      }
    } else if (data.verification_status === "declined") {
      setShowValidation(false);
    } else {
      setShowValidation(true);
      return;
    }

    setShowValidation(false);
    mutate({
      ...data,
      valuation_trade: data.valuation_trade || undefined,
      valuation_retail: data.valuation_retail || undefined,
    });
  });

  return (
    <FormProvider {...methods}>
      <FieldValuation
        title="PPSR"
        className="capitalize font-bold"
        value={ppsrData}
      />
      <FieldValuation
        title="VIN/REGO check"
        value=""
        className="!items-center"
        tool={
          <Controller
            control={methods.control}
            name="vin_rego_check"
            render={({ field: { value } }) => (
              <div className="flex">
                <FieldCheckbox
                  checked={value === "1"}
                  label=""
                  onChange={() => {
                    setShowValidation(false);
                    setValue("vin_rego_check", value === "1" ? "0" : "1");
                  }}
                  disabled={isReadOnly}
                />
              </div>
            )}
          />
        }
      />
      <Controller
        control={methods.control}
        name="verification_status"
        render={({ field: { value, onChange } }) => (
          <ValuationStatus
            value={value}
            isReadOnly={isReadOnly}
            status={data?.status?.name}
            onChange={(value) => {
              setShowValidation(false);
              onChange(value);
            }}
          />
        )}
      />
      <FieldValuation
        title="Trade Amount"
        value={valuationTrade && `$${formatNumber(valuationTrade)}`}
        onView={
          tradeDetails
            ? () => {
                toggleModal("historyID", tradeDetails?.valuation_id);
                toggleModal("trade", true);
              }
            : undefined
        }
        canUserEdit={!isReadOnly}
        onEditValuation={() => toggleModal("addTrade", true)}
      />
      <FieldValuation
        title="Retail Amount"
        value={valuationRetail && `$${formatNumber(valuationRetail)}`}
        onView={
          retailDetails
            ? () => {
                toggleModal("historyID", retailDetails.valuation_id);
                toggleModal("retail", true);
              }
            : undefined
        }
        canUserEdit={!isReadOnly}
        onEditValuation={() => toggleModal("addRetail", true)}
      />
      {canViewPricingInsights && (
        <>
          <FieldValuation
            title="Pricing Insights"
            tool={
              <LinkButton onClick={() => toggleModal("pricingInsights", true)}>
                <span className={"!text-sm"}>{`View >`}</span>
              </LinkButton>
            }
          />
          <ResearchVehicle reference={data.reference} />
        </>
      )}
      <div className="valuation-field flex-col !border-b-0">
        <label>Comment</label>
        <Controller
          control={methods.control}
          name="verification_notes"
          render={({ field: { value, onChange } }) => (
            <FieldTextarea
              value={value}
              className="w-full !bg-white mt-2"
              maxLength={2500}
              placeholder="Enter your comment here"
              onChange={onChange}
              disabled={isReadOnly}
            />
          )}
        />
      </div>

      {showValidation && <TextError error="Please complete the verification" />}
      {!isReadOnly && (
        <Button
          type="submit"
          className="uppercase font-bold"
          onClick={onSubmit}
          isLoading={isLoading || isSubmitting}
        >
          Submit
        </Button>
      )}
      {isStatusReadOnly && (
        <VerificationCertificate requestID={data.request_id} />
      )}
      {verificationWebhook?.message && (
        <p className="text-sm font-medium text-label">
          {`sync: ${verificationWebhook.status} ${
            verificationWebhook.status_code
          }. ${verificationWebhook?.message}. ${
            verificationWebhook?.status_code !== 200
              ? "Please update the dealership directly."
              : ""
          }`}
        </p>
      )}
      {isSubmitting && !verificationWebhook?.status_code && (
        <p className="text-sm font-medium text-label">sync: in progress</p>
      )}
      <PricingInsightsModal
        data={data?.trade}
        isOpen={modalStates.pricingInsights}
        onClose={() => toggleModal("pricingInsights", false)}
        dateCreated={data?.created_at}
      />
      <ModalValuationHistory
        historyID={modalStates.historyID}
        isOpen={!!modalStates.historyID}
        onClose={() => toggleModal("historyID", "")}
        title={modalStates.retail ? "Retail Amount Log" : "Trade Amount Log"}
      />
      <ModalTradeAdd
        open={modalStates.addTrade}
        onClose={() => {
          setShowValidation(false);
          toggleModal("addTrade", false);
        }}
      />
      <ModalRetailAdd
        open={modalStates.addRetail}
        onClose={() => {
          setShowValidation(false);
          toggleModal("addRetail", false);
        }}
      />
    </FormProvider>
  );
};
