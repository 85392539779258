export enum DeviceType {
  Desktop = "Desktop",
  Mobile = "Mobile",
  Tablet = "Tablet",
}

export enum BrowserType {
  Chrome = "chrome",
  Firefox = "firefox",
  Safari = "safari",
  Edge = "edg",
  Other = "other",
}
