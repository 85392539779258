export function OdometerPlaceholder() {
  return (
    <>
      {Array.from({ length: 2 }).map((_, index) => (
        <div key={index}>
          <div className="odometer__log">
            <div className="mb-4">
              <div className="flex justify-between">
                <div className="w-1/3 h-15 bg-grey rounded mb-2" />
                <div className="w-1/4 h-15 bg-grey rounded mb-2" />
              </div>
              <p className="w-1/2 float-right h-3 bg-grey rounded" />
            </div>
          </div>
        </div>
      ))}
    </>
  );
}
