import debounce from "lodash.debounce";
import { ChangeEvent, useMemo, useState, useCallback } from "react";

import { SearchBar } from "components/app";
import { Pagination } from "components/shared";

import { StorageKeys } from "utils";

import { PPSRService } from "api/client";

import { useListQuery } from "hooks/useListQuery";

import { FilterDateRange } from "./FilterDateRange";
import { PPSRListSearchByVin } from "./PPSRListSearchByVin";
import { PPSRCheckTable } from "./PPSRTable";

export const PPSRList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTxt, setSearchTxt] = useState("");
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);

  const isDateRangeValid = useMemo(() => {
    return (fromDate && toDate) || (!fromDate && !toDate);
  }, [fromDate, toDate]);

  const { data, isInitialLoading, refetch } = useListQuery(
    {
      queryKey: StorageKeys.PPSRList,
      currentPage,
      search: searchTxt,
      fromDate: isDateRangeValid ? (fromDate as Date) : undefined,
      toDate: isDateRangeValid ? (toDate as Date) : undefined,
    },
    PPSRService.getRequests
  );

  const debouncedSetSearchText = useMemo(
    () =>
      debounce((value: string) => {
        setSearchTxt(value);
        setCurrentPage(1);
      }, 1000),
    []
  );

  const onSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      debouncedSetSearchText(e.target.value);
    },
    [debouncedSetSearchText]
  );

  return (
    <div className="flex flex-col max-w-[2000px] w-full">
      <div className="flex flex-col gap-2.5 pt-2">
        <span className="inline-flex items-center text-sm font-medium text-label whitespace-nowrap">
          PPSR Checks
        </span>
        <div className="flex items-center gap-2">
          <div className="text-2xl text-txt font-semibold">PPSR Searches</div>
        </div>
        <div className="flex gap-5 mb-5 justify-between">
          <div className="flex justify-between gap-5 items-center">
            <div className="w-[400px] relative">
              <SearchBar onSearch={onSearch} value={searchTxt} />
            </div>
            <div className="flex">
              <FilterDateRange
                startDate={fromDate}
                endDate={toDate}
                onChangeStartDate={setFromDate}
                onChangeEndDate={setToDate}
              />
            </div>
          </div>

          <div>
            <PPSRListSearchByVin onRefetch={refetch} />
          </div>
        </div>
      </div>
      <div>
        <PPSRCheckTable
          data={data?.data || []}
          isInitialLoading={isInitialLoading}
          refetch={refetch}
        />
        <Pagination
          title="Items"
          currentPage={currentPage}
          pageLimit={data?.meta?.per_page || 10}
          total={data?.meta.total || 0}
          onNextPage={() => setCurrentPage(currentPage + 1)}
          onPrevPage={() => setCurrentPage(currentPage - 1)}
          onIndexedPage={(index) => setCurrentPage(index)}
        />
      </div>
    </div>
  );
};
