import { RETAIL_DEALERSHIP_MENU, WHOLESALE_DEALERSHIP_MENU } from "utils";

type useGetRouteProps = {
  title: string;
  isRetail: boolean;
};

export const useGetSummaryRoute = ({ isRetail, title }: useGetRouteProps) => {
  const menu = isRetail ? RETAIL_DEALERSHIP_MENU : WHOLESALE_DEALERSHIP_MENU;

  return menu?.find(({ label }) => label.toLowerCase() === title.toLowerCase())
    ?.route;
};
