import classNames from "classnames";
import React from "react";

interface ItemProps {
  title: string;
  value?: React.ReactNode;
  failedStatus?: boolean;
  hideUnderline?: boolean;
}

export const Item = ({
  title,
  value,
  failedStatus,
  hideUnderline,
}: ItemProps) => {
  return (
    <div
      className={classNames(
        "flex justify-between text-sm text-lightGrey h-[50px] items-center border-softPeach",
        { "border-b": !hideUnderline }
      )}
    >
      <div className="inline-flex whitespace-nowrap items-center font-semibold">
        {title}
      </div>
      <div className={classNames([{ "text-[#C8372D]": failedStatus }])}>
        {value}
      </div>
    </div>
  );
};
